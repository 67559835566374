import React, { PureComponent } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import { Chip } from '@mui/material';
import { sendMessage, getMessages, getEmployeeMessages } from '../../../services/messagingService';

export function Messages({setError, setErrorText, user, employee, messages, selectedContact, setSelectedMessages, setMessagesOpen, changeGutters}) {
    const [messageText, setMessageText] = React.useState('');
    const [isEmployee, setIsEmployee] = React.useState(false);
    const [isEmployeePage, setIsEmployeePage] = React.useState(false);
    const [employeeId, setEmployeeId] = React.useState(0);

    React.useEffect(() => {
    }, []);

    React.useEffect(() => {
        if (employee?.id !== undefined) {
            setIsEmployeePage(true);
            setEmployeeId(employee?.id);
            if (user?.id !== employee?.id) {
                setIsEmployee(true);
            }
        } else {
            setEmployeeId(user?.id);
        }
    }, [employee]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (employee?.id !== undefined) {
                if (user?.id === employee?.id) {
                    getMessages(localStorage.getItem('token'), selectedContact?.id).then(newMessages => {
                        if (newMessages.status == true) {
                            if (newMessages?.data?.messages?.length > messages?.messages?.length) {
                                setSelectedMessages(newMessages.data);
                                var elem = document.getElementById('messagesDiv');
                                elem.scrollTop = elem.scrollHeight;
                            }
                        } else {
                            setError(true);
                            setErrorText(newMessages.message);
                        }
                    }).catch(err => {
                        setError(true);
                        setErrorText(err);
                    });
                } else {
                    getEmployeeMessages(localStorage.getItem('token'), employee?.id, selectedContact?.id).then(newMessages => {
                        if (newMessages.status == true) {
                            if (newMessages?.data?.messages?.length > messages?.messages?.length) {
                                setSelectedMessages(newMessages.data);
                                var elem = document.getElementById('messagesDiv');
                                elem.scrollTop = elem.scrollHeight;
                            }
                        } else {
                            setError(true);
                            setErrorText(newMessages.message);
                        }
                    }).catch(err => {
                        setError(true);
                        setErrorText(err);
                    });
                }
                setEmployeeId(employee?.id);
            } else {
                let id = null;
                if (user?.id === selectedContact?.user_id) {
                    if (selectedContact?.student_id) {
                        id = selectedContact?.student_id;
                    } else {
                        id = selectedContact?.parent_id;
                    }
                } else if (user?.id === selectedContact?.student_id) {
                    id = selectedContact?.user_id;
                } else if (user?.id === selectedContact?.parent_id) {
                    id = selectedContact?.user_id;
                }
                setEmployeeId(user?.id);
                getMessages(localStorage.getItem('token'), id).then(newMessages => {
                    if (newMessages.status == true) {
                        if (newMessages?.data?.messages?.length > messages?.messages?.length) {
                            setSelectedMessages(newMessages.data);
                            var elem = document.getElementById('messagesDiv');
                            elem.scrollTop = elem.scrollHeight;
                        }
                    } else {
                        setError(true);
                        setErrorText(newMessages.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [selectedContact, employee]);

    React.useEffect(() => {
        var elem = document.getElementById('messagesDiv');
        elem.scrollTop = elem.scrollHeight;
        if (employee?.id !== undefined) {
            setEmployeeId(employee?.id);
        }  else {
            setEmployeeId(user?.id);
        }
    }, [messages]);

    const goBackButton = {
        '@media (min-width: 899px)': {
            display: 'none'
        },
    };

    const handleMessageChange = (event, values) => {
        setMessageText(event.target.value);
    };

    const handleSubmitMessage = () => {
        if (messageText !== "") {
            let id = null;
            if (user?.id === selectedContact?.user_id) {
                id = selectedContact?.student_id;
            } else if (user?.id === selectedContact?.student_id) {
                id = selectedContact?.user_id;
            } else {
                id = selectedContact?.id;
            }
            let data = new URLSearchParams({
              'message':messageText
            });
            sendMessage(localStorage.getItem('token'), data, id).then(createdMessageResponse => {
                if (createdMessageResponse.status == true) {
                    getMessages(localStorage.getItem('token'), id).then(messages => {
                        if (messages.status == true) {
                            setSelectedMessages(messages.data);
                            setMessageText('');
                            
                            var elem = document.getElementById('messagesDiv');
                            elem.scrollTop = elem.scrollHeight;
                        } else {
                            setError(true);
                            setErrorText(messages.message);
                        }
                    }).catch(err => {
                        setError(true);
                        setErrorText(err);
                    });
                } else {
                    setError(true);
                    setErrorText(createdMessageResponse.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        }
    };

    const handleGoBack = () => {
        setMessagesOpen(false);
    };

    const messagesTooltipText = (messages) => {
        if (messages?.pending !== 0) {
            return 'You cannot send messages to this user until your connection with them within your network is approved.';
        }
        if (messages?.active === 0) {
            if (messages?.deactivated_by === user?.id) {
                return 'This user is no longer active. You will need to reactivate them to send a message.';
            } else {
                return 'This user deactivated you from their network. You will need to contact them to reactivate you to send a message.';
            }
        }
        return '';
    };

    const formatDate = (date) => {
        var options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit' };
        var formattedDate  = new Date(date);

        return formattedDate.toLocaleDateString('en-US', options);
    };

    return (
        <>
            {!isEmployeePage && (
                <Typography
                    component='div'
                    color={'secondary'}
                    sx={{
                        padding: 2,
                        borderBottom: '1px solid #ccc',
                        fontWeight: 'bold'
                    }}
                >
                    <IconButton 
                        sx={goBackButton}
                        onClick={handleGoBack}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    {messages?.first_name} {messages?.last_name}
                </Typography>
            )}
            <Grid 
                id='messagesDiv'
                sx={{
                    height: (!isEmployee) ? 'calc(100% - 144px)' : '100%',
                    maxHeight: (!isEmployee) ? 'calc(100% - 144px)' : '100%',
                    overflowY: 'scroll',
                    width: '100%'
                }}
            >
                {messages?.messages?.length > 0 ? (
                    <>
                        {messages?.messages?.map((message, index) => (
                            <Grid
                                sx={{
                                    m: 1,
                                    width: '80%',
                                    textAlign: message?.sender_id === employeeId ? 'right' : 'left',
                                    float: message?.sender_id === employeeId ? 'right' : 'left',
                                }}
                            >
                                <Chip 
                                    label={message?.message} 
                                />
                                <Typography
                                    component='div'
                                    color={'secondary'}
                                    sx={{
                                        fontSize: '12px',
                                        pl: 1,
                                        pr: 1,
                                    }}
                                >
                                    {formatDate(message?.created_at)}
                                </Typography>
                                    
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Typography 
                        color='secondary'
                        component='div'
                        sx={{ 
                            textAlign: 'center', 
                            p: 2, width: '100%', 
                            boxSizing: 'border-box' 
                        }}
                    >
                        No messages yet.
                    </Typography>
                )}
            </Grid>
            {!isEmployee && (
                <Grid 
                    container 
                    spacing={0} 
                    sx={{
                        width: '100%',
                        position: 'absolute',
                        bottom: 0,
                        left:0,
                        right:0,
                        padding: changeGutters === true ? '16px 0px !important' : 2,
                        borderTop: '1px solid #ccc',
                        backgroundColor: '#fff'
                    }}
                >
                    <Tooltip 
                        title={messagesTooltipText(messages)}
                        placement="top"
                        arrow
                    >
                        <TextField 
                            fullWidth 
                            label="Send a message."
                            size='small'
                            disabled={(messages?.active === 0 || messages?.pending !== 0) ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton
                                            disabled={(messages?.active === 0 || messages?.pending !== 0) ? true : false}
                                            onClick={handleSubmitMessage}
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleMessageChange}
                            value={messageText}
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                    handleSubmitMessage();
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>
            )}
        </>
    );
};