import React, { PureComponent } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { educatorAccount, checkType } from '../../../services/userService';
import { getEmployees, addEmployee } from '../../../services/educationActionsService';
import { EmployeePreview } from './components/employeePreview';
import { EmployeeFull } from './components/employeeFull';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Employees = () => {
  const [employees, setEmployees] = React.useState(false);
  const [educator, setEducator] = React.useState(false);
  const [formCompleted, setFormCompleted] = React.useState(false);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [userLevel, setUserLevel] = React.useState(null);
  const [viewEmployee, setViewEmployee] = React.useState(null);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = React.useState(false);
  const handleCloseAddEmployeeModal = () => setOpenAddEmployeeModal(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const onFirstNameChange = (event, values) => {
    setFirstName(event.target.value);
  }

  const onLastNameChange = (event, values) => {
    setLastName(event.target.value);
  }

  const onEmailChange = (event, values) => {
    setEmail(event.target.value);
  }

  const onUserLevelChange = (event, values) => {
    setUserLevel(event.target.value);
  }

  const handleSubmit = (event) => {
    let data = new URLSearchParams({
      'first_name':firstName,
      'last_name':lastName,
      'email':email,
      'user_level':userLevel
    });

    addEmployee(localStorage.getItem('token'), data).then(update => {
      setFirstName(null);
      setLastName(null);
      setEmail(null);
      setUserLevel(null);
      handleCloseAddEmployeeModal();

      if (update?.status == true) {
        window.location.reload();
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(update.message);
        if (update.message === 'This email already exists.') {
          setOpenErrorModalText('WARNING: This email already exists on another account. We understand this may be frustrating and we are actively working on a feature that will remedy this. While not the best option the current workaround is to have the employee with this email log into their already existing account and change the email in that account themselves. That will free up the email and allow you to add them as an employee. Feel free to contact support if you have any trouble.');
        }
      }
    });
  };

  React.useEffect(() => {
    if (firstName && lastName && email && userLevel) {
      setFormCompleted(true)
    }
  });

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if ((userData.data?.user_level !== 1 && userData.data?.user_level !== 2) || userData.data?.account_locked) {
          window.location.href = '/educator/dashboard';
        }
        setEducator(userData.data);
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getEmployees(localStorage.getItem('token')).then(employees => {
      if (employees.status == true) {
        setEmployees(employees.data);
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(employees.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      {viewEmployee ? (
        <EmployeeFull 
          employees={employees}
          employee={viewEmployee}
          educator={educator}
          setViewUser={setViewEmployee}
        />
      ) : (
        <>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Employees
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={setOpenAddEmployeeModal}
                disabled={educator?.active ? false : true}
              >
                Add Employee
              </Button>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          {employees?.length > 0 ? (
            <>
              <Grid container spacing={2}>
                {employees?.map((employee, index) => (
                  <EmployeePreview 
                    employee={employee}
                    educator={educator}
                    setViewEmployee={setViewEmployee}
                    key={index}
                  />
                ))}
              </Grid>
            </>
          ) : (
            <>
              <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                You currently have no employees at this time.
              </Typography>
            </>
          )}
          <Modal
            open={openErrorModal}
            onClose={handleCloseErrorModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {openErrorModalText}
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={openAddEmployeeModal}
            onClose={handleCloseAddEmployeeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                  <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                    Add New Employee
                  </Typography>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid item xs={12}>
                  <TextField
                    name="first_name"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    type="text"
                    onChange={onFirstNameChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="last_name"
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    type="text"
                    onChange={onLastNameChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    onChange={onEmailChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="user_level">User Level *</InputLabel>
                    <Select
                      labelId="user_level"
                      id="user_level"
                      label="User Level *"
                      sx={{textAlign:'left'}}
                      onChange={onUserLevelChange}
                    >
                      <MenuItem key='3' value='3'>Employee</MenuItem>
                      <MenuItem key='2' value='2'>Manager</MenuItem>
                      <MenuItem key='1' value='1'>Owner</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    disableElevation
                    size='large'
                    disabled={formCompleted ? false : true}
                    sx={{width:'100%'}}
                    onClick={handleSubmit}
                  >
                    Add Employee
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default Employees;
