import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { checkType } from '../../../services/userService';
import { getScholarships, getScholarshipTypes, deleteScholarship } from '../../../services/scholarshipService';
import { getAdvertisements } from '../../../services/advertisementsService';
import { AdvertisementDisplay } from '../../../components/global/advertisements/advertisementDisplay';
import { getLoans, getLoanTypes } from '../../../services/loansService';
import { getSponsoredScholarships } from '../../../services/advertisementsService';
import { studentDisclaimer } from '../../../components/global/studentDisclaimer';
import { ScholarshipsModal } from '../../../components/global/scholarshipsModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Scholarships = () => {
  const [userType, setUserType] = React.useState(1);
  const [scholarships, setScholarships] = React.useState(null);
  const [scholarshipTypes, setScholarshipTypes] = React.useState([]);
  const [sponsoredScholarships, setSponsoredScholarships] = React.useState([]);
  const [advertisements, setAdvertisements] = React.useState([]);
  const [loans, setLoans] = React.useState(null);
  const [loanTypes, setLoanTypes] = React.useState([]);
  const [openChangeScholarshipModal, setOpenChangeScholarshipModal] = React.useState(false);
  const [modalScholarshipId, setModalScholarshipId] = React.useState('');
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleOpenChangeScholarshipModal = (e, id) => {
    setModalScholarshipId(id);
    setOpenChangeScholarshipModal(true);
  }

  const handleDeleteScholarship = (e, id) => {
    deleteScholarship(localStorage.getItem('token'), id).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }

  const handleCloseChangeScholarshipModal = () => {
    setModalScholarshipId('');
    setOpenChangeScholarshipModal(false);
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
      setUserType(requestData.data?.type);
    });
    getScholarships(localStorage.getItem('token')).then(scholarshipData => {
      if (scholarshipData.status == true) {
        setScholarships(scholarshipData.data);
        getScholarshipTypes(localStorage.getItem('token')).then(scholarshipTypesData => {
          if (scholarshipTypesData.status == true) {
            setScholarshipTypes(scholarshipTypesData.data);
          }
        });
        getLoans(localStorage.getItem('token')).then(loansData => {
          if (loansData.status == true) {
            setLoans(loansData.data);
            getLoanTypes(localStorage.getItem('token')).then(loanTypesData => {
              if (loanTypesData.status == true) {
                setLoanTypes(loanTypesData.data);
              }
            });
          }
        });
        getSponsoredScholarships(localStorage.getItem('token')).then(sponsoredScholarshipsData => {
          if (sponsoredScholarshipsData.status == true) {
            setSponsoredScholarships(sponsoredScholarshipsData.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(sponsoredScholarshipsData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getAdvertisements(localStorage.getItem('token'), 'scholarship').then(advertisements => {
          if (advertisements.status == true) {
            setAdvertisements(advertisements.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(advertisements.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container spacing={3}>
        <Grid item sm={12}  md={(sponsoredScholarships?.length > 0 || advertisements?.length) ? 9 : 12}>
          <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mb: 2 }}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Your Scholarships
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={e => handleOpenChangeScholarshipModal(e, '')}
              >
                Add Scholarship
              </Button>
            </Grid>
          </Grid>
          <Paper sx={{ p: 2 }}>
            {scholarships?.length > 0 ? (
              <>
                {scholarships?.map((scholarship, index) => (
                  <Container key={index} maxWidth={false} disableGutters={true}>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item xs={10}>
                        <Typography color='secondary' variant='h5' sx={{mb:1}}><b>{scholarship.name}</b></Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography color='secondary'>Total Scholarship Amount:</Typography>
                            <Typography color='primary' variant='h5'>${(scholarship.recurrent === 1) ? (scholarship.total * scholarship.recurrency_years).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : scholarship.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography color='secondary'>Scholarship Type:</Typography>
                            <Typography color='secondary' variant='h5'>{scholarshipTypes.find(type => type.id === scholarship.type)?.name}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} align='right'>
                        <IconButton aria-label="edit" color="secondary" onClick={e => handleOpenChangeScholarshipModal(e, scholarship.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton aria-label="delete" color="secondary" onClick={e => handleDeleteScholarship(e, scholarship.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {index !== scholarships.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                  </Container>
                ))}
              </>
            ) : (
              <>
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                  You have added no scholarships at this time. We will assume that you have no scholarships when running calculations until you add some here.
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        {(sponsoredScholarships?.length > 0 || advertisements?.length > 0) &&
          <Grid item sm={12} md={3}>
            {advertisements?.length > 0 ? (
              <>
                <Tooltip enterTouchDelay={0} title='These are sponsored advertisements based on your interests.' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 3, textAlign: 'center', justifyContent: 'center' }}>
                    Sponsored <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                {advertisements?.map((advertisement, index) => (
                    <AdvertisementDisplay 
                        advertisement={advertisement}
                        selectedCategory={advertisement?.category_id}
                        type={'live'}
                        typeTitle={null}
                        setError={openErrorModal}
                        setErrorText={openErrorModalText}
                    />
                ))}
              </>
            ) : (
              <>
                <Tooltip enterTouchDelay={0} title='We have prepared some scholarships you may want to consider applying for to help you on your education journey!' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 4, textAlign: 'center', justifyContent: 'center' }}>
                    Scholarships To Consider <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
              </>
            )}
          </Grid>
        }
      </Grid>
      {studentDisclaimer}
      <Modal
        open={openChangeScholarshipModal}
        onClose={handleCloseChangeScholarshipModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {<ScholarshipsModal scholarship={{id:modalScholarshipId}} userType={userType} />}
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Scholarships;
