import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { userAccount, checkType } from '../../../services/userService';
import { getAllUserSuggestions, getSystemSuggestions } from '../../../services/suggestionsService';
import { SuggestionsList } from '../../../components/global/suggestions/suggestionsList';
import { SuggestionsView } from '../../../components/global/suggestions/suggestionsView';

const StudentSuggestions = () => {
  const [user, setUser] = React.useState({});
  const [reloadList, setReloadList] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const [type, setType] = React.useState('student');
  const [systemSuggestions, setSystemSuggestions] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
  const [specificType, setSpecificType] = React.useState(false);
  const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const handleCloseError = () => setError(false);

  const drawerStyle = {
      width: '80%',
      '@media (max-width: 800px)': {
          width: '100%'
      }
  }

  const handleOpenSuggestionsDrawer = () => {
      setOpenSuggestionsDrawer(true);
  };

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleCloseDrawer = () => {
      setOpenSuggestionsDrawer(false);
      setSelectedSuggestion({});
      setSpecificType(false);
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUser(userData.data);
      } else {
        setError(true);
        setErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
    if (user?.id !== undefined) {
        getSystemSuggestions(localStorage.getItem('token'), user?.id).then(suggestions => {
            if (suggestions.status == true) {
                setSystemSuggestions(suggestions.data);
            }
        });
    }
    getAllUserSuggestions(localStorage.getItem('token')).then(suggestions => {
          if (suggestions.status == true) {
              setSuggestions(suggestions.data);
          } else {
              setError(true);
              setErrorText(suggestions.message);
          }
      }).catch(err => {
          setError(true);
          setErrorText(err);
      });
      setReloadList(false);
  }, [user, setOpenSuggestionsDrawer, reloadList]);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography color='secondary' variant='h4' component='div'>
            <Tooltip title="Suggestions are based off your currently selected occupation and school." placement="bottom" arrow>
                Your Suggestions <InfoIcon color="secondary" sx={{mb:'-5px'}} />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} align='right'>
          <Button
            variant='contained'
            disableElevation
            size='large'
            onClick={handleOpenSuggestionsDrawer}
            disabled={!user?.account_locked ? false : true}
          >
            Compare Others
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
      <Paper>
        <SuggestionsList 
            type={type}
            suggestions={suggestions}
            setSelectedSuggestion={setSelectedSuggestion}
            systemSuggestions={systemSuggestions}
            attachedUser={user}
            setReloadList={setReloadList}
            setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
            setSpecificType={setSpecificType}
            setError={setError}
            setErrorText={setErrorText}
        />
      </Paper>
      <Drawer
          open={openSuggestionsDrawer}
          onClose={handleCloseDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <SuggestionsView
              type={type}
              selectedSuggestion={selectedSuggestion}
              specificType={specificType}
              student={user}
              setReloadList={setReloadList}
              handleCloseDrawer={handleCloseDrawer}
              setError={setError}
              setErrorText={setErrorText}
          />
      </Drawer>
      <Snackbar
        open={error}
        onClose={handleCloseError}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {errorText}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentSuggestions;
