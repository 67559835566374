import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { getUserDocumentsByReferrer } from '../../../../../services/documentsService';
import { DocumentsList } from '../../../../../components/global/documents/documentsList';
import { DocumentsAdd } from '../../../../../components/global/documents/documentsAdd';

export function DocumentsComponent({user, student,setOpenDocuments, setError, setErrorText}) {
    const [documents, setDocuments] = React.useState([]);
    const [type, setType] = React.useState('parent');
    const [updateList, setUpdateList] = React.useState(false);
    const [openAddDocumentModal, setOpenAddDocumentModal] = React.useState(false);
    const handleCloseAddDocumentModal = () => setOpenAddDocumentModal(false);

    React.useEffect(() => {
        getUserDocumentsByReferrer(localStorage.getItem('token'), student?.id).then(documents => {
            if (documents.status == true) {
                setDocuments(documents.data);
            } else {
                setError(true);
                setErrorText(documents.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
        });
    }, [setOpenDocuments, openAddDocumentModal, updateList]);

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Documents
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <DocumentsList
                type={type}
                documents={documents}
                attachedUser={user}
                setUpdateList={setUpdateList}
                setOpenAddDocumentModal={setOpenAddDocumentModal}
                setError={setError}
                setErrorText={setErrorText}
            />
            <Modal
              open={openAddDocumentModal}
              onClose={handleCloseAddDocumentModal}
            >
                <DocumentsAdd
                    type={type}
                    attachedUser={student}
                    educators={[]}
                    setError={setError}
                    setErrorText={setErrorText}
                    handleCloseAddDocumentModal={handleCloseAddDocumentModal}
                />
            </Modal>
        </>
    )
}