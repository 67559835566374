import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { userAccount, checkType } from '../../../services/userService';
import { getAdvertisements } from '../../../services/advertisementsService';
import { AdvertisementDisplay } from '../../../components/global/advertisements/advertisementDisplay';
import { getInstitution, updateInstitution } from '../../../services/educationService';
import { getSponsoredInstitutions } from '../../../services/advertisementsService';
import { studentDisclaimer } from '../../../components/global/studentDisclaimer';
import { EducationModal } from '../../../components/global/educationModal';
import { AddRemoveFavorite } from '../../../components/global/favorites/addRemoveFavorite';
import { getFavorites } from '../../../services/favoriteService';
import { SuggestionsView } from '../../../components/global/suggestions/suggestionsView';
import { getComparisons } from '../../../services/suggestionsService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Education = () => {
  const [userType, setUserType] = React.useState(1);
  const [userData, setUserData] = React.useState({});
  const [openChangeEducationModal, setOpenChangeEducationModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [investmentId, setInvestmentId] = React.useState(null);
  const [educationData, setEducationData] = React.useState([]);
  const [sponsoredInstitutions, setSponsoredInstitutions] = React.useState([]);
  const [advertisements, setAdvertisements] = React.useState([]);
  const [favorites, setFavorites] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
  const [specificType, setSpecificType] = React.useState(false);
  const [reloadPage, setReloadPage] = React.useState(false);
  const handleOpenChangeEducationModal = () => setOpenChangeEducationModal(true);
  const handleCloseChangeEducationModal = () => setOpenChangeEducationModal(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);

  const handleCloseDrawer = () => {
      setOpenSuggestionsDrawer(false);
      setSelectedSuggestion({});
      setSpecificType(false);
  }

  const drawerStyle = {
      width: '80%',
      '@media (max-width: 800px)': {
          width: '100%'
      }
  }

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
      setUserType(requestData.data?.type);
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUserData(userData.data)
        setInvestmentId(userData.data.investments[0].id);

        getInstitution(localStorage.getItem('token'), userData.data.investments[0].id).then(educationData => {
          if (educationData.status == true) {
            setEducationData(educationData.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(educationData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getSponsoredInstitutions(localStorage.getItem('token'), userData.data.investments[0].id).then(sponsoredInstitutionsData => {
          if (sponsoredInstitutionsData.status == true) {
            setSponsoredInstitutions(sponsoredInstitutionsData.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(sponsoredInstitutionsData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getAdvertisements(localStorage.getItem('token'), 'institution').then(advertisements => {
          if (advertisements.status == true) {
            setAdvertisements(advertisements.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(advertisements.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getFavorites(localStorage.getItem('token'), '*').then(favorites => {
          if (favorites.status == true) {
            setFavorites(favorites.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(favorites.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  // could be used for later
  const handleChangeInstitution = (event, institution) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'institution':institution
    });

    updateInstitution(localStorage.getItem('token'), investmentId, data).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  const handleCompareInstitution = (event, institution) => {
    getComparisons(localStorage.getItem('token'), userData.id, institution, 'education').then(comparisonData => {
        if (comparisonData.status == true) {
            setOpenSuggestionsDrawer(true);
            setSelectedSuggestion(comparisonData.data);
            setSpecificType('education');
        } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(comparisonData.message);
            setOpenSuggestionsDrawer(false);
            setSelectedSuggestion({});
            setSpecificType(false);
        }
    }).catch(err => {
        setOpenErrorModal(true);
        setOpenErrorModalText(err);
        setOpenSuggestionsDrawer(false);
        setSelectedSuggestion({});
        setSpecificType(false);
    });
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container spacing={3}>
        <Grid item sm={12} md={(sponsoredInstitutions?.length || advertisements?.length) > 0 ? 9 : 12} sx={{maxWidth:'100%'}}>
          <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mb:2 }}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Education Information
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={handleOpenChangeEducationModal}
              >
                Change Institution
              </Button>
            </Grid>
          </Grid>
          <Paper sx={{ p: 2, mb: 2, position:'relative' }}>
            <Box
              sx={{
                position:'absolute',
                right: 1,
                top: 1,
                zIndex: 9
              }}
            >
              <AddRemoveFavorite
                allFavorites={favorites}
                setAllFavorites={setFavorites}
                potentialFavorite={{
                  id: educationData.id,
                  type: 1,
                  title: educationData.college_name
                }}
                size={'large'}
                setError={setOpenErrorModal}
                setErrorText={setOpenErrorModalText}
              />
            </Box>
            <Typography color='primary' variant='h3' component='div'>
              {educationData.college_name}
            </Typography>
            <Typography color='secondary' fontWeight='bold' component='div'>
              {educationData.street} <br />
              {educationData.city}, {educationData.state} {educationData.zipcode}
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <Tooltip enterTouchDelay={0} title='The net price is the actual cost you will likely pay per year. It includes housing, books and other costs not related to the institution itself.' placement='bottom' arrow>
                  <Typography color='secondary' fontWeight='bold' component='div' sx={{ textAlign: 'center', mb:1 }}>
                    Net Price Information <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{mb:2}}>
                  <Grid item sm={12} md={6}>
                    <Typography color='secondary'component='div'>
                      Avg Attendance Cost
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData?.average_cost_of_attendance ? (<>
                        ${educationData.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                      </>) : (<>
                        Not Found
                      </>)}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Typography color='secondary' component='div'>
                      Avg Cost After Aid
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData?.average_net_price ? (<>
                        ${educationData.average_net_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                      </>) : (<>
                        Not Found
                      </>)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Tooltip enterTouchDelay={0} title='The sticker price is the advertised price from the institution as to what you will pay per year.' placement='bottom' arrow>
                  <Typography color='secondary' fontWeight='bold' component='div' sx={{ textAlign: 'center', mb:1 }}>
                    Sticker Price Information <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                  <Grid item sm={12} md={6}>
                    <Typography color='secondary'component='div'>
                      In State Cost
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData?.in_state_tuition ? (<>
                        ${educationData.in_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                      </>) : (<>
                        Not Found
                      </>)}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Typography color='secondary' component='div'>
                      Out of State Cost
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData?.out_of_state_tuition ? (<>
                        ${educationData.out_of_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                      </>) : (<>
                        Not Found
                      </>)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={6}>
                <Typography color='secondary' variant='h5' component='div'>
                  Cost After Aid by Income
                </Typography>
                <Table size='small'>
                  <TableBody>
                    {educationData.net_price_by_income_level?.map((row) => (
                    <TableRow key={row.price}>
                      <TableCell sx={{ pl: 0 }}>
                        <Typography fontWeight='bold'>${row.range.min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{row.range.max ? ' - $' + row.range.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '+'}</Typography>
                      </TableCell>
                      <TableCell align='right' sx={{ pr: 0 }}>
                        <Typography fontWeight='bold' color='primary'>${row.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</Typography>
                      </TableCell>
                    </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Paper>
          <Typography color='secondary' variant='h4' component='div' sx={{ mt: 4, mb: 2 }}>
            Wage and Financial Aid Information
          </Typography>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={3} sx={{mb:2}}>
              <Grid item sm={12} md={6}>
                <Tooltip enterTouchDelay={0} title='The average salary per student 6 years after workforce entry.' placement='bottom' arrow>
                  <Typography color='secondary' component='div'>
                    Avg Earnings 6 Yrs After Graduation <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                <Typography color='primary' variant='h4' component='div'>
                  {educationData?.median_earnings_six_years_after_entry ? (<>
                    ${educationData.median_earnings_six_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                  </>) : (<>
                    Not Found
                  </>)}
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Tooltip enterTouchDelay={0} title='The average salary per student 10 years after workforce entry.' placement='bottom' arrow>
                  <Typography color='secondary' component='div'>
                    Avg Earnings 10 Yrs After Graduation <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                <Typography color='primary' variant='h4' component='div'>
                  {educationData?.median_earnings_ten_years_after_entry ? (<>
                    ${educationData.median_earnings_ten_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                  </>) : (<>
                    Not Found
                  </>)}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <Typography color='secondary' component='div'>
                  Percent Who Recieve Financial Aid
                </Typography>
                <Typography color='primary' variant='h4' component='div'>
                  {Math.round(educationData.percent_who_recieve_financial_aid * 100)}%
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Typography color='secondary' component='div'>
                  Percent Who Recieve Federal Aid
                </Typography>
                <Typography color='primary' variant='h4' component='div'>
                  {educationData.percent_who_recieve_federal_aid}%
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Typography color='secondary' variant='h4' component='div' sx={{ mt: 4, mb: 2 }}>
            Institution Information
          </Typography>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                  <Grid item sm={12} md={6}>
                    <Typography color='secondary'component='div'>
                      Acceptance Rate
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData?.acceptance_rate ? (<>
                        {Math.round(educationData.acceptance_rate * 100)}%
                      </>) : (<>
                        Not Found
                      </>)}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Typography color='secondary' component='div'>
                      Graduation Rate
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData?.graduation_rate && educationData?.graduation_rate !== 'NaN%'  ? (<>
                        {educationData.graduation_rate}
                      </>) : (<>
                        Not Found
                      </>)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                  <Grid item sm={12} md={6}>
                    <Tooltip enterTouchDelay={0} title='Total number of students admitted into the institution.' placement='bottom' arrow>
                      <Typography color='secondary'component='div'>
                        Total Admitted <InfoIcon sx={{fontSize: 'medium'}} />
                      </Typography>
                    </Tooltip>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData.total_admitted ? (
                        <>
                          {educationData.total_admitted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      ) : (
                        <>
                          Not Found
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Tooltip enterTouchDelay={0} title='The total number of students admitted and accepted who actually study at the institution.' placement='bottom' arrow>
                      <Typography color='secondary' component='div'>
                        Total Enrolled <InfoIcon sx={{fontSize: 'medium'}} />
                      </Typography>
                    </Tooltip>
                    <Typography color='primary' variant='h4' component='div'>
                      {educationData.total_enrolled ? (
                        <>
                          {educationData.total_enrolled?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      ) : (
                        <>
                          Not Found
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={6}>
                <Table size='small'>
                  <TableBody>
                    {educationData.website &&
                    <TableRow>
                      <TableCell sx={{ pl: 0 }}>
                        <Typography fontWeight='bold'>Main Website</Typography>
                      </TableCell>
                      <TableCell align='right' sx={{ pr: 0 }}>
                        <Typography><Link href={'https://' + educationData.website} target="_blank" fontWeight='bold' color='primary'>{educationData.website.length > 25 ? educationData.website.replace('www.', '').substring(0, 22) + "..." : educationData.website.replace('www.', '')}</Link></Typography>
                      </TableCell>
                    </TableRow>
                    }
                    {educationData.applications_website &&
                    <TableRow>
                      <TableCell sx={{ pl: 0 }}>
                        <Typography fontWeight='bold'>Applications Information</Typography>
                      </TableCell>
                      <TableCell align='right' sx={{ pr: 0 }}>
                        <Typography><Link href={'https://' + educationData.applications_website} target="_blank" fontWeight='bold' color='primary'>{educationData.applications_website.length > 25 ? educationData.applications_website.replace('www.', '').substring(0, 22) + "..." : educationData.applications_website.replace('www.', '')}</Link></Typography>
                      </TableCell>
                    </TableRow>
                    }
                    {educationData.admissions_website &&
                    <TableRow>
                      <TableCell sx={{ pl: 0 }}>
                        <Typography fontWeight='bold'>Admissions Information</Typography>
                      </TableCell>
                      <TableCell align='right' sx={{ pr: 0 }}>
                        <Typography><Link href={'https://' + educationData.admissions_website} target="_blank" fontWeight='bold' color='primary'>{educationData.admissions_website.length > 25 ? educationData.admissions_website.replace('www.', '').substring(0, 22) + "..." : educationData.admissions_website.replace('www.', '')}</Link></Typography>
                      </TableCell>
                    </TableRow>
                    }
                    {educationData.financial_aid_website &&
                    <TableRow>
                      <TableCell sx={{ pl: 0 }}>
                        <Typography fontWeight='bold'>Financial Aid Information</Typography>
                      </TableCell>
                      <TableCell align='right' sx={{ pr: 0 }}>
                        <Typography><Link href={'https://' + educationData.financial_aid_website} target="_blank" fontWeight='bold' color='primary'>{educationData.financial_aid_website.length > 25 ? educationData.financial_aid_website.replace('www.', '').substring(0, 22) + "..." : educationData.financial_aid_website.replace('www.', '')}</Link></Typography>
                      </TableCell>
                    </TableRow>
                    }
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {(sponsoredInstitutions?.length > 0 || advertisements?.length > 0) &&
          <Grid item sm={12} md={3}>
            {advertisements?.length > 0 ? (
              <>
                <Tooltip enterTouchDelay={0} title='These are sponsored advertisements based on your interests.' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 3, textAlign: 'center', justifyContent: 'center' }}>
                    Sponsored <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                {advertisements?.map((advertisement, index) => (
                    <AdvertisementDisplay 
                        advertisement={advertisement}
                        selectedCategory={advertisement?.category_id}
                        type={'live'}
                        typeTitle={null}
                        setError={openErrorModal}
                        setErrorText={openErrorModalText}
                    />
                ))}
              </>
            ) : (
              <>
                <Tooltip enterTouchDelay={0} title='Based on the state you are in, you may want to consider these institutions as a better investment.' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 4, textAlign: 'center', justifyContent: 'center' }}>
                    Institutions To Consider <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                {sponsoredInstitutions?.map((institution, index) => (
                  <Paper sx={{ p: 2, mb:2, position: 'relative' }} component='div' key={index}>
                    <Box
                      sx={{
                        position:'absolute',
                        right: 1,
                        top: 1,
                        zIndex: 9
                      }}
                    >
                      <AddRemoveFavorite
                        allFavorites={favorites}
                        setAllFavorites={setFavorites}
                        potentialFavorite={{
                          id: institution.id,
                          type: 1,
                          title: institution.college_name
                        }}
                        size={'small'}
                        setError={setOpenErrorModal}
                        setErrorText={setOpenErrorModalText}
                      />
                    </Box>
                    <Typography color='secondary' variant='h6' sx={{mb:1}}><b>{institution.college_name}</b></Typography>
                    <Typography color='secondary' display="inline" sx={{mb:2}}><b>Avg Attendance Cost:</b></Typography> <Typography display="inline" sx={{mb:2}} color='primary'><b>${institution.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr</b></Typography>
                    <Typography color='secondary' sx={{mb:1}}><Link href={'https://' + institution.website} target="_blank" fontWeight='bold' color='primary'>{institution.website.replace('www.', '')}</Link></Typography>
                    <Button
                      variant='outlined'
                      size='medium'
                      fullWidth
                      //onClick={e => handleChangeInstitution(e, institution.id)}
                      onClick={e => handleCompareInstitution(e, institution.id)}
                    >
                      <b>Compare Statistics</b>
                    </Button>
                  </Paper>
                ))}
              </>
            )}
          </Grid>
        }
      </Grid>
      {studentDisclaimer}
      <Modal
        open={openChangeEducationModal}
        onClose={handleCloseChangeEducationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {<EducationModal userType={userType} />}
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
      <Drawer
          open={openSuggestionsDrawer}
          onClose={handleCloseDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <SuggestionsView
              type={'student'}
              selectedSuggestion={selectedSuggestion}
              specificType={specificType}
              student={userData}
              setReloadList={setReloadPage}
              handleCloseDrawer={handleCloseDrawer}
              setError={setOpenErrorModal}
              setErrorText={setOpenErrorModalText}
          />
      </Drawer>
    </Container>
  );
};

export default Education;
