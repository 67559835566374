import { apiUrl } from '../constants/globals';

export async function getLoans(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getLoan(token, loanId) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/' + loanId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentLoans(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/educator/student/loans/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function parentGetStudentLoans(token, studentId) {
    try {
        const response = await fetch(apiUrl + '/parents/student/loans/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addLoan(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateLoan(token, loanId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/' + loanId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteLoan(token, loanId) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/' + loanId, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getLoanTypes(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/loanTypes/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getLoanRepaymentPlans(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/loanRepaymentPlans/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getEligibleRepaymentPlans(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/eligibleRepaymentPlans/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function handleNoLoans(token, investmentId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/handleNoLoans/' + investmentId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getParentLoans(token) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loans/', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getParentLoan(token, loanId) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loans/' + loanId, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function addParentLoan(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loans/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function updateParentLoan(token, loanId, data) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loans/' + loanId, {
        method: 'PUT',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function deleteParentLoan(token, loanId) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loans/' + loanId, {
        method: 'DELETE',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getParentLoanTypes(token) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loanTypes/', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getParentLoanRepaymentPlans(token) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loanRepaymentPlans/', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getParentEligibleRepaymentPlans(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/account/eligibleRepaymentPlans/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function parentHandleNoLoans(token, investmentId, data) {
  try {
      const response = await fetch(apiUrl + '/parents/account/loans/handleNoLoans/' + investmentId, {
        method: 'PUT',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}
