import { apiUrl } from '../constants/globals';

export async function getProducts(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/getProducts', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function calculateTax(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/calculateTax', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getSubscription(token) {
  try {
      const response = await fetch(apiUrl + '/students/account/billing/subscription', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function createSubscription(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/subscription', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function removeSubscription(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/subscription', {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateDefaultCardBasedOnSubscription(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/customer/card', {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getBillingMethods(token) {
  try {
      const response = await fetch(apiUrl + '/students/account/billing/card', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function createBillingMethod(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/card', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteBillingMethod(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/card', {
          method: 'DELETE',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getInvoices(token) {
  try {
      const response = await fetch(apiUrl + '/students/account/billing/invoices', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getPromoCode(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/billing/promocode', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}
