import React, { Suspense } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { SuggestionsView } from '../../../../../components/global/suggestions/suggestionsView';
import { SuggestionItem } from '../../../../../components/global/suggestions/lists/suggestionItem';
import { getUserSuggestionsByReferrer } from '../../../../../services/suggestionsService';
import { getFavorites } from '../../../../../services/favoriteService';

export function SuggestionsComponent({user, parent, setOpenErrorModal, setOpenErrorModalText}) {
    const [suggestions, setSuggestions] = React.useState([]);
    const [type, setType] = React.useState('parent');
    const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
    const [specificType, setSpecificType] = React.useState(false);
    const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);
    const [reloadList, setReloadList] = React.useState(false);
    const [favorites, setFavorites] = React.useState([]);
  
    const drawerStyle = {
        width: '80%',
        '@media (max-width: 800px)': {
            width: '100%'
        }
    }

    const handleCloseDrawer = () => {
        setOpenSuggestionsDrawer(false);
        setSelectedSuggestion({});
        setSpecificType(false);
    }

    React.useEffect(() => {
        getUserSuggestionsByReferrer(localStorage.getItem('token'), parent?.id).then(suggestions => {
            if (suggestions.status == true) {
                setSuggestions(suggestions.data);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(suggestions.message);
            }
        }).catch(err => {
            setOpenErrorModal(true);
            setOpenErrorModalText(err);
        });
        getFavorites(localStorage.getItem('token'), '*').then(favorites => {
            if (favorites.status == true) {
                setFavorites(favorites.data);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(favorites.message);
            }
        }).catch(err => {
            setOpenErrorModal(true);
            setOpenErrorModalText(err);
        });
        setReloadList(false);
    }, [user, setOpenSuggestionsDrawer, reloadList]);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
            Suggestions
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Box sx={{ width: '100%' }}>
                <Suspense fallback={<div>Loading...</div>}>
                    {suggestions?.length > 0 ? (
                        <>
                            {suggestions?.map((suggestion, index) => (
                                <SuggestionItem
                                    key={index}
                                    type={type}
                                    attachedUser={user}
                                    suggestion={suggestion}
                                    setSelectedSuggestion={setSelectedSuggestion}
                                    setReloadList={setReloadList}
                                    setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                                    setSpecificType={setSpecificType}
                                    favorites={favorites}
                                    setFavorites={setFavorites}
                                    setError={setOpenErrorModal}
                                    setErrorText={setOpenErrorModalText}
                                    removeGutters={true}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10 }}>
                                There are currently no suggestions from this parent.
                            </Typography>
                        </>
                    )}
                </Suspense>
            </Box>
            <Drawer
                open={openSuggestionsDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <SuggestionsView
                    type={type}
                    selectedSuggestion={selectedSuggestion}
                    specificType={specificType}
                    student={user}
                    setReloadList={setReloadList}
                    handleCloseDrawer={handleCloseDrawer}
                    setError={setOpenErrorModal}
                    setErrorText={setOpenErrorModalText}
                />
            </Drawer>
        </>
    )
}