import React, { PureComponent, Suspense } from 'react';
import { MessagingContacts } from './messagingContacts';
import { Messages } from './messages';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { getContacts, getMessages } from '../../../services/messagingService';

export function MessagingContainer({user, userType, setError, setErrorText}) {
    const [messagesOpen, setMessagesOpen] = React.useState(false);
    const [userContacts, setUserContacts] = React.useState([]);
    const [selectedContact, setSelectedContact] = React.useState([]);
    const [selectedMessages, setSelectedMessages] = React.useState([]);

    React.useEffect(() => {
        getContacts(localStorage.getItem('token')).then(contacts => {
            if (contacts.status == true) {
                // gross but there's no way around it with
                // the weird sorting that the API does
                let contactArray = [];
                for (var key in contacts.data) {
                    if (!contacts.data.hasOwnProperty(key)) continue;
                    contactArray.push(contacts.data[key]);
                }
                setUserContacts(contactArray);
                if (contactArray?.length > 0) {
                    let id = null;
                    if (user?.id === contactArray[0]?.user_id) {
                        if (contactArray[0]?.student_id) {
                            id = contactArray[0]?.student_id;
                        } else {
                            id = contactArray[0]?.parent_id;
                        }
                    } else if (user?.id === contactArray[0]?.student_id) {
                        id = contactArray[0]?.user_id;
                    } else if (user?.id === contactArray[0]?.parent_id) {
                        id = contactArray[0]?.user_id;
                    }
                    if (id !== null) {
                        setSelectedContact(contactArray[0]);
                        getMessages(localStorage.getItem('token'), id).then(messages => {
                            if (messages.status == true) {
                                setSelectedMessages(messages.data);
                            } else {
                                setError(true);
                                setErrorText(messages.message);
                            }
                        }).catch(err => {
                            setError(true);
                            setErrorText(err);
                        });
                    }
                }
            } else {
                setError(true);
                setErrorText(contacts.message);
            }
        }).catch(err => {
          setError(true);
          setErrorText(err);
        });
    }, []);

    const contactsHiddenStyle = {
        '@media (max-width: 899px)': {
            display: messagesOpen ? 'none' : 'block',
            backgroundColor: '#fff',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: '4px',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
        },
        '@media (min-width: 899px)': {
            maxHeight: 'calc(65vh - 64px)',
            minHeight: 'calc(65vh - 64px)',
            overflowY: 'scroll'
        }
    }

    const messagesHiddenStyle = {
        '@media (max-width: 899px)': {
            display: messagesOpen ? 'block' : 'none'
        },
        maxHeight: 'calc(65vh - 64px)',
        minHeight: 'calc(65vh - 64px)',
    }
  
    return (
        <Suspense fallback={<div>Loading...</div>}>
            {user && selectedContact && userContacts?.length > 0 ? (
                <Grid container spacing={0}>
                    <Grid 
                        item 
                        xs={12}
                        sm={12}
                        md={8}
                        sx={messagesHiddenStyle}
                    >
                        <Paper
                            sx={{height:'100%', position: 'relative'}}
                        >
                            <Messages
                                setError={setError} 
                                setErrorText={setErrorText}
                                user={user}
                                messages={selectedMessages}
                                selectedContact={selectedContact}
                                setSelectedMessages={setSelectedMessages}
                                setMessagesOpen={setMessagesOpen}
                            />
                        </Paper>
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={contactsHiddenStyle}
                    >
                        <MessagingContacts
                            setError={setError} 
                            setErrorText={setErrorText} 
                            user={user}
                            contacts={userContacts}
                            selectedContact={selectedContact}
                            setSelectedContact={setSelectedContact}
                            setSelectedMessages={setSelectedMessages}
                            setMessagesOpen={setMessagesOpen}
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                        No contacts found. Add {(userType?.type === 1 || userType?.type === 3) ? 'someone' : 'a student or parent'} to your network to start messaging.
                    </Typography>
                </>
            )}
        </Suspense>
    );
};