import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaidIcon from '@mui/icons-material/Paid';
import ExploreIcon from '@mui/icons-material/Explore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ScienceIcon from '@mui/icons-material/Science';
import { logOut } from '../../../services/userService';

const runLogOut = () => {
  logOut(localStorage.getItem('token')).then(logOut => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  });
}

export function MainListItems({accountLocked, messagesCount, accountSoftLocked}) {
  return (
    <React.Fragment>
      <ListItemButton 
        component="a" 
        href="/parents/dashboard" 
        selected={(window.location.pathname.includes('dashboard')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/students" 
        selected={(window.location.pathname.includes('students')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText primary="My Students" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/educators" 
        selected={(window.location.pathname.includes('educators')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Educators" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/messages" 
        selected={(window.location.pathname.includes('messages')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Messages" />
        {(messagesCount > 0) && (
          <Chip 
            label={messagesCount}
            color='primary'
            size='small'
            sx={{
              color: 'white',
            }}
          />
        )}
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/suggestions" 
        selected={(window.location.pathname.includes('suggestions')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <ScienceIcon />
        </ListItemIcon>
        <ListItemText primary="Suggestions" />
        <Chip 
          label="BETA"
          color='primary'
          size='small'
          sx={{
            color: 'white',
          }}
        />
      </ListItemButton>
    </React.Fragment>
  )
};

export function DiscoverListItems({accountLocked, accountSoftLocked}) {
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Discovery Center
      </ListSubheader>
      <ListItemButton 
        component="a" 
        href="/parents/explore" 
        selected={(window.location.pathname.includes('explore')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <ExploreIcon />
        </ListItemIcon>
        <ListItemText primary="Overview" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/education" 
        selected={(window.location.pathname.includes('education')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="Find Schools" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/occupation" 
        selected={(window.location.pathname.includes('occupation')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary="Find Occupations" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/loans" 
        selected={(window.location.pathname.includes('loans')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <HandshakeIcon />
        </ListItemIcon>
        <ListItemText primary="Find Loans" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/parents/scholarships" 
        selected={(window.location.pathname.includes('scholarships')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="Find Scholarships" />
      </ListItemButton>
    </React.Fragment>
  )
};

export function AccountListItems({accountLocked, accountSoftLocked, openSidebar}) {
  const [open, setOpen] = React.useState(window.location.pathname.includes('billing') ? true : false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Account Details
      </ListSubheader>
      <ListItemButton 
        onClick={handleClick} 
        selected={(window.location.pathname.includes('billing')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="Billing" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton 
            sx={{ pl: openSidebar === true ? 4 : 2 }}
            component="a"
            href="/parents/billing/subscription"
            selected={(window.location.pathname.includes('subscription')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <PaidIcon />
            </ListItemIcon>
            <ListItemText primary="Your Subscription" />
          </ListItemButton>
          <ListItemButton 
            sx={{ pl: openSidebar === true ? 4 : 2 }}
            component="a"
            href="/parents/billing/invoices"
            selected={(window.location.pathname.includes('invoices')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Invoice History" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton 
        component="a" 
        href="/parents/settings" 
        selected={(window.location.pathname.includes('settings')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
      <ListItemButton onClick={runLogOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Log Out" />
      </ListItemButton>
    </React.Fragment>
  )
};
