import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getInstitutionsList } from '../../../../services/educationService';
import { getComparisons, getCalculatorComparison, addSuggestion } from '../../../../services/suggestionsService';
import { updateInstitution, updateParentInstitution } from '../../../../services/educationService';
import { getFavorites } from '../../../../services/favoriteService';
import { AddRemoveFavorite } from '../../favorites/addRemoveFavorite';
import { CalculatorComparisonBlock } from './investmentCalculatorView/calculator';

export function EducationBlock({type, selectedSuggestion, student, setReloadList, handleCloseDrawer, setError, setErrorText}) {

    const [institutions, setInstitutions] = React.useState([]);
    const [institution, setInstitution] = React.useState(null);
    const [investmentId, setInvestmentId] = React.useState(null);
    const [comparison, setComparison] = React.useState({});
    const [calculatorComparison, setCalculatorComparison] = React.useState({});
    const [favorites, setFavorites] = React.useState([]);
    const [comparisonDescription, setComparisonDescription] = React.useState(null);
  
    React.useEffect(() => {
        getInstitutionsList('*').then(getInstitutionsList => {
            setInstitutions(getInstitutionsList.data);
        });
        
        if (Object.keys(selectedSuggestion).length !== 0) {
            setComparison(selectedSuggestion);
            getCalculatorComparison(localStorage.getItem('token'), student?.id, 'education', selectedSuggestion.suggestion_id).then(comparison => {
                if (comparison.status == true) {
                    setCalculatorComparison(comparison.data);
                } else {
                    setError(true);
                    setErrorText(comparison.message);
                }
            }).catch(err => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            });
        };
        if (type === 'student' || type === 'parent') {
            setInvestmentId(student.investments[0].id);
            if (type === 'student') {
                getFavorites(localStorage.getItem('token'), 1).then(favorites => {
                    if (favorites.status == true) {
                        setFavorites(favorites.data);
                    } else {
                        setError(true);
                        setErrorText(favorites.message);
                    }
                }).catch(err => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                });
            }
        }
    }, []);

    const handleAddSuggestion = () => {
        let data = new URLSearchParams({
            'suggestion_id':comparison?.suggestion_id,
            'type':comparison?.type,
            'description':comparisonDescription,
            'attached_user': type === 'student' ? null : student?.id
        });
        addSuggestion(localStorage.getItem('token'), data).then(suggestion => {
            if (suggestion.status) {
                setReloadList(suggestion);
                handleCloseDrawer();
            } else {
                setError(true);
                setErrorText(suggestion.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error adding the suggestion.');
        });
        handleCloseDrawer();
    };

    const handleChangeInstitution = () => {
        let data = new URLSearchParams({
            'institution':comparison?.suggestion_id
        });
        if (type === 'student') {
            updateInstitution(localStorage.getItem('token'), investmentId, data).then(suggestion => {
                setReloadList(suggestion);
                handleCloseDrawer();
            }).catch(err => {
                setError(true);
                setErrorText('There was an error.');
            });
        } else if (type === 'parent') {
            updateParentInstitution(localStorage.getItem('token'), investmentId, data).then(suggestion => {
                setReloadList(suggestion);
                handleCloseDrawer();
            }).catch(err => {
                setError(true);
                setErrorText('There was an error.');
            });
        }
    };

    const onSuggestionDescriptionChange = (event) => {
        setComparisonDescription(event.target.value);
    };

    const updateInstitutions = (event, values) => {
        var value = '*';
        if (event.target.value) {
            value = event.target.value;
        }
        getInstitutionsList(value).then(getInstitutionsList => {
            setInstitutions(getInstitutionsList.data);
        });
    }
  
    const onInstitutionChange = (event, values) => {
        setInstitution(null);
        if (values && values.unitId !== null) {
            setInstitution(values.unitId);
            getComparisons(localStorage.getItem('token'), student.id, values.unitId, 'education').then(comparisonData => {
                if (comparisonData.status == true) {
                    setComparison(comparisonData.data);
                } else {
                    setError(true);
                    setErrorText(comparisonData.message);
                    handleCloseDrawer();
                }
                getCalculatorComparison(localStorage.getItem('token'), student?.id, 'education', comparisonData.data.suggestion_id).then(comparison => {
                    if (comparison.status == true) {
                        setCalculatorComparison(comparison.data);
                    } else {
                        setError(true);
                        setErrorText(comparison.message);
                    }
                }).catch(err => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                });
            }).catch(err => {
                setError(true);
                setErrorText(err);
                handleCloseDrawer();
            });
        }
    }

    const getComparisonPercentage = (suggested, current) => {
        return Math.ceil(((suggested - current) / current) * 100);
    }

    return (
        <Box sx={{ width: '100%', position:'relative' }}>
            {Object.keys(selectedSuggestion).length === 0 && (
                <Autocomplete
                    disablePortal
                    id="institute"
                    options={institutions}
                    name="institute"
                    fullWidth
                    onChange={onInstitutionChange}
                    getOptionLabel={institutions => institutions.name}
                    renderInput={(params) => <TextField {...params} onChange={updateInstitutions} required label="Desired Institution" />}
                    sx={{mb: 2}}
                />
            )}
            {Object.keys(comparison).length === 0 ? (
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                    Start searching for an institution to compare with what {type === 'student' ? 'you have' : 'this student has'} selected.
                </Typography>
            ) : (
                <>
                    {comparison?.description && comparison?.description !== null && (
                        <>
                            <Typography color='primary' variant='h6' component='div' sx={{mt: '-20px'}}>
                                Note from educator:
                            </Typography>
                            <Typography component='div'>
                                {comparison?.description}
                            </Typography>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                        </>
                    )}
                    {Object.keys(calculatorComparison).length !== 0 && (
                        <CalculatorComparisonBlock
                            comparison={{
                                'current': comparison?.details?.current_selection?.college_name,
                                'suggestion': comparison?.details?.suggestion?.college_name
                            }}
                            calculatorComparison={calculatorComparison}
                            type={'education'}
                        />
                    )}
                    {(type === 'student' && Object.keys(comparison).length !== 0) && (
                        <Box
                            sx={{
                                position:'absolute',
                                right: 1,
                                top: Object.keys(selectedSuggestion).length !== 0 ? 1 : 60,
                                zIndex: 9
                            }}
                        >
                            <AddRemoveFavorite
                                allFavorites={favorites}
                                setAllFavorites={setFavorites}
                                potentialFavorite={{
                                    id: comparison?.suggestion_id,
                                    type: 1,
                                    title: comparison?.details?.suggestion?.college_name
                                }}
                                size={'small'}
                                setError={setError}
                                setErrorText={setErrorText}
                            />
                        </Box>
                    )}
                    <Table size='small' sx={{mb:2}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>Further Breakdown</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>{comparison?.details?.suggestion?.college_name}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>{comparison?.details?.current_selection?.college_name} (Selected By {type === 'student' ? 'You' : 'Student'})</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Comparison Data</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Average Cost of Attendance</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.suggestion?.average_cost_of_attendance ? (<>
                                            ${comparison?.details?.suggestion?.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.current_selection?.average_cost_of_attendance ? (<>
                                            ${comparison?.details?.current_selection?.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.average_cost_of_attendance, comparison?.details?.current_selection?.average_cost_of_attendance) === Infinity ? (<>
                                            Not Applicable
                                        </>) : (<>
                                            {getComparisonPercentage(comparison?.details?.suggestion?.average_cost_of_attendance, comparison?.details?.current_selection?.average_cost_of_attendance)}%
                                            {getComparisonPercentage(comparison?.details?.suggestion?.average_cost_of_attendance, comparison?.details?.current_selection?.average_cost_of_attendance) < 0 && (
                                                <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                            )}
                                            {getComparisonPercentage(comparison?.details?.suggestion?.average_cost_of_attendance, comparison?.details?.current_selection?.average_cost_of_attendance) > 0 && (
                                                <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                            )}
                                        </>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Average In State Tuition</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.suggestion?.in_state_tuition ? (<>
                                            ${comparison?.details?.suggestion?.in_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.current_selection?.in_state_tuition ? (<>
                                            ${comparison?.details?.current_selection?.in_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.in_state_tuition, comparison?.details?.current_selection?.in_state_tuition) === Infinity ? (<>
                                            Not Applicable
                                        </>) : (<>
                                            {getComparisonPercentage(comparison?.details?.suggestion?.in_state_tuition, comparison?.details?.current_selection?.in_state_tuition)}%
                                            {getComparisonPercentage(comparison?.details?.suggestion?.in_state_tuition, comparison?.details?.current_selection?.in_state_tuition) < 0 && (
                                                <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                            )}
                                            {getComparisonPercentage(comparison?.details?.suggestion?.in_state_tuition, comparison?.details?.current_selection?.in_state_tuition) > 0 && (
                                                <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                            )}
                                        </>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Average Earnings Six Years After Entry</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.suggestion?.median_earnings_six_years_after_entry ? (<>
                                            ${comparison?.details?.suggestion?.median_earnings_six_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.current_selection?.median_earnings_six_years_after_entry ? (<>
                                            ${comparison?.details?.current_selection?.median_earnings_six_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_six_years_after_entry, comparison?.details?.current_selection?.median_earnings_six_years_after_entry) === Infinity ? (<>
                                            Not Applicable
                                        </>) : (<>
                                            {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_six_years_after_entry, comparison?.details?.current_selection?.median_earnings_six_years_after_entry)}%
                                            {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_six_years_after_entry, comparison?.details?.current_selection?.median_earnings_six_years_after_entry) > 0 && (
                                                <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                            )}
                                            {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_six_years_after_entry, comparison?.details?.current_selection?.median_earnings_six_years_after_entry) < 0 && (
                                                <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                            )}
                                        </>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Average Earnings Ten Years After Entry</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.suggestion?.median_earnings_ten_years_after_entry ? (<>
                                            ${comparison?.details?.suggestion?.median_earnings_ten_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.current_selection?.median_earnings_ten_years_after_entry ? (<>
                                            ${comparison?.details?.current_selection?.median_earnings_ten_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_ten_years_after_entry, comparison?.details?.current_selection?.median_earnings_ten_years_after_entry) === Infinity ? (<>
                                            Not Applicable
                                        </>) : (<>
                                            {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_ten_years_after_entry, comparison?.details?.current_selection?.median_earnings_ten_years_after_entry)}%
                                            {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_ten_years_after_entry, comparison?.details?.current_selection?.median_earnings_ten_years_after_entry) > 0 && (
                                                <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                            )}
                                            {getComparisonPercentage(comparison?.details?.suggestion?.median_earnings_ten_years_after_entry, comparison?.details?.current_selection?.median_earnings_ten_years_after_entry) < 0 && (
                                                <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                            )}
                                        </>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Acceptance Rate</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.suggestion?.acceptance_rate ? (<>
                                            {Math.ceil(comparison?.details?.suggestion?.acceptance_rate * 100)}%
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.current_selection?.acceptance_rate ? (<>
                                            {Math.ceil(comparison?.details?.current_selection?.acceptance_rate * 100)}%
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.acceptance_rate, comparison?.details?.current_selection?.acceptance_rate) === Infinity ? (<>
                                            Not Applicable
                                        </>) : (<>
                                            {getComparisonPercentage(comparison?.details?.suggestion?.acceptance_rate, comparison?.details?.current_selection?.acceptance_rate)}%
                                            {getComparisonPercentage(comparison?.details?.suggestion?.acceptance_rate, comparison?.details?.current_selection?.acceptance_rate) > 0 && (
                                                <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                            )}
                                            {getComparisonPercentage(comparison?.details?.suggestion?.acceptance_rate, comparison?.details?.current_selection?.acceptance_rate) < 0 && (
                                                <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                            )}
                                        </>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Graduation Rate</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.suggestion?.graduation_rate && comparison?.details?.suggestion?.graduation_rate !== 'NaN%'  ? (<>
                                            {comparison?.details?.suggestion?.graduation_rate}
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.current_selection?.graduation_rate && comparison?.details?.current_selection?.graduation_rate !== 'NaN%'  ? (<>
                                            {comparison?.details?.current_selection?.graduation_rate}
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {(getComparisonPercentage(comparison?.details?.suggestion?.graduation_rate, comparison?.details?.current_selection?.graduation_rate) === Infinity || 
                                        comparison?.details?.current_selection?.graduation_rate === 'NaN%' ||
                                        comparison?.details?.suggestion?.graduation_rate === 'NaN%') ? (<>
                                            Not Applicable
                                        </>) : (<>
                                            {getComparisonPercentage(parseInt(comparison?.details?.suggestion?.graduation_rate), parseInt(comparison?.details?.current_selection?.graduation_rate))}%
                                            {getComparisonPercentage(parseInt(comparison?.details?.suggestion?.graduation_rate), parseInt(comparison?.details?.current_selection?.graduation_rate)) > 0 && (
                                                <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                            )}
                                            {getComparisonPercentage(parseInt(comparison?.details?.suggestion?.graduation_rate), parseInt(comparison?.details?.current_selection?.graduation_rate)) < 0 && (
                                                <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                            )}
                                        </>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Total Admitted</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.suggestion?.total_admitted ? (<>
                                            {comparison?.details?.suggestion?.total_admitted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>
                                        {comparison?.details?.current_selection?.total_admitted ? (<>
                                            {comparison?.details?.current_selection?.total_admitted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </>) : (<>
                                            Not Found
                                        </>)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.total_admitted, comparison?.details?.current_selection?.total_admitted) === Infinity ? (<>
                                            Not Applicable
                                        </>) : (<>
                                            {getComparisonPercentage(comparison?.details?.suggestion?.total_admitted, comparison?.details?.current_selection?.total_admitted)}%
                                            {getComparisonPercentage(comparison?.details?.suggestion?.total_admitted, comparison?.details?.current_selection?.total_admitted) > 0 && (
                                                <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                            )}
                                            {getComparisonPercentage(comparison?.details?.suggestion?.total_admitted, comparison?.details?.current_selection?.total_admitted) < 0 && (
                                                <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                            )}
                                        </>)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {(type === 'student' || type === 'parent') && (
                        <Button
                            variant="contained"
                            type="submit"
                            disableElevation
                            size="large"
                            fullWidth
                            onClick={handleChangeInstitution}
                        >
                            Change To This Institution
                        </Button>
                    )}
                    {(type === 'self_educator' || type === 'self_parent') && Object.keys(selectedSuggestion).length === 0 && (
                        <>
                            <TextField
                                name="description"
                                fullWidth
                                id="description"
                                label="Add A Note"
                                type="text"
                                multiline
                                rows={5}
                                value={comparisonDescription}
                                onChange={onSuggestionDescriptionChange}
                                sx={{mb: 2}}
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                disableElevation
                                size="large"
                                fullWidth
                                onClick={handleAddSuggestion}
                            >
                                Suggest This Institution
                            </Button>
                        </>
                    )}
                </>
            )}
        </Box>
    )
}