import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import { SuggestionItem } from './lists/suggestionItem';
import { getFavorites } from '../../../services/favoriteService';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function SuggestionsList({type, suggestions, setSelectedSuggestion, systemSuggestions, attachedUser, setReloadList, setOpenSuggestionsDrawer, setSpecificType, setError, setErrorText}) {
    const [tabValue, setTabValue] = React.useState(0);
    const [favorites, setFavorites] = React.useState([]);

    React.useEffect(() => {
        if (type === 'student') {
            getFavorites(localStorage.getItem('token'), '*').then(favorites => {
                if (favorites.status == true) {
                    setFavorites(favorites.data);
                } else {
                    setError(true);
                    setErrorText(favorites.message);
                }
            }).catch(err => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            });
        }
    }, []);
  
    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tooltip title="ROI (The Owl) is the name of our artificial intelligence engine! He is designed to help suggest occupations, industries and schools that may be a better fit and will help out financially. Bear with him, he's still learning!" placement="bottom" arrow>
                        <Tab label="Suggestions From ROI" icon={<InfoIcon fontSize="small" />} iconPosition="end" {...a11yProps(0)} sx={{width:'50%', maxWidth:'50%'}} />
                    </Tooltip>
                    <Tab label={type === 'parent' ? "Other Suggestions" : "Suggestions From Advisors"} {...a11yProps(1)} sx={{width:'50%', maxWidth:'50%'}} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0} sx={{position:'relative'}}>
                {systemSuggestions?.length > 0 ? (
                    <>
                        {systemSuggestions?.map((suggestion, index) => (
                            <SuggestionItem
                                key={index}
                                type={type}
                                attachedUser={attachedUser}
                                suggestion={suggestion}
                                setSelectedSuggestion={setSelectedSuggestion}
                                setReloadList={setReloadList}
                                setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                                setSpecificType={setSpecificType}
                                favorites={favorites}
                                setFavorites={setFavorites}
                                setError={setError}
                                setErrorText={setErrorText}
                            />
                        ))}
                    </>
                ) : (
                    <>
                        <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                            {type === 'student' ? 'You do' : 'This student does'} not have any suggestions from ROI.
                        </Typography>
                    </>
                )}
            </TabPanel>
            <TabPanel value={tabValue} index={1} sx={{position:'relative'}}>
                {suggestions?.length > 0 ? (
                    <>
                        {suggestions?.map((suggestion, index) => (
                            <SuggestionItem
                                key={index}
                                type={type}
                                attachedUser={attachedUser}
                                suggestion={suggestion}
                                setSelectedSuggestion={setSelectedSuggestion}
                                setReloadList={setReloadList}
                                setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                                setSpecificType={setSpecificType}
                                favorites={favorites}
                                setFavorites={setFavorites}
                                setError={setError}
                                setErrorText={setErrorText}
                            />
                        ))}
                    </>
                ) : (
                    <>
                        <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                            {type === 'student' ? 'You do' : 'This student does'} not have any suggestions.<br />
                            {type === 'self_educator' && (
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='large'
                                    sx={{mt: 2}}
                                    onClick={() => setOpenSuggestionsDrawer({})}
                                    disabled={attachedUser?.active ? false : true}
                                >
                                    Add Suggestion
                                </Button>
                            )}
                        </Typography>
                    </>
                )}
            </TabPanel>
        </Box>
    )
}