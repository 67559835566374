import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { getParentsByEmployee, getParents, assignParents } from '../../../../../services/educationActionsService';
import { getEmployees } from '../../../../../services/educationActionsService';
import { ParentAdd } from '../../../parents/components/parentAdd';

export function ParentsComponent({student, educator, selectedEducator, studentParents}) {
    const [parentList, setParentList] = React.useState([]);
    const [allParentList, setAllParentList] = React.useState([]);
    const [openAssignModal, setOpenAssignModal] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [checkedParents, setCheckedParents] = React.useState([]);
    const [employees, setEmployees] = React.useState(false);
    const handleCloseAssignModal = () => setOpenAssignModal(false);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const handleCloseAddModal = () => setOpenAddModal(false);

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 400,
      minWidth: 300,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      textAlign:'center'
    };

    React.useEffect(() => {
        let filteredParents = [];
        if (selectedEducator?.parents && studentParents) {
            const educatorParents = selectedEducator.parents;
            filteredParents = studentParents.filter(studentParent =>
                educatorParents.some(eduStudent => eduStudent.id === studentParent.id)
            );

            setParentList(filteredParents);
        }

        if (educator?.user_level === 1 || educator?.user_level === 2) {
            getParents(localStorage.getItem('token')).then(parentsData => {
                if (parentsData.status == true) {
                    const finalizedFilteredParents = parentsData.data?.filter((parent) => !filteredParents.some((p) => p.id === parent.id));
                    
                    let finalizedAllStudentList = [];
                    for (let index = 0; index < finalizedFilteredParents.length; index++) {
                        if (finalizedFilteredParents[index].pending === 0 && finalizedFilteredParents[index].account_locked === 0 && finalizedFilteredParents[index].active === 1) {
                            finalizedAllStudentList.push(finalizedFilteredParents[index])
                        }
                    }
                    setAllParentList(finalizedAllStudentList);
                }
                getEmployees(localStorage.getItem('token')).then(employees => {
                  if (employees.status == true) {
                    setEmployees(employees.data);
                  }
                })
            });
        } else {
            getParentsByEmployee(localStorage.getItem('token'), educator.id).then(parentsData => {
                if (parentsData.status == true) {
                    setAllParentList(parentsData.data?.filter((parent) => !filteredParents.some((p) => p.id === parent.id)));
                }
            });
        }
    }, [selectedEducator, studentParents]);

    const viewParent = (id) => {
        window.location.href = '/educator/parents?parent_id=' + id;
    };

    const handleParentChange = (event) => {
      const {
        target: { value },
      } = event;
      setCheckedParents(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleSelectRender = (selected) => {
        let selectedNames = [];
        selected.forEach(id => {
            allParentList?.forEach(parent => {
                if (id === parent.id) {
                    selectedNames.push(parent.first_name + ' ' + parent.last_name);
                }
            });
        });
        return selectedNames.join(', ');
    }

    const getParentName = (parent) => {
        return parent.first_name + ' ' + parent.last_name;
    }

    const handleAddParents = (event) => {
        let controlledParents = '[' + [...new Set(checkedParents)].toString() + ']';
        let data = new URLSearchParams({
            'parents':controlledParents,
        });
    
        assignParents(localStorage.getItem('token'), student.id, data).then(update => {
            if (update?.status == true) {
                setCheckedParents([]);
                handleCloseAssignModal();
                window.location.href = '/educator/students';
            } else {
                setErrorText(update.message);
            }
        });
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={8}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Student Parents
                    </Typography>
                </Grid>
                <Grid item xs={4} align='right'>
                    <Button
                        variant='contained'
                        disableElevation
                        size='small'
                        sx={{mr: 1}}
                        onClick={() => setOpenAssignModal(true)}
                        disabled={educator?.active || educator?.account_locked ? false : true}
                    >
                        Assign Parent
                    </Button>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid 
                container 
                alignItems="center" 
                justifyContent="center" 
                spacing={3}
            >
                <Grid item xs={12}>
                    {parentList?.length > 0 ? (
                        <>
                            {parentList?.map((parent, index) => (
                                <Box sx={{ width: '100%' }} key={index} >
                                    <Grid 
                                        container 
                                        spacing={2}
                                        alignItems="center" 
                                        justifyContent="center" 
                                    >
                                        <Grid item xs={12} sm={8}>
                                            {!parent?.first_name && !parent?.last_name ? (
                                                <Typography color='secondary' variant='h6'>{parent?.email}</Typography>
                                            ) : (
                                                <>
                                                    <Typography color='secondary' variant='h6'>{parent?.first_name} {parent?.last_name}</Typography>
                                                    <Typography color='secondary' sx={{mb:1}}>{parent?.email}</Typography>
                                                </>
                                            )}
                                            {parent?.pending === 2 ? (
                                                <Chip size='small' color='primary' sx={{color:'#fff', mr:1}} label='Pending Educator Approval' />
                                            ) : (
                                                <>
                                                    {parent?.active === 0 && (
                                                        <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                                    )} 
                                                    {parent?.active === 1 && (
                                                        <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                                    )}
                                                    {parent?.pending === 1 && (
                                                        <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Student Approval' />
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={4} align='right' alignItems="center" justifyContent="center" sx={{pr:1}}>
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                size="small"
                                                disabled={!educator?.account_locked ? false : true}
                                                onClick={() => { viewParent(parent?.id) }}
                                            >
                                                View
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {index !== parentList?.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                                </Box>
                            ))}
                        </>
                    ) : (
                        <>
                            <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                                No Students Found.
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
            <Modal
                open={openAssignModal}
                onClose={handleCloseAssignModal}
            >
                <Box sx={style}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                                Assign Parents
                            </Typography>
                            {errorText && (
                                <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                                    {errorText}
                                </Typography>
                            )}
                        </Grid>
                        {allParentList?.length > 0 ? (
                            <>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width:'100%', textAlign: 'left' }}>
                                        <InputLabel id="parent-label">Assign A Parent</InputLabel>
                                        <Select
                                            labelId="parent-label"
                                            id="parent-checkbox"
                                            multiple
                                            required
                                            fullWidth
                                            value={checkedParents}
                                            onChange={handleParentChange}
                                            input={<OutlinedInput label="Assign A Parent" />}
                                            renderValue={(selected) => handleSelectRender(selected)}
                                        >
                                            {allParentList.map((parent) => (
                                                <MenuItem key={parent.id} value={parent.id} disabled={educator.active && !educator.account_locked ? false : true}>
                                                    <Checkbox checked={checkedParents.indexOf(parent.id) > -1} />
                                                    <ListItemText primary={getParentName(parent)} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disableElevation
                                        size="large"
                                        fullWidth
                                        onClick={handleAddParents}
                                        disabled={checkedParents.length !== 0 ? false : true}
                                    >
                                        Assign Parent(s)
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Typography textAlign={'center'} variant='h6' component='div'>
                                    No parents found, please add a new parent to assign them.
                                </Typography>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='large'
                                    fullWidth
                                    sx={{mt:1}}
                                    onClick={() => setOpenAddModal(true)}
                                    disabled={educator?.active || educator?.account_locked ? false : true}
                                >
                                    Invite A Parent
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openAddModal}
                onClose={handleCloseAddModal}
            >
                <>
                <ParentAdd
                    educator={educator}
                    employees={employees}
                    handleCloseAddModal={handleCloseAddModal}
                />
                </>
            </Modal>
        </>
    )
}