import React, { Suspense } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { removeSubscription } from '../../../../../../services/parentBillingService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function SubscriptionStop({handleCloseCancelModal, setError, setErrorText}) {
    const handleCancelSubscription = () => {
        handleCloseCancelModal();
        removeSubscription(localStorage.getItem('token')).then(response => {
            if (response.status == true) {
                window.location.reload();
            } else {
                setError(true);
                setErrorText(response.errors);
            }
        });
    };

    return (
        <Box sx={style}>
            <WarningIcon
                color="primary"
                fontSize="large"
            />
            <Typography variant="h6" component="h2">
            Are you sure you want to cancel your subscription? You will be prorated for any time left on your subscription.
            </Typography>
            <Grid container spacing={2} sx={{mt:1}}>
                <Grid item xs={12} sm={6}>
                    <Button 
                        variant='contained'
                        color='primary'
                        disableElevation
                        sx={{width:'100%'}}
                        onClick={handleCloseCancelModal}
                    >
                        Go Back
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <Button 
                        variant='outlined'
                        color='secondary'
                        sx={{width:'100%'}}
                        onClick={handleCancelSubscription}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};