import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentsIcon from '@mui/icons-material/Payments';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import GroupsIcon from '@mui/icons-material/Groups';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import EmailIcon from '@mui/icons-material/Email';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HandymanIcon from '@mui/icons-material/Handyman';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PaidIcon from '@mui/icons-material/Paid';
import TollIcon from '@mui/icons-material/Toll';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
import { logOut } from '../../../services/userService';

const runLogOut = () => {
  logOut(localStorage.getItem('token')).then(logOut => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  });
}

export function MainListItems({userLevel, accountLocked, accountSoftLocked, messagesCount}) {
  return (
    <React.Fragment>
      <ListItemButton 
        component="a" 
        href="/educator/dashboard" 
        selected={(window.location.pathname.includes('dashboard')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/educator/students" 
        selected={(window.location.pathname.includes('students')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText primary="Students" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/educator/parents" 
        selected={(window.location.pathname.includes('parents')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Parents" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/educator/messages" 
        selected={(window.location.pathname.includes('messages')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Messages" />
        {(messagesCount > 0) && (
          <Chip 
            label={messagesCount}
            color='primary'
            size='small'
            sx={{
              color: 'white',
            }}
          />
        )}
      </ListItemButton>
      {(userLevel === 1 || userLevel === 2) && (
        <>
          <ListItemButton 
            component="a" 
            href="/educator/employees" 
            selected={(window.location.pathname.includes('employees')) ? true : false}
            disabled={(accountLocked || accountSoftLocked) ? true : false}
          >
            <ListItemIcon>
              <BadgeIcon />
            </ListItemIcon>
            <ListItemText primary="Employees" />
          </ListItemButton>
          <ListItemButton 
            component="a" 
            href="/educator/reports" 
            selected={(window.location.pathname.includes('reports')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </>
      )}
    </React.Fragment>
  )
};

export function SecondaryListItems({userData, alertInstitution, alertPayments, accountLocked, accountSoftLocked, openSidebar}) {
  const [open, setOpen] = React.useState(window.location.pathname.includes('billing') ? true : false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Business Settings
      </ListSubheader>
      <ListItemButton
        component="a"
        href="/educator/details"
        selected={(window.location.pathname.includes('details')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary="Details" />
      </ListItemButton>
      <ListItemButton 
        onClick={handleClick} 
        selected={(window.location.pathname.includes('billing')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="Billing" />
        {(alertPayments) && (
          <ListItemIcon>
            <WarningAmberIcon color="primary" />
          </ListItemIcon>
        )}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton 
            sx={{ pl: openSidebar === true ? 4 : 2 }}
            component="a"
            href="/educator/billing/subscription"
            selected={(window.location.pathname.includes('subscription')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <PaidIcon />
            </ListItemIcon>
            <ListItemText primary="Your Subscription" />
            {(alertPayments) && (
              <ListItemIcon>
                <WarningAmberIcon color="primary" />
              </ListItemIcon>
            )}
          </ListItemButton>
          <ListItemButton 
            sx={{ pl: openSidebar === true ? 4 : 2 }}
            component="a"
            href="/educator/billing/advertising"
            selected={(window.location.pathname.includes('advertising')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <TollIcon />
            </ListItemIcon>
            <ListItemText primary="Advertising Balance" />
          </ListItemButton>
          <ListItemButton 
            sx={{ pl: openSidebar === true ? 4 : 2 }}
            component="a"
            href="/educator/billing/invoices"
            selected={(window.location.pathname.includes('invoices')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Invoice History" />
          </ListItemButton>
        </List>
      </Collapse>
    </React.Fragment>
  )
};

export function AdvertisementListItems({accountLocked, accountSoftLocked}) {
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Listings
      </ListSubheader>
      <ListItemButton 
        component="a" 
        href="/educator/advertisements" 
        selected={(window.location.pathname.includes('advertisements')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <NewspaperIcon />
        </ListItemIcon>
        <ListItemText primary="List An Ad" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/educator/jobs" 
        selected={(window.location.pathname.includes('jobs')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <HandymanIcon />
        </ListItemIcon>
        <ListItemText primary="List A Job" />
      </ListItemButton>
      <ListItemButton
        component="a"
        href="/educator/scholarships"
        selected={(window.location.pathname.includes('scholarships')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <AttachMoneyIcon />
        </ListItemIcon>
        <ListItemText primary="List A Scholarship" />
      </ListItemButton>
    </React.Fragment>
  )
};

export function TertiaryListItems({accountLocked, accountSoftLocked}) { 
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Profile Settings
      </ListSubheader>
      <ListItemButton 
        component="a" 
        href="/educator/settings" 
        selected={(window.location.pathname.includes('settings')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
      <ListItemButton onClick={runLogOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Log Out" />
      </ListItemButton>
    </React.Fragment>
  )
};
