import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { activateParents, deactivateParents } from '../../../../../services/educationActionsService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function ActivationComponent({selectedEducator, parent, currentEducator, deactivatedGlobally}) {
    const [toolTipText, setToolTipText] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const [activation, setActivation] = React.useState(1);
    const [statusEvent, setStatusEvent] = React.useState(0);
    const [openStatusModal, setOpenStatusModal] = React.useState(false);
    const handleCloseStatusModal = () => setOpenStatusModal(false);
    const [errorText, setErrorText] = React.useState('');

    React.useEffect(() => {
        setActivation(selectedEducator?.active);
        if (selectedEducator?.active === 1) {
            if (selectedEducator?.id === currentEducator?.id) {
                setToolTipText('Since this parent is assigned to you, you can edit if they are connected to them here.');
                setDisabled(false);
            } else {
                setToolTipText('You cannot change the status of another educator here. You will have to edit the parent institutionally.');
                setDisabled(true);
            }
        } else {
            if (selectedEducator?.id === currentEducator?.id) {
                if (selectedEducator?.deactivated_by === currentEducator?.id) {
                    setToolTipText('Since this parent is assigned to you, you can edit if they are connected to them here.');
                    setDisabled(false);
                } else {
                    setToolTipText('This parent removed you from their list of parents. You cannot edit their status. If this was a mistake, contact them directly.');
                    setDisabled(true);
                }
            } else {
                setToolTipText('You cannot change the status of another educator here. You will have to edit the parent institutionally.');
                setDisabled(true);
            }
        }
    }, [selectedEducator]);

    const handleActivation = (event) => {
        let data = new URLSearchParams({
            'id' : parent?.id
        });
        if (statusEvent === 1) {
            activateParents(localStorage.getItem('token'), data).then(update => {
                if (update?.status == true) {
                    setActivation(1);
                    setErrorText('');
                    handleCloseStatusModal();
                } else {
                    setErrorText(update.message);
                }
            });
        } else {
            deactivateParents(localStorage.getItem('token'), data).then(update => {
                if (update?.status == true) {
                    setActivation(0);
                    setErrorText('');
                    handleCloseStatusModal();
                } else {
                    setErrorText(update.message);
                }
            });
        }
    };

    const handleChange = (event, values) => {
        setStatusEvent(event.target.value);
        setOpenStatusModal(true);
    }

    return (
        <>
            <Tooltip 
                arrow 
                placement='top'
                title={toolTipText}
            >
                <FormControl size='small'>
                    <Select
                        labelId="activation"
                        id="activation"
                        onChange={handleChange}
                        value={activation}
                        disabled={disabled}
                        sx={{ 
                            color: '#777777', 
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { 
                                border: 0 
                            },
                            '.MuiSelect-icon': { 
                                visibility: 'hidden'
                            },
                            '&:hover': {
                                backgroundColor: '#f1f1f1',
                                '.MuiSelect-icon': { 
                                    visibility: 'visible'
                                }
                            }
                        }}
                    >
                        <MenuItem value={1}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{minWidth:'20px'}}>
                                    <RadioButtonUncheckedIcon 
                                        sx={{
                                            color: 'green',
                                            fontSize: '12px'
                                        }} 
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Active" />
                            </div>
                        </MenuItem>
                        <MenuItem value={0}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{minWidth:'20px'}}>
                                    <RadioButtonUncheckedIcon 
                                        sx={{
                                            color: 'red',
                                            fontSize: '12px'
                                        }} 
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Inactive" />
                            </div>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Tooltip>
            <Modal
                open={openStatusModal}
                onClose={handleCloseStatusModal}
            >
                <Box sx={style}>
                    {errorText && (
                        <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                            {errorText}
                        </Typography>
                    )}
                    {deactivatedGlobally ? 'This parent has been deactivated from the institution. You cannot edit their status. To activate them, you will have to contact a manager or the owner of the institution to activate the parent.' : (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {statusEvent === 1 ? 
                                    'Are you sure you want to activate this parent?' : 
                                    'Are you sure you want to deactivate this parent? You will be able to activate them later and you will still be able to see past interactions.'
                                }
                            </Typography>
                            <Button
                                variant="contained"
                                type="submit"
                                disableElevation
                                size="large"
                                fullWidth
                                onClick={handleActivation}
                            >
                                {statusEvent === 1 ? 'Activate' : 'Deactivate'} Parent
                            </Button>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    )
}