import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { EducationBlock } from './views/education';
import { OccupationBlock } from './views/occupation';
import { IndustryBlock } from './views/industry';
import { getOccupationsList } from '../../../services/employmentService';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function SuggestionsView({type, selectedSuggestion, specificType, student, setReloadList, handleCloseDrawer, setError, setErrorText}) {
    const [tabValue, setTabValue] = React.useState(0);
    const [selectedOccupation, setSelectedOccupation] = React.useState({});

    React.useEffect(() => {
        getOccupationsList().then(response => {
            if (!response?.data || !student?.investments?.[0]) return;
            const selected = response.data.find(item => 
                item?.occupation_code === student.investments[0]?.occupation
            );
            if (selected) setSelectedOccupation(selected);
        });
    }, []);
  
    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{p:2}}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                        {Object.keys(selectedSuggestion).length === 0 ? 'Compare With Others' : 'Suggestion'}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2 }} />
                </Grid>
                <Grid item xs={12}>
                    {Object.keys(selectedSuggestion).length === 0 ? (
                        <>
                        {specificType !== false ? (
                            <>
                                {specificType === 'education' && (
                                    <EducationBlock 
                                        type={type}
                                        selectedSuggestion={selectedSuggestion} 
                                        student={student}
                                        setReloadList={setReloadList}
                                        handleCloseDrawer={handleCloseDrawer}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                    />
                                )}
                                {specificType === 'occupation' && (
                                    <OccupationBlock 
                                        type={type}
                                        selectedSuggestion={selectedSuggestion} 
                                        student={student}
                                        setReloadList={setReloadList}
                                        handleCloseDrawer={handleCloseDrawer}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                    />
                                )}
                                {specificType === 'industry' && (
                                    <IndustryBlock 
                                        type={type}
                                        selectedSuggestion={selectedSuggestion} 
                                        student={student}
                                        occupation={selectedOccupation}
                                        setReloadList={setReloadList}
                                        handleCloseDrawer={handleCloseDrawer}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                    />
                                )}
                            </>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label="School/Institution" {...a11yProps(0)} sx={{width:'33.33%', maxWidth:'33.33%'}} />
                                        <Tab label="Occuaption" {...a11yProps(1)} sx={{width:'33.33%', maxWidth:'33.33%'}} />
                                        <Tab label="Industry" {...a11yProps(2)} sx={{width:'33.33%', maxWidth:'33.33%'}} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tabValue} index={0} sx={{position:'relative'}}>
                                    <EducationBlock 
                                        type={type}
                                        selectedSuggestion={selectedSuggestion} 
                                        student={student}
                                        setReloadList={setReloadList}
                                        handleCloseDrawer={handleCloseDrawer}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                    />                                
                                </TabPanel>
                                <TabPanel value={tabValue} index={1} sx={{position:'relative'}}>
                                    <OccupationBlock 
                                        type={type}
                                        selectedSuggestion={selectedSuggestion} 
                                        student={student}
                                        setReloadList={setReloadList}
                                        handleCloseDrawer={handleCloseDrawer}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                    />
                                </TabPanel>
                                <TabPanel value={tabValue} index={2} sx={{position:'relative'}}>
                                    <IndustryBlock 
                                        type={type}
                                        selectedSuggestion={selectedSuggestion} 
                                        student={student}
                                        occupation={selectedOccupation}
                                        setReloadList={setReloadList}
                                        handleCloseDrawer={handleCloseDrawer}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                    />
                                </TabPanel>
                            </Box>
                        )}
                        </>
                    ) : (
                        <>
                            {specificType === 'education' && (
                                <EducationBlock 
                                    type={type}
                                    selectedSuggestion={selectedSuggestion} 
                                    student={student}
                                    setReloadList={setReloadList}
                                    handleCloseDrawer={handleCloseDrawer}
                                    setError={setError}
                                    setErrorText={setErrorText}
                                />
                            )}
                            {specificType === 'occupation' && (
                                <OccupationBlock 
                                    type={type}
                                    selectedSuggestion={selectedSuggestion} 
                                    student={student}
                                    setReloadList={setReloadList}
                                    handleCloseDrawer={handleCloseDrawer}
                                    setError={setError}
                                    setErrorText={setErrorText}
                                />
                            )}
                            {specificType === 'industry' && (
                                <IndustryBlock 
                                    type={type}
                                    selectedSuggestion={selectedSuggestion} 
                                    student={student}
                                    occupation={selectedOccupation}
                                    setReloadList={setReloadList}
                                    handleCloseDrawer={handleCloseDrawer}
                                    setError={setError}
                                    setErrorText={setErrorText}
                                />
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </> 
    )
}