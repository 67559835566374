import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import { getIndustriesList, getOccupationsList } from '../../../../services/employmentService';
import { getComparisons, addSuggestion } from '../../../../services/suggestionsService';
import { updateOccupation, updateParentOccupation } from '../../../../services/employmentService';

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
                {value}
            </text>
        );
    }
}

export function IndustryBlock({type, selectedSuggestion, student, occupation, setReloadList, handleCloseDrawer, setError, setErrorText}) {
    const [industries, setIndustries] = React.useState([]);
    const [selectedOccupation, setSelectedOccupation] = React.useState(occupation);
    const [industry, setIndustry] = React.useState({});
    const [comparison, setComparison] = React.useState({});
    const [investmentId, setInvestmentId] = React.useState(null);
    const [comparisonDescription, setComparisonDescription] = React.useState(null);

    const year = new Date().getFullYear();
    const beginningYear = Math.floor(year/10)*10;
    const endYear = Math.ceil(year/10)*10;

    React.useEffect(() => {
        if (occupation?.occupation_code) {
            getIndustriesList(occupation.occupation_code).then(getIndustriesList => {
                setIndustries(getIndustriesList.data);
            });
        }
        
        if (Object.keys(selectedSuggestion).length !== 0) {
            let comparisonData = structuredClone(selectedSuggestion);

            if (comparisonData.details.current_selection !== null) {
                comparisonData.details.current_selection['graph_data'] = buildGraphData(comparisonData.details.current_selection);
            }
            comparisonData.details.suggestion['graph_data'] = buildGraphData(comparisonData.details.suggestion);
            setComparison(comparisonData);
        };
        if (type === 'student' || type === 'parent') {
            setInvestmentId(student.investments[0].id);
        }
    }, []);

    const handleAddSuggestion = () => {
        let data = new URLSearchParams({
            'suggestion_id':comparison?.suggestion_id,
            'type':comparison?.type,
            'description':comparisonDescription,
            'attached_user': type === 'student' ? null : student?.id
        });
        addSuggestion(localStorage.getItem('token'), data).then(suggestion => {
            if (suggestion.status) {
                setReloadList(suggestion);
                handleCloseDrawer();
            } else {
                setError(true);
                setErrorText(suggestion.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error adding the suggestion.');
        });
        handleCloseDrawer();
    };

    const handleUpdateOccupation = (event) => {
        let data = new URLSearchParams({
            'occupation':occupation?.occupation_code,
            'industry':comparison?.suggestion_id
        });

        if (type === 'student') {
            updateOccupation(localStorage.getItem('token'), investmentId, data).then(suggestion => {
                setReloadList(suggestion);
                handleCloseDrawer();
            }).catch(err => {
                setError(true);
                setErrorText('There was an error.');
            });
        } else if (type === 'parent') {
            updateParentOccupation(localStorage.getItem('token'), investmentId, data).then(suggestion => {
                setReloadList(suggestion);
                handleCloseDrawer();
            }).catch(err => {
                setError(true);
                setErrorText('There was an error.');
            });
        }
    };

    const onSuggestionDescriptionChange = (event) => {
        setComparisonDescription(event.target.value);
    };

    const onIndustryChange = (event, values) => {
        setIndustry(null);
        if (values && values.industry_code !== null) {
            setIndustry(values.industry_code);
            getComparisons(localStorage.getItem('token'), student.id, values.industry_code, 'industry').then(comparisonData => {
                if (comparisonData.status == true) {
                    let comparisons = structuredClone(comparisonData.data);
        
                    if (comparisons.details.current_selection !== null) {
                        comparisons.details.current_selection['graph_data'] = buildGraphData(comparisons.details.current_selection);
                    }
                    comparisons.details.suggestion['graph_data'] = buildGraphData(comparisons.details.suggestion);
                    setComparison(comparisons);
                } else {
                    setError(true);
                    setErrorText(comparisonData.message);
                    handleCloseDrawer();
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
                handleCloseDrawer();
            });
        }
    }

    const buildGraphData = (data) => {
        let graphData= [];
        if (data !== null) {
            for (var i = beginningYear; i <= endYear; i++) {
                graphData.push({'year': i});
            }
            for (let i = 0; i < graphData.length; i++) {
                let yearlyEmploymentIncrease = data.employment_current + ((data.employment_change / (endYear - beginningYear)) * i);
                graphData[i]['noj'] = yearlyEmploymentIncrease;
            }
        }
        return graphData;
    }

    return (
        <Box sx={{ width: '100%' }}>
            {Object.keys(selectedSuggestion).length === 0 && (
                <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{mb: 2}}>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl fullWidth disabled>
                            <InputLabel id="occupation">Selected Occupation</InputLabel>
                            <Select
                                labelId="occupation"
                                id="occupation"
                                label="Selected Occupation"
                                sx={{textAlign:'left'}}
                                value={selectedOccupation?.occupation_code}
                                displayEmpty
                                MenuProps={{
                                    style: {zIndex: 9999}
                                }}
                            >
                                <MenuItem value={selectedOccupation?.occupation_code}>{selectedOccupation?.occupation_title}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Autocomplete
                            disablePortal
                            id="industry"
                            options={industries}
                            name="industry"
                            fullWidth
                            onChange={onIndustryChange}
                            getOptionLabel={industries => industries.industry_title}
                            renderInput={(params) => <TextField {...params} label="Desired Industry (Optional)" />}
                        />
                    </Grid>
                </Grid>
            )}
            {Object.keys(comparison).length === 0 ? (
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                    Start searching for an industy to compare with what {type === 'student' ? 'you have' : 'this student has'} selected.
                </Typography>
            ) : (
                <>
                    {comparison?.description && comparison?.description !== null && (
                        <>
                            <Typography color='primary' variant='h6' component='div' sx={{mt: '-20px'}}>
                                Note from educator:
                            </Typography>
                            <Typography component='div'>
                                {comparison?.description}
                            </Typography>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                        </>
                    )}
                    <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{mb: 2}}>
                        <Grid item md={comparison?.details?.current_selection !== null ? 6 : 12} sm={12} xs={12}>
                            <Typography variant='h5' color='secondary' component='div'>
                                {Object.keys(selectedSuggestion).length !== 0 ? 'Suggested Industry' : 'Selected Industry'}:
                            </Typography>
                            <Typography variant='h6' color='primary' component='div'>
                                {comparison?.details?.suggestion?.industry_title}
                            </Typography>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={6}>
                                    <Typography color='secondary' component='div'>
                                        Projected Employment Opportunities
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div'>
                                        {comparison?.details?.suggestion?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={6}>
                                    <Typography color='secondary' component='div'>
                                        Employment Percentage Change
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div'>
                                        {comparison?.details?.suggestion?.employment_percent_change}%
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                            <Box sx={{ width:'100%', height: comparison?.details?.current_selection === null ? '455px' : '300px' }}>
                                <ResponsiveContainer width='100%' height='100%'>
                                    <LineChart
                                    data={comparison.details.suggestion.graph_data}
                                    margin={{
                                        top: 5,
                                        left: 0,
                                        bottom: 5
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='year' />
                                    <YAxis />
                                    <Legend />
                                    <Line type='monotone' dataKey='noj' name='Projected Number of Jobs' stroke='#fa7918' label={<CustomizedLabel />} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 1 }} />
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
                        {comparison?.details?.current_selection !== null && (
                            <Grid item md={6} sm={12} xs={12}>
                                <Typography variant='h5' color='secondary' component='div'>
                                    Current Industry:
                                </Typography>
                                <Typography variant='h6' color='primary' component='div'>
                                    {comparison?.details?.current_selection?.industry_title}
                                </Typography>
                                <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                                <Grid container alignItems="center" justifyContent="center" spacing={0}>
                                    <Grid item xs={6}>
                                        <Typography color='secondary' component='div'>
                                            Projected Employment Opportunities
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{textAlign:'right'}}>
                                        <Typography color='primary' component='div'>
                                            {comparison?.details?.current_selection?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                                <Grid container alignItems="center" justifyContent="center" spacing={0}>
                                    <Grid item xs={6}>
                                        <Typography color='secondary' component='div'>
                                            Employment Percentage Change
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{textAlign:'right'}}>
                                        <Typography color='primary' component='div'>
                                            {comparison?.details?.current_selection?.employment_percent_change}%
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                                <Box sx={{ width:'100%', height: comparison?.details?.current_selection === null ? '455px' : '300px' }}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <LineChart
                                        data={comparison.details.current_selection.graph_data}
                                        margin={{
                                            top: 5,
                                            left: 0,
                                            bottom: 5
                                        }}
                                        >
                                        <CartesianGrid strokeDasharray='3 3' />
                                        <XAxis dataKey='year' />
                                        <YAxis />
                                        <Legend />
                                        <Line type='monotone' dataKey='noj' name='Projected Number of Jobs' stroke='#fa7918' label={<CustomizedLabel />} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                                <Divider variant="fullWidth" sx={{ mt: 2, mb: 1 }} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {(type === 'student' || type === 'parent') && (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disableElevation
                                    size="large"
                                    fullWidth
                                    onClick={handleUpdateOccupation}
                                >
                                    Change To This Industry
                                </Button>
                            )}
                            {(type === 'self_educator' || type === 'self_parent') && Object.keys(selectedSuggestion).length === 0 && (
                                <>
                                    <TextField
                                        name="description"
                                        fullWidth
                                        id="description"
                                        label="Add A Note"
                                        type="text"
                                        multiline
                                        rows={5}
                                        value={comparisonDescription}
                                        onChange={onSuggestionDescriptionChange}
                                        sx={{mb: 2}}
                                    />
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disableElevation
                                        size="large"
                                        fullWidth
                                        onClick={handleAddSuggestion}
                                    >
                                        Suggest This Industry
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    )
}