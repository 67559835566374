import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { educatorAccount, updateEducatorAccount, updateEducatorPhoto, checkType } from '../../../services/userService';
import { storageUrl } from '../../../constants/globals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Settings = () => {

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [password, setPassword] = useState(null);
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [publicChecked, setPublicChecked] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [openConfirmPasswordModal, setOpenConfirmPasswordModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [formCompleted, setFormCompleted] = React.useState(false);
  const [oldPasswordCompleted, setOldPasswordCompleted] = React.useState(false);
  const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleOpenConfirmPasswordModal = () => setOpenConfirmPasswordModal(true);
  const handleCloseConfirmPasswordModal = () => setOpenConfirmPasswordModal(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  const imageUploader = React.useRef(null);

  const handleImageUpload = (event) => {

    const data = new FormData();
    data.append('profile_image', event.target.files[0]);

    updateEducatorPhoto(localStorage.getItem('token'), data).then(photoData => {
      if (photoData.status) {
        setProfileImage(storageUrl + photoData.data);
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(photoData.message);
      }
    }).catch(err => {
      setOpenErrorModal(true);
      setOpenErrorModalText('There was an error uploading the image.');
    });
  };

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new URLSearchParams({
      'first_name':firstName,
      'last_name':lastName,
      'email':email,
      'password':password,
      'old_password':oldPassword,
      'newsletter_opt_in':newsletterChecked,
      'public':publicChecked
    });

    updateEducatorAccount(localStorage.getItem('token'), data).then(update => {
      setOpenConfirmPasswordModal(false);
      setOpenErrorModal(true);
      setOpenErrorModalText(update.message);
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked) {
          window.location.href = '/educator/dashboard';
        }
        setNewsletterChecked(userData.data.newsletter_opt_in === true ? true : false);
        setFirstName(userData.data.firstName);
        setLastName(userData.data.lastName);
        setEmail(userData.data.email);
        setPublicChecked(userData.data.newsletter_opt_in === true ? true : false);
        if (userData.data.profile_image !== null) {
          setProfileImage(storageUrl + userData.data.profile_image);
        }
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const onFirstNameChange = (event, values) => {
    setFirstName(event.target.value);
    submitCheck(event.target.value, lastName, email);
  }

  const onLastNameChange = (event, values) => {
    setLastName(event.target.value);
    submitCheck(firstName, event.target.value, email);
  }

  const onEmailChange = (event, values) => {
    setEmail(event.target.value);
    submitCheck(firstName, lastName, event.target.value);
  }

  const onNewsletterChange = (event, values) => {
    setNewsletterChecked(event.target.checked);
    submitCheck(firstName, lastName, email);
  }

  const onPublicChange = (event, values) => {
    setPublicChecked(event.target.checked);
    submitCheck(firstName, lastName, email);
  }

  const onPasswordChange = (event, values) => {
    setPassword(event.target.value);
  }

  const onOldPasswordChange = (event, values) => {
    setOldPasswordCompleted(false);
    if (event.target.value) {
      setOldPassword(event.target.value);
    }
    if (
      validatePassword(event.target.value)
    ) {
      setOldPasswordCompleted(true);
    }
  }

  const submitCheck = (first, last, mail) => {
    setFormCompleted(false);
    if (
      first &&
      last &&
      validateEmail(mail)
    ) {
      setFormCompleted(true);
    }
  }

  const validateEmail = (email) => {
    return String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const validatePassword = (password) => {
    return /[A-Z]/       .test(password) &&
           /[a-z]/       .test(password) &&
           /[0-9]/       .test(password) &&
           /[^A-Za-z0-9]/.test(password) &&
           password.length > 4;
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item sm={12} md={6}>
            <Typography color='secondary' variant='h4' component='div'>
              Account Settings
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} align='right'>
            <Button
              variant='contained'
              disableElevation
              size='large'
              onClick={handleOpenConfirmPasswordModal}
              disabled={formCompleted ? false : true}
            >
              Save Settings
            </Button>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="first_name"
              required
              fullWidth
              id="first_name"
              label="First Name"
              autoFocus
              value={firstName || ''}
              type="text"
              onChange={onFirstNameChange}
            />
            <TextField
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="family-name"
              value={lastName || ''}
              type="text"
              sx={{ mt: 3 }}
              onChange={onLastNameChange}
            />
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email || ''}
              type="email"
              sx={{ mt: 3 }}
              onChange={onEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>


            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none"
                }}
              />
              <div
                style={{
                  height: "210px",
                  width: "210px",
                  border: "1px dashed #777",
                  borderRadius: "200px",
                  overflow: "hidden",
                  cursor: "pointer",
                  position: "relative"
                }}
                onClick={() => imageUploader.current.click()}
              >
                {!profileImage && (
                  <>
                    <PhotoCameraIcon
                      fontSize="large"
                      style={{
                        position: "absolute",
                        top: "43%",
                        left: "43%",
                        transform: "translateX(-50%) translateY(-50%)",
                        zIndex: 0,
                        color: "#999",
                        transform: "scale(4)"
                      }}
                    />
                  </>
                )}
                {profileImage && (
                  <>
                    <img
                      src={profileImage}
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        zIndex: 1
                      }}
                    />
                  </>
                )}
              </div>
            </div>


          </Grid>
          <Grid item xs={12}>
            <Tooltip enterTouchDelay={0} title="Must include at least one uppercase character, number and special character" arrow>
              <TextField
                fullWidth
                name="password"
                label="New Password (Optional)"
                id="password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                onChange={onPasswordChange}
                value={password || ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox
                value="allowExtraEmails"
                color="primary"
                name="newsletter_opt_in"
                checked={newsletterChecked}
                onChange={onNewsletterChange}
              />}
              label="Newsletter opt in."
            />
          </Grid>
          <Grid item xs={12} sx={{mt:0}}>
            <FormControlLabel
              control={<Checkbox
                value="allowExtraEmails"
                color="primary"
                name="public"
                checked={publicChecked}
                onChange={onPublicChange}
              />}
              label="Make your account public."
            />
            <Typography sx={{fontSize:'12px'}}>This will advertise your account to students in your area who will be able to contact you. If your institution is not set to be public howerver, then your account will not be shown. This will not affect your students ability to add you by searching you directly.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="fullWidth" sx={{ mb: 4 }} />
            <Grid container alignItems="right" justifyContent="right" spacing={3}>
              <Grid item align='right'>
                <Button
                  variant='contained'
                  disableElevation
                  size='large'
                  onClick={handleOpenConfirmPasswordModal}
                  disabled={formCompleted ? false : true}
                >
                  Save Settings
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        open={openConfirmPasswordModal}
        onClose={handleCloseConfirmPasswordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        component="form"
        onSubmit={handleSubmit}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm your account password to update your settings.
          </Typography>
          <TextField
            fullWidth
            name="old_password"
            label="Current Password"
            id="old_password"
            type={showCurrentPassword ? "text" : "password"}
            sx={{ mt: 2, mb: 2 }}
            onChange={onOldPasswordChange}
            value={oldPassword || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownCurrentPassword}
                  >
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            type="submit"
            disableElevation
            size="large"
            fullWidth
            disabled={oldPasswordCompleted ? false : true}
          >
            Save Settings
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Settings;
