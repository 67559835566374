import React, { PureComponent } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { inviteStudent } from '../../../../services/parentActionsService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

export function StudentAdd({educators, handleCloseAddModal}) {
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [errorText, setErrorText] = React.useState('');
    const [checkedEducators, setCheckedEducators] = React.useState([]);

    const handleEmailChange = (event, values) => {
      setEmail('');
      if (event.target.value) {
        setEmail(event.target.value);
      }
    };

    const handleEduatorChange = (event) => {
      const {
        target: { value },
      } = event;
      setCheckedEducators(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleMessageChange = (event, values) => {
      setMessage('');
      if (event.target.value) {
        setMessage(event.target.value);
      }
    };

    const handleSelectRender = (selected) => {
        let selectedNames = [];
        selected.forEach(id => {
            educators?.forEach(educator => {
                if (id === educator.id) {
                    selectedNames.push(educator.first_name + ' ' + educator.last_name);
                }
            });
        });
        return selectedNames.join(', ');
    }

    const handleAddStudent = (event) => {
        let controlledEducators = '[' + [...new Set(checkedEducators)].toString() + ']';
        let data = new URLSearchParams({
            'email':email,
            'educators':controlledEducators,
            'message':message
        });
    
        inviteStudent(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                setEmail('');
                handleCloseAddModal();
                window.location.href = window.location.href;
            } else {
                setErrorText(update.message);
            }
        });
    };

    const getEducatorName = (educator) => {
        return educator.first_name + ' ' + educator.last_name;
    }

    return (
        <Box sx={style}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                        Invite A Student
                    </Typography>
                    {errorText && (
                        <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                            {errorText}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="email"
                        id="email"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </Grid>
                {educators?.length > 0 && (
                    <Grid item xs={12}>
                        <FormControl sx={{ width:'100%', textAlign: 'left' }}>
                            <InputLabel id="educator-label">Assign An Educator</InputLabel>
                            <Select
                                labelId="educator-label"
                                id="educators-checkbox"
                                multiple
                                required
                                fullWidth
                                value={checkedEducators}
                                onChange={handleEduatorChange}
                                input={<OutlinedInput label="Assign An Educator" />}
                                renderValue={(selected) => handleSelectRender(selected)}
                            >
                                {educators?.map((educator, index) => (
                                    <MenuItem key={educator.id} value={educator.id} disabled={educator.active && !educator.account_locked ? false : true}>
                                        <Checkbox checked={checkedEducators.indexOf(educator.id) > -1} />
                                        <ListItemText primary={getEducatorName(educator)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TextField
                        name="message"
                        fullWidth
                        id="message"
                        label='Add A Message (Optional)'
                        type="text"
                        multiline
                        rows={5}
                        value={message}
                        onChange={handleMessageChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={handleAddStudent}
                        disabled={email ? false : true}
                    >
                        Invite Student
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}