import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { deleteSuggestion } from '../../../../services/suggestionsService';
import { AddRemoveFavorite } from '../../favorites/addRemoveFavorite';

function ThumbDirection({greater, less}) {
    if (less === undefined || less === null) {
        less = 0;
    }

    let up = false;
    let down = false;

    if (greater >= less) {
        up = true;
        down = false;
    }

    if (less >= greater) {
        up = false;
        down = true;
    }
    
    return (
        <>
            {up === true && (
                <ThumbUpIcon sx={{ color: 'green' }} />
            )}
            {down === true && (
                <ThumbDownIcon sx={{ color: 'red' }} />
            )}
        </>
    );
}

export function SuggestionItem({type, attachedUser, suggestion, setSelectedSuggestion, setReloadList, setOpenSuggestionsDrawer, setSpecificType, favorites, setFavorites, setError, setErrorText, removeGutters}) {
    const [openPopper, setOpenPopper] = React.useState(null);
    const [popperSuggestion, setPopperSuggestion] = React.useState(null);

    const canDelete = () => {
        if (attachedUser?.active === 0) {
            return false;
        }
        if (type === 'self_educator') {
            if (suggestion?.educator_id === attachedUser?.id) {
                return true;
            }
        }
        if (type === 'parent') {
            if (suggestion?.parent_id === attachedUser?.id) {
                return true;
            }
        }
        return false;
    };

    const onDeleteChange = () => {
        deleteSuggestion(localStorage.getItem('token'), popperSuggestion?.id).then(task => {
            if (task.status) {
                setReloadList(task);
            } else {
                setError(true);
                setErrorText(task.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error updating the task.');
        });
        handleClosePopper();
    }

    const handleClickPopper = (event, task) => {
        setOpenPopper(event.currentTarget);
        setPopperSuggestion(task);
    };

    const handleClosePopper = () => {
        setOpenPopper(null);
        setPopperSuggestion(null);
    };

    const open = Boolean(openPopper);
  
    const openSuggestionDrawer = () => {
        setSelectedSuggestion(suggestion);
        setSpecificType(suggestion?.type);
        setOpenSuggestionsDrawer(true);
    };

    return (
        <Container maxWidth={false} disableGutters={(type === 'student' && !removeGutters) ? false : true}>
            <Grid 
                container 
                alignItems="center" 
                justifyContent="center" 
                spacing={0}
            >
                <Grid item xs={canDelete() ? 11 : 12}>
                    <Grid container disableGutters={true} justifyContent="center" alignItems="center" sx={{position:'relative'}}>
                        {suggestion?.type === 'education' && (
                            <>
                                {type === 'student' && (
                                    <Box
                                        sx={{
                                            position:'absolute',
                                            right: '-5px',
                                            top: '-20px',
                                            zIndex: 9
                                        }}
                                    >
                                        <AddRemoveFavorite
                                            allFavorites={favorites}
                                            setAllFavorites={setFavorites}
                                            potentialFavorite={{
                                                id: suggestion?.suggestion_id,
                                                type: 1,
                                                title: suggestion?.details?.suggestion?.college_name
                                            }}
                                            size={'small'}
                                            setError={setError}
                                            setErrorText={setErrorText}
                                        />
                                    </Box>
                                )}
                                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'center', sm: 'left' }, mb: {xs: 1, sm: 0} }}>
                                    <Grid item>
                                        <Link onClick={openSuggestionDrawer} color='primary' sx={{cursor:'pointer',fontWeight:'bold'}}>{suggestion?.details?.suggestion?.college_name}</Link>
                                    </Grid>
                                    <Grid item>
                                        {suggestion?.educator?.id && (
                                            <Chip label={'Suggested By: ' + suggestion?.educator?.first_name + ' ' + suggestion?.educator?.last_name} size="small" sx={{mt:1, mr:1}} />
                                        )}
                                        {suggestion?.parent?.id && (
                                            <Chip label={'Suggested By: ' + suggestion?.parent?.first_name + ' ' + suggestion?.parent?.last_name} size="small" sx={{mt:1, mr:1}} />
                                        )}
                                        <Chip label={suggestion?.type.charAt(0).toUpperCase() + suggestion?.type.slice(1)} size="small" sx={{mt:1, mr:1}} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{textAlign:'center', mb: {xs: 1, sm: 0}}}>
                                    <Typography color={'secondary'}>
                                        Average Cost
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        ${suggestion?.details?.suggestion?.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.current_selection?.average_cost_of_attendance}
                                        less={suggestion?.details?.suggestion?.average_cost_of_attendance}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{textAlign:'center', mb: {xs: 1, sm: 0}}}>
                                    <Typography color={'secondary'}>
                                        In State Cost
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        ${suggestion?.details?.suggestion?.in_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.current_selection?.in_state_tuition}
                                        less={suggestion?.details?.suggestion?.in_state_tuition}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{textAlign:'center'}}>
                                    <Typography color={'secondary'}>
                                        Acceptance Rate
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        {Math.round(suggestion?.details?.suggestion?.acceptance_rate * 100)}%
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.suggestion?.acceptance_rate}
                                        less={suggestion?.details?.current_selection?.acceptance_rate}
                                    />
                                </Grid>
                            </>
                        )}
                        {suggestion?.type === 'occupation' && (
                            <>
                                {type === 'student' && (
                                    <Box
                                        sx={{
                                            position:'absolute',
                                            right: '-5px',
                                            top: '-20px',
                                            zIndex: 9
                                        }}
                                    >
                                        <AddRemoveFavorite
                                            allFavorites={favorites}
                                            setAllFavorites={setFavorites}
                                            potentialFavorite={{
                                                id: suggestion?.suggestion_id,
                                                type: 2,
                                                title: suggestion?.details?.suggestion?.occupation_title
                                            }}
                                            size={'small'}
                                            setError={setError}
                                            setErrorText={setErrorText}
                                        />
                                    </Box>
                                )}
                                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'center', sm: 'left' }, mb: {xs: 1, sm: 0} }}>
                                    <Grid item>
                                        <Link onClick={openSuggestionDrawer} color='primary' sx={{cursor:'pointer',fontWeight:'bold'}}>{suggestion?.details?.suggestion?.occupation_title}</Link>
                                    </Grid>
                                    <Grid item>
                                        {suggestion?.educator?.id && (
                                            <Chip label={'Suggested By: ' + suggestion?.educator?.first_name + ' ' + suggestion?.educator?.last_name} size="small" sx={{mt:1, mr:1}} />
                                        )}
                                        {suggestion?.parent?.id && (
                                            <Chip label={'Suggested By: ' + suggestion?.parent?.first_name + ' ' + suggestion?.parent?.last_name} size="small" sx={{mt:1, mr:1}} />
                                        )}
                                        <Chip label={suggestion?.type.charAt(0).toUpperCase() + suggestion?.type.slice(1)} size="small" sx={{mt:1, mr:1}} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{textAlign:'center', mb: {xs: 1, sm: 0}}}>
                                    <Typography color={'secondary'}>
                                        Annual Wage
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        ${suggestion?.details?.suggestion?.median_annual_wage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.suggestion?.median_annual_wage}
                                        less={suggestion?.details?.current_selection?.median_annual_wage}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{textAlign:'center', mb: {xs: 1, sm: 0}}}>
                                    <Typography color={'secondary'}>
                                        Current Employment
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        {suggestion?.details?.suggestion?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.suggestion?.employment_current}
                                        less={suggestion?.details?.current_selection?.employment_current}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{textAlign:'center'}}>
                                    <Typography color={'secondary'}>
                                        Projected Employment
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        {suggestion?.details?.suggestion?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.suggestion?.employment_projected}
                                        less={suggestion?.details?.current_selection?.employment_projected}
                                    />
                                </Grid>
                            </>
                        )}
                        {suggestion?.type === 'industry' && (
                            <>
                                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'center', sm: 'left' }, mb: {xs: 1, sm: 0} }}>
                                    <Grid item>
                                        <Link onClick={openSuggestionDrawer} color='primary' sx={{cursor:'pointer',fontWeight:'bold'}}>{suggestion?.details?.suggestion?.industry_title}</Link>
                                    </Grid>
                                    <Grid item>
                                        {suggestion?.educator?.id && (
                                            <Chip label={'Suggested By: ' + suggestion?.educator?.first_name + ' ' + suggestion?.educator?.last_name} size="small" sx={{mt:1, mr:1}} />
                                        )}
                                        {suggestion?.parent?.id && (
                                            <Chip label={'Suggested By: ' + suggestion?.parent?.first_name + ' ' + suggestion?.parent?.last_name} size="small" sx={{mt:1, mr:1}} />
                                        )}
                                        <Chip label={suggestion?.type.charAt(0).toUpperCase() + suggestion?.type.slice(1)} size="small" sx={{mt:1, mr:1}} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{textAlign:'center'}}>
                                    <Typography color={'secondary'}>
                                         Population Percentage
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        {suggestion?.details?.suggestion?.percent_of_population_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.suggestion?.percent_of_population_current}
                                        less={suggestion?.details?.current_selection?.percent_of_population_current}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{textAlign:'center', mb: {xs: 1, sm: 0}}}>
                                    <Typography color={'secondary'}>
                                        Current Employment
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        {suggestion?.details?.suggestion?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.suggestion?.employment_current}
                                        less={suggestion?.details?.current_selection?.employment_current}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{textAlign:'center'}}>
                                    <Typography color={'secondary'}>
                                        Projected Employment
                                    </Typography>
                                    <Typography color={'primary'} sx={{fontWeight:'bold', display: 'block'}}>
                                        {suggestion?.details?.suggestion?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                    <ThumbDirection
                                        greater={suggestion?.details?.suggestion?.employment_projected}
                                        less={suggestion?.details?.current_selection?.employment_projected}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                {canDelete() && (
                    <Grid item xs={1}>
                        <IconButton aria-label="delete" onClick={(event) => handleClickPopper(event, suggestion)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={openPopper}
                            onClose={handleClosePopper}
                            elevation={0}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box>
                                <Link
                                    underline='none'
                                    color='inherit'
                                    sx={{
                                        width:'100px',
                                        display:'block',
                                        p: 1,
                                        border: '1px solid #ccc',
                                        borderTop: '0px',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#FF0000',
                                            color: '#fff',
                                        },
                                    }}
                                    onClick={onDeleteChange}
                                >
                                    Delete Suggestion
                                </Link>
                            </Box>
                        </Popover>
                    </Grid> 
                )}
                <Grid item xs={12}>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                </Grid>
            </Grid>
        </Container>
    )
}