import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { getUserTasksByReferrer } from '../../../../../services/tasksService';
import { TasksList } from '../../../../../components/global/tasks/tasksList';
import { TasksView } from '../../../../../components/global/tasks/tasksView';

export function TasksComponent({user, student, setOpenTasks, setError, setErrorText}) {
    const [tasks, setTasks] = React.useState([]);
    const [selectedTask, setSelectedTask] = React.useState({});
    const [type, setType] = React.useState('parent');
    const [reloadList, setReloadList] = React.useState(false);
    const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false);
    const handleCloseDrawer = () => setOpenTaskDrawer(false);
    const [checked, setChecked] = React.useState([]);

    const drawerStyle = {
        width: '60%',
        '@media (max-width: 800px)': {
            width: '100%'
        }
    }

    React.useEffect(() => {
        getUserTasksByReferrer(localStorage.getItem('token'), student?.id).then(tasks => {
            if (tasks.status == true) {
                setTasks(tasks.data);
            } else {
                setError(true);
                setErrorText(tasks.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
        });
        setReloadList(false);
    }, [setOpenTasks, reloadList]);

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Tasks
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <TasksList
                type={type}
                tasks={tasks}
                setSelectedTask={setSelectedTask}
                attachedUser={user}
                setReloadList={setReloadList}
                setOpenTaskDrawer={setOpenTaskDrawer}
                setError={setError}
                setErrorText={setErrorText}
            />
            <Drawer
                open={openTaskDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <TasksView 
                    type={type}
                    selectedTask={selectedTask}
                    attachedUser={user}
                    attachedRecipient={student}
                    educators={[]}
                    handleCloseDrawer={handleCloseDrawer}
                    setReloadList={setReloadList}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Drawer>
        </>
    )
}