import React, { PureComponent, Suspense } from 'react';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { userAccount, checkType } from '../../../services/userService';
import { MessagingContainer } from '../../../components/global/messaging/messagingContainer';

const StudentMessages = () => {
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openErrorSnackbarText, setOpenErrorSnackbarText] = React.useState('');
  const handleCloseErrorSnackbar = () => setOpenErrorSnackbar(false);
  const [user, setUser] = React.useState(false);
  const [userType, setUserType] = React.useState(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
      setUserType(requestData.data);
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUser(userData.data);
      } else {
        setOpenErrorSnackbar(true);
        setOpenErrorSnackbarText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Suspense fallback={<div>Loading...</div>}>
        {user && userType && (
          <MessagingContainer
              user={user}
              userType={userType}
              setError={setOpenErrorSnackbar} 
              setErrorText={setOpenErrorSnackbarText} 
          />
        )}
      </Suspense>
      <Snackbar
        open={openErrorSnackbar}
        onClose={handleCloseErrorSnackbar}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {openErrorSnackbarText}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentMessages;
