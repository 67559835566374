import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { addTask, updateTask } from '../../../services/tasksService';

export function TasksView({type, selectedTask, attachedUser, attachedRecipient, educators, handleCloseDrawer, setReloadList, setError, setErrorText}) {
    const [canEdit, setCanEdit] = React.useState(false);
    const [canEditStrict, setCanEditStrict] = React.useState(false);
    const [taskTitle, setTaskTitle] = useState(selectedTask?.task);
    const [taskDescription, setTaskDescription] = useState(selectedTask?.description);
    const [taskDueDate, setTaskDueDate] = useState(selectedTask?.due_date);
    const [taskCompleted, setTaskCompleted] = useState(selectedTask?.completed);
    const [selectedEducator, setSelectedEducator] = useState(selectedTask?.educator?.id);
    const [formCompleted, setFormCompleted] = React.useState(false);

    React.useEffect(() => {
        setFormCompleted(false);
        if (taskTitle) {
            setFormCompleted(true);
        }
    });

    React.useEffect(() => {
        if (type === 'student') {
            if (selectedTask?.educator_id === undefined || selectedTask?.educator_id === null || selectedTask?.educator_id === 'null') {
                setCanEdit(true);
            }

            if (selectedTask?.uploader_id === attachedUser?.id) {
                setCanEdit(true);
                setCanEditStrict(true);
            }

            if (selectedTask?.user_id === attachedUser?.id) {
                setCanEdit(true);
            }
        } else if (type === 'self_educator') {
            if (selectedTask?.uploader_id === attachedUser?.id) {
                setCanEdit(true);
                setCanEditStrict(true);
            }

            if (selectedTask?.educator_id === attachedUser?.id) {
                setCanEdit(true);
            }
        } else if (type === 'parent') {
            setCanEdit(false);
            setCanEditStrict(false);
        }
        if (Object.keys(selectedTask).length === 0) {
            setCanEdit(true);
            setCanEditStrict(true);
        }
        if (attachedUser?.active === 0 || selectedTask?.educator?.active === 0) {
            setCanEdit(false);
            setCanEditStrict(false);
        }
    }, [selectedTask]);

    const onTaskTitleChange = (event) => {
        setTaskTitle(event.target.value);
    };

    const onTaskDescriptionChange = (event) => {
        setTaskDescription(event.target.value);
    };

    const onTaskDueDateChange = (date) => {
        setTaskDueDate(date);
    };

    const onTaskCompletedChange = (event) => {
        setTaskCompleted(event.target.value);
    };

    const onEducatorChange = (event) => {
      setSelectedEducator(event.target.value);
    }

    const handleSubmit = () => {
        if (selectedTask?.id) {
            let data = new URLSearchParams({
                'task':taskTitle,
                'due_date':formatDate(taskDueDate),
                'description':taskDescription,
                'completed':taskCompleted,
                'archived':selectedTask?.archived
            });
            updateTask(localStorage.getItem('token'), data, selectedTask?.id).then(task => {
                if (task.status) {
                    setReloadList(task);
                } else {
                    setError(true);
                    setErrorText(task.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error updating the task.');
            });
        } else {
            let recipient = null;
            if (type === 'student') {
                if (selectedEducator === undefined) {
                    recipient = null;
                } else {
                    recipient = selectedEducator;
                }
            } else {
                recipient = attachedRecipient?.id;
            }
            
            let data = new URLSearchParams({
                'task':taskTitle,
                'due_date':formatDate(taskDueDate),
                'description':taskDescription,
                'completed':taskCompleted,
                'archived':selectedTask?.archived,
                'attached_user':recipient
            });
            addTask(localStorage.getItem('token'), data).then(task => {
                if (task.status) {
                    setReloadList(task);
                    handleCloseDrawer();
                } else {
                    setError(true);
                    setErrorText(task.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error adding the task.');
            });
        }
        handleCloseDrawer();
    };
    
    const formatFormDate = (date) => {
        return new Date(date);
    };

    const getTooltipText = (canEdit) => {
        if (attachedUser?.active === 0) {
            return 'You cannot edit this field because this user is inactive.';
        }
        if (selectedTask?.educator?.active === 0) {
            return 'You cannot edit this field because this educator is inactive.';
        }
        if (!canEdit) {
            return 'You do not have permission to edit this field.';
        }
    }

    const formatDate = (date) => {
        if (date === null) {
            return null;
        }

        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        var formattedDate  = new Date(date);
        var toLocale = formattedDate.toLocaleDateString('en-US', options);

        if (toLocale === 'Invalid Date') {
            return null;
        }

        return toLocale;
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{p:2}}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                        {Object.keys(selectedTask).length === 0 ? 'Add A Task' : 'Task Added ' + formatDate(selectedTask?.created_at)}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2 }} />
                </Grid>
                <Grid item xs={12}>
                    <Tooltip 
                        arrow 
                        title={getTooltipText(canEditStrict)}
                        PopperProps={{style:{zIndex:9999}}}
                    >                        
                        <TextField
                            name="task_title"
                            required
                            fullWidth
                            id="task_title"
                            label="Task Title"
                            type="text"
                            disabled={!canEditStrict ? true : false}
                            value={taskTitle}
                            onChange={onTaskTitleChange}
                            multiline
                            rows={1}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip 
                        arrow 
                        title={getTooltipText(canEditStrict)}
                        PopperProps={{style:{zIndex:9999}}}
                    >
                        <TextField
                            name="task_description"
                            fullWidth
                            id="task_description"
                            label="Task Description"
                            type="text"
                            multiline
                            rows={10}
                            disabled={!canEditStrict ? true : false}
                            value={taskDescription}
                            onChange={onTaskDescriptionChange}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Tooltip 
                            arrow 
                            title={getTooltipText(canEdit)}
                            PopperProps={{style:{zIndex:9999}}}
                        >
                            <span style={{display:'block'}}>
                                <DatePicker 
                                    label="Complete By Date"
                                    sx={{
                                        width:'100%',
                                        '& .Mui-disabled': {
                                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                        }
                                    }}
                                    disabled={!canEditStrict ? true : false}
                                    onChange={(newValue) => onTaskDueDateChange(newValue)}
                                    value={taskDueDate ? formatFormDate(taskDueDate) : null}
                                />
                            </span>
                        </Tooltip>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip 
                        arrow 
                        title={getTooltipText(canEdit)}
                        PopperProps={{style:{zIndex:9999}}}
                    >  
                        <FormControl fullWidth>
                            <InputLabel id="task_completed">Task Completed</InputLabel>
                            <Select
                                labelId="task_completed"
                                id="task_completed"
                                label="Task Completed"
                                value={taskCompleted ? 1 : 0}
                                onChange={onTaskCompletedChange}
                                disabled={!canEdit ? true : false}
                                MenuProps={{
                                    style: {zIndex: 9999}
                                }}
                                sx={{
                                    textAlign:'left',
                                    '& .Mui-disabled': {
                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                    }
                                }}
                            >
                                <MenuItem value={1}>Complete</MenuItem>
                                <MenuItem value={0}>Incomplete</MenuItem>
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>
                {educators?.length > 0 && (
                    <>
                        {(Object.keys(selectedTask).length === 0 || selectedTask?.educator?.id) && (
                            <Grid item xs={12}>
                                <Tooltip 
                                    arrow 
                                    title={Object.keys(selectedTask).length !== 0 ? 'You cannot reassign a task to a new educator.' : ''}
                                    PopperProps={{style:{zIndex:9999}}}
                                >  
                                    <FormControl fullWidth>
                                        <InputLabel id="educator">{selectedTask?.educator?.id ? 'Selected Educator' : 'Send To Educator (Optional)'}</InputLabel>
                                        <Select
                                            labelId="educator"
                                            id="educator"
                                            label={selectedTask?.educator?.id ? 'Selected Educator' : 'Send To Educator (Optional)'}
                                            sx={{textAlign:'left'}}
                                            onChange={onEducatorChange}
                                            value={selectedEducator}
                                            MenuProps={{
                                                style: {zIndex: 9999}
                                            }}
                                            disabled={Object.keys(selectedTask).length !== 0 ? true : false}
                                        >
                                            {educators?.map((educator, index) => (
                                                <MenuItem key={index} value={educator?.id}>{educator?.first_name} {educator?.last_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                        )}
                    </>
                )}
                {canEdit && (
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            disableElevation
                            size='large'
                            sx={{width:'100%'}}
                            disabled={formCompleted ? false : true}
                            onClick={handleSubmit}
                        >
                            {Object.keys(selectedTask).length === 0 ? 'Add' : 'Update'} Task
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Comments</Divider>
                    <Typography color='secondary' textAlign='center' component='div'>
                        The ability to add comments are coming soon! For now, please message your {type === 'student' ? 'educator' : 'student'} directly.
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}