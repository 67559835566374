import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getParentLoans, getParentLoanTypes, getParentLoanRepaymentPlans, deleteParentLoan, parentHandleNoLoans } from '../../../services/loansService';
import { getAdvertisements } from '../../../services/advertisementsService';
import { AdvertisementDisplay } from '../../../components/global/advertisements/advertisementDisplay';
import { studentDisclaimer } from '../../../components/global/studentDisclaimer';
import { getSponsoredLoans } from '../../../services/advertisementsService';
import { LoansModal } from '../../../components/global/loansModal';
import { NoLoansModal } from '../../../components/global/noLoansModal';
import { parentAccount, checkType } from '../../../services/userService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Loans = () => {
  const [userType, setUserType] = React.useState(1);
  const [loans, setLoans] = React.useState(null);
  const [openChangeLoanModal, setOpenChangeLoanModal] = React.useState(false);
  const [openNoLoansModal, setOpenNoLoansModal] = React.useState(false);
  const [sponsoredLoans, setSponsoredLoans] = React.useState([]);
  const [advertisements, setAdvertisements] = React.useState([]);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [loanTypes, setLoanTypes] = React.useState([]);
  const [loanRepaymentPlans, setLoanRepaymentPlans] = React.useState([]);
  const [modalLoanId, setModalLoanId] = React.useState('');
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const handleOpenNoLoansModal = () => setOpenNoLoansModal(true);
  const handleCloseNoLoansModal = () => setOpenNoLoansModal(false);
  const [investment, setInvestment] = React.useState(null);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleOpenChangeLoanModal = (e, id) => {
    setModalLoanId(id);
    setOpenChangeLoanModal(true);
  }

  const handleDeleteLoan = (e, id) => {
    deleteParentLoan(localStorage.getItem('token'), id).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }

  const handleCloseChangeLoanModal = () => {
    setOpenChangeLoanModal(false);
    setModalLoanId('');
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
      setUserType(requestData.data?.type);
    });
    parentAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/parents/dashboard';
        }
        setInvestment(userData.data.investments[0]);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getParentLoans(localStorage.getItem('token')).then(loansData => {
      if (loansData.status == true) {
        setLoans(loansData.data);
        getParentLoanTypes(localStorage.getItem('token')).then(loanTypesData => {
          if (loanTypesData.status == true) {
            setLoanTypes(loanTypesData.data);
          }
        });
        getParentLoanRepaymentPlans(localStorage.getItem('token')).then(loanRepaymentPlansData => {
          if (loanRepaymentPlansData.status == true) {
            setLoanRepaymentPlans(loanRepaymentPlansData.data);
          }
        });
        getSponsoredLoans(localStorage.getItem('token')).then(sponsoredLoansData => {
          if (sponsoredLoansData.status == true) {
            setSponsoredLoans(sponsoredLoansData.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(sponsoredLoansData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getAdvertisements(localStorage.getItem('token'), 'advertisement').then(advertisements => {
          if (advertisements.status == true) {
            setAdvertisements(advertisements.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(advertisements.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const clickHandleNoLoans = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'no_loans':0
    });

    parentHandleNoLoans(localStorage.getItem('token'), investment.id, data).then(update => {
      parentAccount(localStorage.getItem('token')).then(userData => {
        if (userData.status == true) {
          setInvestment(userData.data.investments[0]);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
      handleOpenChangeLoanModal(event, '');
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container spacing={3}>
        <Grid item sm={12} md={(sponsoredLoans?.length > 0 || advertisements?.length) ? 9 : 12}>
          <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mb: 2 }}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Loans
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              {investment?.no_loans === 0 &&
                <Button
                  variant='contained'
                  disableElevation
                  size='large'
                  onClick={e => handleOpenChangeLoanModal(e, '')}
                >
                  Add Loan
                </Button>
              }
            </Grid>
          </Grid>
          <Paper sx={{ p: 2 }}>
            {investment?.no_loans === 1 ? (
              <>
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                  You have set your account to have no loans meaning it is assumed your schooling is paid in full. If this is incorrect you can add a loan below.<br />
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    size='large'
                    onClick={clickHandleNoLoans}
                  >
                    Add A Loan
                  </Button>
                </Typography>
              </>
            ) : (
              <>
                {loans?.length > 0 ? (
                  <>
                    {loans?.map((loan, index) => (
                      <Container key={index} maxWidth={false} disableGutters={true}>
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item xs={10}>
                            <Typography color='secondary'>Loan Type: <b>{loanTypes.find(type => type.id === loan.type)?.name}</b></Typography>
                            <Typography color='secondary'>Repayment Plan: <b>{loanRepaymentPlans.find(plan => plan.id === loan.repayment_plan)?.name}</b></Typography>
                            <Typography variant='h5' color="secondary" component='div' sx={{mb: 1, mt: 1}}>
                              <b>${loan.balance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b> at <b>{loan.interest_rate}% Interest</b> Over <b>{loan.loan_term} Years</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={2} align='right'>
                            <IconButton aria-label="edit" color="secondary" onClick={e => handleOpenChangeLoanModal(e, loan.id)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" color="secondary" onClick={e => handleDeleteLoan(e, loan.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                        {index !== loans.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                      </Container>
                    ))}
                  </>
                ) : (
                  <>
                    <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                      You have no loans at this time. We will assume you are taking out the full amount for the school or institution in a single loan until you add some. Adding a loan will affect your investment projections.
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Button
                        variant='text'
                        color='primary'
                        size='large'
                        onClick={handleOpenNoLoansModal}
                      >
                        <b>If you have no loans to repay and your tuition will be paid in full click here.</b>
                      </Button>
                    </Typography>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
        {(sponsoredLoans?.length > 0 || advertisements?.length > 0) &&
          <Grid item sm={12} md={3}>
            {advertisements?.length > 0 ? (
              <>
                <Tooltip enterTouchDelay={0} title='These are sponsored advertisements based on your interests.' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 3, textAlign: 'center', justifyContent: 'center' }}>
                    Sponsored <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                {advertisements?.map((advertisement, index) => (
                    <AdvertisementDisplay 
                        advertisement={advertisement}
                        selectedCategory={advertisement?.category_id}
                        type={'live'}
                        typeTitle={null}
                        setError={openErrorModal}
                        setErrorText={openErrorModalText}
                    />
                ))}
              </>
            ) : (
              <>
                <Tooltip enterTouchDelay={0} title='Based on your information these loans might help you.' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 4, textAlign: 'center', justifyContent: 'center' }}>
                    Loans To Consider <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
              </>
            )}
          </Grid>
        }
      </Grid>
      {studentDisclaimer}
      <Modal
        open={openChangeLoanModal}
        onClose={handleCloseChangeLoanModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {<LoansModal loan={{id:modalLoanId}} userType={userType} />}
        </Box>
      </Modal>
      <Modal
        open={openNoLoansModal}
        onClose={handleCloseNoLoansModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {<NoLoansModal userType={userType} />}
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Loans;
