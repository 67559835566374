import React, { Suspense } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { getPromoCode, calculateTax, getSubscription, removeSubscription, createSubscription, updateDefaultCardBasedOnSubscription } from '../../../../../../services/parentBillingService';
import { studentPaymentsUrl } from '../../../../../../constants/globals';

export function SubscriptionAdd({product, setOpenTermsModal, setError, setErrorText}) {
    const [stripeComplete, setStripeComplete] = React.useState(false);
    const [paymentComplete, setPaymentComplete] = React.useState(false);
    const [addressComplete, setAddressComplete] = React.useState(false);
    const [completedButtonText, setCompletedButtonText] = React.useState('Submit');
    const [promoCode, setPromoCode] = React.useState(null);
    const [updatedPrice, setUpdatedPrice] = React.useState(product?.pricing[0]?.total_price || 0);
    const [tax, setTax] = React.useState(0);

    const stripe = useStripe();
    const elements = useElements();

    React.useEffect(() => {
        setStripeComplete(false);
        if (paymentComplete && addressComplete) {
            setStripeComplete(true);
        }
    });

    const paymentElementChange = (element) => {
        setPaymentComplete(false);
        if (!element.empty && element.complete) {
            setPaymentComplete(true);
        }
    }

    const fetchUpdatedPrice = async (address) => {
        const addressData = address.address;

        let data = new URLSearchParams({
            price: product?.pricing[0]?.total_price,
            "address[line1]": addressData?.line1 || "",
            "address[line2]": addressData?.line2 || "",
            "address[city]": addressData?.city || "",
            "address[state]": addressData?.state || "",
            "address[postal_code]": addressData?.postal_code || "",
            "address[country]": addressData?.country || "",
        });

        const taxData = await calculateTax(localStorage.getItem('token'), data);
        if (taxData?.status) {
            setTax(taxData?.data?.taxAmount);
            setUpdatedPrice(taxData?.data?.totalPrice);
        }
    };

    const addressElementChange = (element) => {
        setAddressComplete(element.complete);

        if (element.complete) {
            const { value } = element;
            fetchUpdatedPrice(value);
        }
    }

    const onPromoCodeChange = (event, values) => {
      setPromoCode(null);
      if (event.target.value) {
        setPromoCode(event.target.value);
      }
    }

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setStripeComplete(false);
        setAddressComplete(false);
        setPaymentComplete(false);
        setCompletedButtonText('Working');

        if (promoCode) {
            let promoData = new URLSearchParams({
                'promocode':promoCode
            });
            await getPromoCode(localStorage.getItem('token'), promoData).then(promocode => {
                if (promocode.status === false) {
                    setError(true);
                    setErrorText('Please select a valid promo code.');
                    setStripeComplete(true);
                    setAddressComplete(true);
                    setPaymentComplete(true);
                    setCompletedButtonText('Submit');
                } else {
                    checkOldSubscriptions(promocode.data.id);
                }
            }).catch((error) => {
                setError(true);
                setErrorText('Please select a valid promo code.');
            });
        } else {
            checkOldSubscriptions(null);
        }
    };

    const checkOldSubscriptions = async (promocode) => {
        await getSubscription(localStorage.getItem('token')).then(subscription => {
            if (
                subscription.status == true && Object.keys(subscription.data).length !== 0 && (subscription.data.status != 'incomplete' || subscription.data.items.data[0].price.id !== product?.pricing[0]?.id)
            ) {
                removeSubscription(localStorage.getItem('token')).then(subscription => {
                    submitNewSubscription(promocode);
                });
            } else if (
                subscription.status == true && Object.keys(subscription.data).length !== 0 && subscription.data.status == 'incomplete' && subscription.data.items.data[0].price.id === product?.pricing[0]?.id
            ) {
                if(subscription.data?.latest_invoice?.payment_intent === null) {
                    window.location.reload();
                } else {
                    submitPayment(subscription.data);
                }
            } else {
                submitNewSubscription(promocode);
            }
        });
    }

    const submitNewSubscription = async (promocode) => {
        let subscriptionData = new URLSearchParams({
            'price':product?.pricing[0]?.id,
            'promocode':promocode
        });

        await createSubscription(localStorage.getItem('token'), subscriptionData).then(subscription => {
            if (subscription.status == true) {
                if(subscription.data?.latest_invoice?.payment_intent === null) {
                    window.location.reload();
                } else {
                    submitPayment(subscription.data);
                }
            } else {
                setError(true);
                setErrorText(subscription.errors);
            }
        });
    }

    const submitPayment = async (subscription) => {
        const {error: submitError} = await elements.submit();
        if (submitError) {
            setError(true);
            setErrorText('Error submitting payment, please try again.');
            return;
        }
                
        const paymentResult = await stripe.confirmPayment({
            elements,
            clientSecret: subscription?.latest_invoice?.payment_intent?.client_secret,
            confirmParams: {
                return_url: studentPaymentsUrl,
            },
            redirect: 'if_required'
        });
        
        if (paymentResult?.error) {
            setError(true);
            setErrorText(paymentResult.error.message);
        } else {
            if (paymentResult?.paymentIntent.status === "succeeded") {
                getSubscription(localStorage.getItem('token')).then(subscription => {
                    if (subscription?.data?.default_payment_method !== null || subscription?.data?.latest_invoice?.payment_intent?.payment_method !== null) {
                        let cardData = new URLSearchParams({
                            'subscription':subscription?.data.id
                        });
                        updateDefaultCardBasedOnSubscription(localStorage.getItem('token'), cardData).then(card => {
                            if (card.status !== true) {
                                setError(true);
                                setErrorText(card.errors);
                            } else {
                                window.location.reload();
                            }
                        });
                    } else {
                        window.location.reload();
                    }
                });
            }
        }

        setStripeComplete(true);
        setAddressComplete(true);
        setPaymentComplete(true);
        setCompletedButtonText('Submit');
    }

    return (
        <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
                <Paper sx={{ p: 2 }}>
                    <Typography color='secondary' variant='h4' component='div'>
                        Subscribe For Full Access
                    </Typography>
                    <Typography color='secondary' variant='h6' component='div'>
                        If you have an educator, your subscription will become free. 
                        For more information see <Link href="#" onClick={() => setOpenTermsModal(true)}>our terms and conditions</Link>.
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Purchase Information</Divider>
                    <Grid container alignItems="center">
                        <Grid item xs={9}>
                            <strong>Monthly Subscription</strong>
                            <p>Grants full access to Education Advisor.</p>
                        </Grid>
                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                            {product?.pricing[0]?.unit_amount ? (
                                <>
                                    ${product?.pricing[0]?.unit_amount?.toString()?.substring(0, product?.pricing[0]?.unit_amount?.toString()?.length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00
                                </>
                            ) : (
                                <>
                                    $0.00
                                </>
                            )}
                            &nbsp;/&nbsp;{product?.pricing[0]?.recurring.interval} per student
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Grid container>
                        <Grid item xs={9}>
                            <strong>Total Student Seats Needed:</strong>
                        </Grid>
                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                            {product?.pricing[0]?.total_quantity ? (
                                <>
                                    {product?.pricing[0]?.total_quantity}
                                </>
                            ) : (
                                <>
                                    0
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Total Due Today</Divider>
                    <Grid container>
                        <Grid item xs={9}>
                            Subtotal:
                        </Grid>
                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                            {product?.pricing[0]?.total_price ? (
                                <>
                                    ${product?.pricing[0]?.total_price?.toString()?.substring(0, product?.pricing[0]?.total_price?.toString()?.length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00
                                </>
                            ) : (
                                <>
                                    $0.00
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Grid container>
                        <Grid item xs={9}>
                            Taxes:
                        </Grid>
                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                            <Tooltip enterTouchDelay={0} title='We are working with our payment processor for accurate tax calculations. Currently, we can only calculate taxes after checkout. If you have any further questions please chat with our support team below!' placement='top' arrow>
                                Calculated after checkout. <InfoIcon sx={{fontSize: 'small', ml: 1, mb: '-2px'}} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Grid container sx={{mb:2}}>
                        <Grid item xs={9}>
                            <strong>Grand Total:</strong>
                        </Grid>
                        <Grid item xs={3} sx={{textAlign: 'right'}}>
                            <strong>
                                {updatedPrice ? (
                                    <>
                                        ${updatedPrice?.toString()?.substring(0, updatedPrice?.toString()?.length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00
                                    </>
                                ) : (
                                    <>
                                        $0.00
                                    </>
                                )}
                            </strong>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <FormControl style={{minWidth: '100%'}}>
                    <Divider variant="fullWidth" sx={{ mb: 2 }}>Billing Information</Divider>
                    <AddressElement 
                        options={{mode: 'billing'}}
                        onChange={addressElementChange}
                    />
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Payment Information</Divider>
                    <PaymentElement
                        onChange={paymentElementChange}
                    />
                    <Typography color={'primary'} component="p" sx={{fontSize:'12px', fontWeight:'bold', mt:1}}>
                        This card will now be the default card for your subscription.
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Promo Code</Divider>
                    <Grid container>
                        <TextField
                            fullWidth
                            id="promocode"
                            name="promocode"
                            label="Enter A Promo Code"
                            onChange={onPromoCodeChange}
                            sx={{backgroundColor:'#fff'}}
                        />

                        <Typography color={'secondary'} component="p" sx={{fontSize:'12px', mt:2}}>
                            Promo code will be applied to the total after checkout.
                        </Typography>
                    </Grid>
                    <Button 
                        fullWidth
                        disableElevation
                        variant='contained'
                        disabled={stripeComplete ? false : true}
                        onClick={handleSubmit}
                        sx={{mt:2}}
                    >
                        {completedButtonText}
                    </Button>
                    <Typography color={'secondary'} component="p" sx={{fontSize:'12px', textAlign:'center', mt:2}}>
                        Once you click register you will be charged immediately and a recurring amount 
                        will be charged to your account monthly. You can cancel your subscription at any time.
                    </Typography>
                </FormControl>
            </Grid>
        </Grid>
    );
};