import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Messages } from '../../../../../components/global/messaging/messages';
import { getMessages } from '../../../../../services/messagingService';

export function MessagesComponent({user, student, setOpenMessages, setError, setErrorText}) {
    const [selectedContact, setSelectedContact] = React.useState([]);
    const [selectedMessages, setSelectedMessages] = React.useState([]);

    React.useEffect(() => {
        setSelectedContact(student);
        getMessages(localStorage.getItem('token'), student?.id).then(messages => {
            if (messages.status == true) {
                setSelectedMessages(messages.data);
            } else {
                setError(true);
                setErrorText(messages.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
        });
    }, [setOpenMessages]);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
              Messages
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Box
                sx={{
                    height:'100%', 
                    position: 'relative',
                    maxHeight: 'calc(60vh - 64px)',
                    minHeight: 'calc(60vh - 64px)',
                }}
            >
                <Messages
                    setError={setError} 
                    setErrorText={setErrorText}
                    user={user}
                    employee={user}
                    messages={selectedMessages}
                    selectedContact={selectedContact}
                    setSelectedMessages={setSelectedMessages}
                    setMessagesOpen={true}
                />
            </Box>
        </>
    )
}