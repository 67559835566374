import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import GoogleIcon from '@mui/icons-material/Google';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import './signup.scss';
import { getOccupationsList, getIndustriesList } from '../../../services/employmentService';
import { getInstitutionsList } from '../../../services/educationService';
import { registerStudent } from '../../../services/userService';
import { checkType } from '../../../services/userService';
import { CareerDecider } from './careerDecider';
import { EducationDecider } from './educationDecider';

const steps = ['Career', 'Education', 'Your Info'];

let signupData = {
  'first_name':null,
  'last_name':null,
  'email':null,
  'password':null,
  'newsletter_opt_in':true,
  'occupation':null,
  'industry':null,
  'institution':null,
  'income':null
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const finderStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
  borderRadius: '10px'
};

const SignUp = () => {
  if (localStorage.getItem('token')) {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [occupationCompleted, setOccupationCompleted] = React.useState(false);
  const [educationCompleted, setEducationCompleted] = React.useState(false);
  const [identificationCompleted, setIdentificationCompleted] = React.useState(false);
  const [occupations, setOccupations] = React.useState([]);
  const [selectedOccupationCode, setSelectedOccupationCode] = React.useState(null);
  const [showIndustries, setShowIndustries] = React.useState(false);
  const [industries, setIndustries] = React.useState([]);
  const [institutions, setInstitutions] = React.useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [openCareerFind, setOpenCareerFind] = React.useState(false);
  const [openEducationFind, setOpenEducationFind] = React.useState(false);
  const [occupationDeciderData, setOccupationDeciderData] = React.useState({});
  const [educationDeciderData, setEducationDeciderData] = React.useState({});
  const [chosenState, setChosenState] = React.useState(null);
  const [isUsingOccupationDeciderData, setIsUsingOccupationDeciderData] = React.useState(0);
  const [isUsingEducationDeciderData, setIsUsingEducationDeciderData] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalText, setOpenModalText] = React.useState('');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new FormData();
    for ( var key in signupData) {
      data.append(key, signupData[key]);
    }
    data.append('occupation_quiz_data', JSON.stringify(occupationDeciderData));
    data.append('state_of_residence', chosenState);
    data.append('education_quiz_data', JSON.stringify(educationDeciderData));
    registerStudent(data).then(register => {
      if (register.status == true) {
        localStorage.setItem('token', register.data.token);
        window.location.href = '/students/dashboard';
      } else {
        setOpenModal(true);
        setOpenModalText(register.message);
      }
    });
  };

  const handleCareerFindModal = (state) => () => {
    setOpenEducationFind(false);
    setOpenCareerFind(state);
    setIsUsingOccupationDeciderData(false);
    if (state === true) {
      setIsUsingOccupationDeciderData(true);
    }
  };

  const handleEducationFindModal = (state) => () => {
    setOpenCareerFind(false);
    setOpenEducationFind(state);
    setIsUsingEducationDeciderData(false);
    if (state === true) {
      setIsUsingEducationDeciderData(true);
    }
  };

  React.useEffect(() => {
    getOccupationsList().then(getOccupationsList => {
      setOccupations(getOccupationsList.data);
    });
  }, []);

  React.useEffect(() => {
    getInstitutionsList('*').then(getInstitutionsList => {
      setInstitutions(getInstitutionsList.data);
    });
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    setOpenEducationFind(false);
    setOpenCareerFind(false);
    if (newActiveStep === 0 && isUsingOccupationDeciderData === true) {
      handleCareerFindModal(true)();
    }
    if (newActiveStep === 1 && isUsingEducationDeciderData === true) {
      handleEducationFindModal(true)();
    }
  };

  const handleBack = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    newCompleted[activeStep - 1] = false;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setOpenEducationFind(false);
    setOpenCareerFind(false);
    if ((activeStep - 1) === 0 && isUsingOccupationDeciderData === true) {
      handleCareerFindModal(true)();
    }
    if ((activeStep - 1) === 1 && isUsingEducationDeciderData === true) {
      handleEducationFindModal(true)();
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const onOccupationChange = (event, values) => {
    setShowIndustries(false);
    setIndustries([]);
    setOccupationCompleted(false);
    signupData['occupation'] = null;
    if (values) {
      getIndustriesList(values.occupation_code).then(getIndustriesList => {
        setIndustries(getIndustriesList.data);
      });
      setOccupationCompleted(true);
      setShowIndustries(true);
      signupData['occupation'] = values.occupation_code;
    }
  }

  const onIndustryChange = (event, values) => {
    signupData['industry'] = null;
    if (values) {
      signupData['industry'] = values.industry_code;
    }
  }

  const onInstitutionChange = (event, values) => {
    setEducationCompleted(false);
    signupData['institution'] = null;
    if (values) {
      signupData['institution'] = values.unitId;
      setEducationCompleted(true);
    }
  }
  const updateInstitutions = (event, values) => {
    var value = '*';
    if (event.target.value) {
      value = event.target.value;
    }
    getInstitutionsList(value).then(getInstitutionsList => {
      setInstitutions(getInstitutionsList.data);
    });
  }

  const onIncomeChange = (event, values) => {
    signupData['income'] = null;
    if (event.target.value) {
      signupData['income'] = event.target.value;
    }
  }

  const onNewsletterChange = (event, values) => {
    if (values) {
      signupData['newsletter_opt_in'] = values;
    } else {
        signupData['newsletter_opt_in'] = false;
    }
  }

  const onIdentificationChange = (event, values) => {
    signupData[event.target.name] = null;
    setIdentificationCompleted(false);
    if (event.target.value) {
      signupData[event.target.name] = event.target.value;
    }
    if (
      signupData['first_name'] &&
      signupData['last_name'] &&
      validateEmail(signupData['email']) &&
      validatePassword(signupData['password'])
    ) {
      setIdentificationCompleted(true);
    }
  }

  const validateEmail = (email) => {
    return String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const validatePassword = (password) => {
    return /[A-Z]/       .test(password) &&
           /[a-z]/       .test(password) &&
           /[0-9]/       .test(password) &&
           /[^A-Za-z0-9]/.test(password) &&
           password.length > 4;
  }

  const occupation = (step) => {
    return (
      <Box component="div" sx={{ display: (step == 0) ? 'block' : 'none' }}>
        <Typography variant="h4" align="center">
          Step 1: Career Path
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="occupation"
              options={occupations}
              name="occupation"
              fullWidth
              onChange={onOccupationChange}
              getOptionLabel={occupations => occupations.occupation_title}
              renderInput={(params) => <TextField {...params} required label="Desired Occupation" />}
            />
          </Grid>
          <Grid
            item xs={12}
            style={showIndustries ? {} : { display: 'none' }}
          >
            <Autocomplete
              disablePortal
              id="industry"
              options={industries}
              name="industry"
              fullWidth
              onChange={onIndustryChange}
              getOptionLabel={industries => industries.industry_title}
              renderInput={(params) => <TextField {...params} label="Desired Industry (Optional)" />}
            />
          </Grid>
          <Grid item xs={12} sx={{textAlign:'center'}}>
            <Divider sx={{mb:3, mt:2}}>OR</Divider>
            <Link variant="body2" sx={{cursor:'pointer', fontSize:'18px', fontWeight:'bold'}} onClick={handleCareerFindModal(true)}>
              Need help choosing a career?
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              disableElevation
              onClick={handleComplete}
              disabled={occupationCompleted ? false : true}
            >
              Next Step
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/login" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const education = (step) => {
    return (
      <Box component="div" sx={{ display: (step == 1) ? 'block' : 'none' }}>
        <Typography variant="h4" align="center">
          Step 2: Education
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="institute"
                options={institutions}
                name="institute"
                fullWidth
                onChange={onInstitutionChange}
                getOptionLabel={institutions => institutions.name}
                renderInput={(params) => <TextField {...params} onChange={updateInstitutions} required label="Desired Institution" />}
              />
          </Grid>
          <Grid item xs={12}>
              <TextField
                name="income"
                fullWidth
                id="income"
                label="Approximate Household Income (Optional)"
                autoFocus
                type="number"
                onChange={onIncomeChange}
              />
          </Grid>
          <Grid item xs={12} sx={{textAlign:'center'}}>
            <Divider sx={{mb:3, mt:2}}>OR</Divider>
            <Link variant="body2" sx={{cursor:'pointer', fontSize:'18px', fontWeight:'bold'}} onClick={handleEducationFindModal(true)}>
              Need help finding the perfect institution?
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Previous Step
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disableElevation
              onClick={handleComplete}
              disabled={educationCompleted ? false : true}
            >
              Next Step
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/login" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const identification = (step) => {
    return (
      <Box component="div" sx={{ display: (step == 2) ? 'block' : 'none' }}>
        <Typography variant="h4" align="center">
          Step 3: Your Info
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="first_name"
              required
              fullWidth
              id="first_name"
              label="First Name"
              autoFocus
              onChange={onIdentificationChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="family-name"
              onChange={onIdentificationChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={onIdentificationChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip enterTouchDelay={0} title="Must include at least one uppercase character, number and special character" arrow>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                autoComplete="new-password"
                onChange={onIdentificationChange}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" defaultChecked onChange={onNewsletterChange} color="primary" name="newsletter_opt_in" />}
              label="I want to receive email updates from Education Advisor."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Previous Step
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disableElevation
              disabled={identificationCompleted ? false : true}
            >
              Finish
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/login" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Container component="div" className="wrapper wrapper-small signup">
      <Stepper activeStep={activeStep} nonLinear alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
        {occupation(activeStep)}
        {education(activeStep)}
        {identification(activeStep)}
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openModalText}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openCareerFind}
        onClose={handleCareerFindModal(false)}
      >
        <Box sx={finderStyle}>
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCareerFindModal(false)}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <CareerDecider
            handleComplete={handleComplete}
            handleCareerFindModal={handleCareerFindModal}
            occupationDeciderData={occupationDeciderData}
            setOccupationDeciderData={setOccupationDeciderData}
            setOpenModal={setOpenModal}
            setOpenModalText={setOpenModalText}
          />
        </Box>
      </Modal>
      <Modal
        open={openEducationFind}
        onClose={handleEducationFindModal(false)}
      >
        <Box sx={finderStyle}>
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleEducationFindModal(false)}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <EducationDecider
            handleComplete={handleComplete}
            handleEducationFindModal={handleEducationFindModal}
            educationDeciderData={educationDeciderData}
            setEducationDeciderData={setEducationDeciderData}
            chosenState={chosenState}
            setChosenState={setChosenState}
            setOpenModal={setOpenModal}
            setOpenModalText={setOpenModalText}
          />
        </Box>
      </Modal>
    </Container>
  );
};

export default SignUp;
