import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuIcon from '@mui/icons-material/Menu';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import MailIcon from '@mui/icons-material/Mail';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HandshakeIcon from '@mui/icons-material/Handshake';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import StarIcon from '@mui/icons-material/Star';
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Chip from '@mui/material/Chip';
import { OverviewComponent } from './studentViewComponents/overviewComponent';
import { HistoryComponent } from './studentViewComponents/historyComponent';
import { EducatorsComponent } from './studentViewComponents/educatorsComponent';
import { TasksComponent } from './studentViewComponents/tasksComponent';
import { MessagesComponent } from './studentViewComponents/messagesComponent';
import { PortfolioComponent } from './studentViewComponents/portfolioComponent';
import { ResumeComponent } from './studentViewComponents/resumeComponent';
import { DocumentsComponent } from './studentViewComponents/documentsComponent';
import { SuggestionsComponent } from './studentViewComponents/suggestionsComponent';
import { LoansComponent } from './studentViewComponents/loansComponent';
import { FavoritesComponent } from './studentViewComponents/favoritesComponent';
import { ScholarshipsComponent } from './studentViewComponents/scholarshipsComponent';
import { activateStudents, deactivateStudents } from '../../../../services/parentActionsService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function StudentView({student, parent, setViewStudent}) {
    const [statusActivation, setStatusActivation] = React.useState(1);
    const [statusEvent, setStatusEvent] = React.useState(0);
    const [openStatusModal, setOpenStatusModal] = React.useState(false);
    const handleCloseStatusModal = () => setOpenStatusModal(false);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
        setOpenErrorModalText(null);
    }
    const [drawerState, setDrawerState] = React.useState(false);
    const [openOverview, setOpenOverview] = React.useState(true);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [openEducators, setOpenEducators] = React.useState(false);
    const [openTasks, setOpenTasks] = React.useState(false);
    const [openMessages, setOpenMessages] = React.useState(false);
    const [openPortfolio, setOpenPortfolio] = React.useState(false);
    const [openFavorites, setOpenFavorites] = React.useState(false);
    const [openResume, setOpenResume] = React.useState(false);
    const [openDocuments, setOpenDocuments] = React.useState(false);
    const [openSuggestions, setOpenSuggestions] = React.useState(false);
    const [openLoans, setOpenLoans] = React.useState(false);
    const [openScholarships, setOpenScholarships] = React.useState(false);

    const navigationStyle = {
        '@media (max-width: 899px)': {
            display: 'block',
            float: 'right'
        },
        'display': 'none'
    }

    const sidebarStyle = {
        '@media (max-width: 899px)': {
            display: 'none'
        },
        borderLeft: '1px solid #e0e0e0',
    }

    React.useEffect(() => {
        setStatusActivation(student?.active);
    }, []);

    const toggleDrawer = (direction) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setDrawerState(direction);
    };

    const handleNavigationChange = (page) => {
        setOpenOverview(false);
        setOpenHistory(false);
        setOpenEducators(false);
        setOpenMessages(false);
        setOpenPortfolio(false);
        setOpenFavorites(false);
        setOpenResume(false);
        setOpenTasks(false);
        setOpenDocuments(false);
        setOpenSuggestions(false);
        setOpenLoans(false);
        setOpenScholarships(false);

        if (page === 'overview') {
            setOpenOverview(true);
        }

        if (page === 'history') {
            setOpenHistory(true);
        }

        if (page === 'educators') {
            setOpenEducators(true);
        }

        if (page === 'favorites') {
            setOpenFavorites(true);
        }

        if (page === 'messages') {
            setOpenMessages(true);
        }

        if (page === 'portfolio') {
            setOpenPortfolio(true);
        }

        if (page === 'resume') {
            setOpenResume(true);
        }

        if (page === 'tasks') {
            setOpenTasks(true);
        }

        if (page === 'documents') {
            setOpenDocuments(true);
        }

        if (page === 'suggestions') {
            setOpenSuggestions(true);
        }

        if (page === 'loans') {
            setOpenLoans(true);
        }

        if (page === 'scholarships') {
            setOpenScholarships(true);
        }
    };

    const handleStatusActivation = (event) => {
        let data = new URLSearchParams({
            'id' : student?.id
        });
        if (statusEvent === 1) {
            activateStudents(localStorage.getItem('token'), data).then(update => {
                if (update?.status == true) {
                    setStatusActivation(1);
                    setOpenErrorModal(false);
                    setOpenErrorModalText('');
                    handleCloseStatusModal();
                } else {
                    setOpenErrorModal(true);
                    setOpenErrorModalText(update.message);
                }
            });
        } else {
            deactivateStudents(localStorage.getItem('token'), data).then(update => {
                if (update?.status == true) {
                    setStatusActivation(0);
                    setOpenErrorModal(false);
                    setOpenErrorModalText('');
                    handleCloseStatusModal();
                } else {
                    setOpenErrorModal(true);
                    setOpenErrorModalText(update.message);
                }
            });
        }
    };

    const handleStatusChange = (event, values) => {
        setStatusEvent(event.target.value);
        setOpenStatusModal(true);
    }

    const navigationList = (
        <Box
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List sx={{pt:0, pb:0}}>
            <ListItemButton
                selected={openOverview}
                onClick={() => handleNavigationChange('overview')}
            >
                <ListItemIcon>
                    <LineAxisIcon />
                </ListItemIcon>
                <ListItemText primary='Overview' />
            </ListItemButton>
            <ListItemButton
                selected={openHistory}
                onClick={() => handleNavigationChange('history')}
            >
                <ListItemIcon>
                    <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary='Activity' />
            </ListItemButton>
            <ListItemButton
                selected={openEducators}
                onClick={() => handleNavigationChange('educators')}
            >
                <ListItemIcon>
                    <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary='Educators' />
            </ListItemButton>
            <ListItemButton
                selected={openLoans}
                onClick={() => handleNavigationChange('loans')}
            >
                <ListItemIcon>
                    <HandshakeIcon />
                </ListItemIcon>
                <ListItemText primary='Loans' />
            </ListItemButton>
            <ListItemButton
                selected={openScholarships}
                onClick={() => handleNavigationChange('scholarships')}
            >
                <ListItemIcon>
                    <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary='Scholarships' />
            </ListItemButton>
            <ListItemButton
                selected={openMessages}
                onClick={() => handleNavigationChange('messages')}
            >
                <ListItemIcon>
                    <MailIcon />
                </ListItemIcon>
                <ListItemText primary='Messages' />
            </ListItemButton>
            <ListItemButton
                selected={openFavorites}
                onClick={() => handleNavigationChange('favorites')}
            >
                <ListItemIcon>
                    <StarIcon />
                </ListItemIcon>
                <ListItemText primary='Favorites' />
            </ListItemButton>
            <ListItemButton
                selected={openPortfolio}
                onClick={() => handleNavigationChange('portfolio')}
            >
                <ListItemIcon>
                    <FolderCopyIcon />
                </ListItemIcon>
                <ListItemText primary='Portfolio' />
            </ListItemButton>
            <ListItemButton
                selected={openResume}
                onClick={() => handleNavigationChange('resume')}
            >
                <ListItemIcon>
                    <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary='Resume' />
            </ListItemButton>
            <ListItemButton
                selected={openTasks}
                onClick={() => handleNavigationChange('tasks')}
            >
                <ListItemIcon>
                    <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary='Tasks' />
            </ListItemButton>
            <ListItemButton
                selected={openDocuments}
                onClick={() => handleNavigationChange('documents')}
            >
                <ListItemIcon>
                    <FilePresentIcon />
                </ListItemIcon>
                <ListItemText primary='Documents' />
            </ListItemButton>
            <ListItemButton
                selected={openSuggestions}
                onClick={() => handleNavigationChange('suggestions')}
            >
                <ListItemIcon>
                    <LightbulbIcon />
                </ListItemIcon>
                <ListItemText primary='Suggestions' />
                <Chip 
                    label="BETA"
                    color='primary'
                    size='small'
                    sx={{
                        color: 'white',
                    }}
                />
            </ListItemButton>
          </List>
        </Box>
    );

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Link onClick={() => setViewStudent(false)} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                        <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back To All Students
                    </Link>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Paper sx={{padding:2}}>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <Typography color='secondary' variant='h4' component='div'>
                        {student?.first_name} {student?.last_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} textAlign={'right'}>
                    <FormControl size='small'>
                        <Select
                            labelId="activation"
                            id="activation"
                            onChange={handleStatusChange}
                            value={statusActivation}
                            sx={{ 
                                color: '#777777', 
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': { 
                                    border: 0 
                                },
                                '.MuiSelect-icon': { 
                                    visibility: 'hidden'
                                },
                                '&:hover': {
                                    backgroundColor: '#f1f1f1',
                                    '.MuiSelect-icon': { 
                                        visibility: 'visible'
                                    }
                                }
                            }}
                        >
                            <MenuItem value={1}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon sx={{minWidth:'20px'}}>
                                        <RadioButtonUncheckedIcon 
                                            sx={{
                                                color: 'green',
                                                fontSize: '12px'
                                            }} 
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Active" />
                                </div>
                            </MenuItem>
                            <MenuItem value={0}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon sx={{minWidth:'20px'}}>
                                        <RadioButtonUncheckedIcon 
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px'
                                            }} 
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Inactive" />
                                </div>
                            </MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container spacing={0}>
                    <Grid 
                        item 
                        xs={12}
                        sm={12}
                        md={8}
                    >
                        <IconButton
                            size="small"
                            sx={navigationStyle}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon sx={{ fontSize: '30px'}} />
                        </IconButton>
                        {openOverview && (
                            <OverviewComponent
                                student={student}
                                setOpenOverview={setOpenOverview}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openHistory && (
                            <HistoryComponent
                                student={student}
                            />
                        )}
                        {openEducators && (
                            <EducatorsComponent
                                user={parent}
                                student={student}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openLoans && (
                            <LoansComponent
                                student={student}
                                setOpenLoans={setOpenLoans}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openScholarships && (
                            <ScholarshipsComponent
                                student={student}
                                setOpenScholarships={setOpenScholarships}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openMessages && (
                            <MessagesComponent
                                user={parent}
                                student={student}
                                setOpenMessages={setOpenMessages}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openFavorites && (
                            <FavoritesComponent
                                user={parent}
                                student={student}
                                setOpenFavorites={setOpenFavorites}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openPortfolio && (
                            <PortfolioComponent
                                student={parent}
                                setOpenMessages={setOpenMessages}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openResume && (
                            <ResumeComponent
                                student={parent}
                                setOpenMessages={setOpenMessages}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openTasks && (
                            <TasksComponent
                                user={parent}
                                student={student}
                                setOpenTasks={setOpenTasks}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openDocuments && (
                            <DocumentsComponent
                                user={parent}
                                student={student}
                                setOpenDocuments={setOpenDocuments}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openSuggestions && (
                            <SuggestionsComponent
                                user={parent}
                                student={student}
                                setOpenSuggestions={setOpenSuggestions}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={sidebarStyle}
                    >
                        {navigationList}
                    </Grid>
                </Grid>
                <Drawer
                    open={drawerState}
                    onClose={toggleDrawer(false)}
                    anchor='right'
                    sx={{zIndex: 99999}}
                    PaperProps={{
                        sx: {
                            width: '300px',
                        }
                    }}
                >
                    {navigationList}
                </Drawer>
            </Paper>
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {openErrorModalText}
                </Typography>
                </Box>
            </Modal>
            <Modal
                open={openStatusModal}
                onClose={handleCloseStatusModal}
            >
                <Box sx={style}>
                    {openErrorModalText && (
                        <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                            {openErrorModalText}
                        </Typography>
                    )}
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {statusEvent === 1 ? 
                                'Are you sure you want to activate this student?' : 
                                'Are you sure you want to deactivate this student? You will be able to activate them later and you will still be able to see past interactions.'
                            }
                        </Typography>
                        <Button
                            variant="contained"
                            type="submit"
                            disableElevation
                            size="large"
                            fullWidth
                            onClick={handleStatusActivation}
                        >
                            {statusEvent === 1 ? 'Activate' : 'Deactivate'} Student
                        </Button>
                    </>
                </Box>
            </Modal>
        </>
    )
}