import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactGA from "react-ga4";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { gaTrackingId, hubspotId } from './constants/globals';
import Layout from "./components/layout/default/layout";
import SharedLogin from "./pages/shared/login/login";
import SharedActivation from "./pages/shared/activation/activation";
import SharedRequestRecover from "./pages/shared/recover/requestRecover";
import SharedRecover from "./pages/shared/recover/recover";
import StudentsLayout from "./components/layout/student/layout";
import StudentSignUp from "./pages/student/signup/signup";
import StudentDashboard from "./pages/student/dashboard/dashboard";
import StudentEducation from "./pages/student/education/education";
import StudentLoans from "./pages/student/loans/loans";
import StudentScholarships from "./pages/student/scholarships/scholarships";
import StudentOccupation from "./pages/student/occupation/occupation";
import StudentFavorites from "./pages/student/favorites/favorites";
import StudentSettings from "./pages/student/settings/settings";
import StudentSubscription from "./pages/student/billing/subscription/subscription";
import StudentInvoices from "./pages/student/billing/invoices/invoices";
import StudentEducators from './pages/student/educators/educators';
import StudentParents from './pages/student/parents/parents';
import StudentResume from './pages/student/resume/resume';
import StudentPortfolio from './pages/student/portfolio/portfolio';
import StudentMessages from './pages/student/messages/messages';
import StudentToDo from './pages/student/todo/todo';
import StudentDocuments from './pages/student/documents/documents';
import StudentSuggestions from './pages/student/suggestions/suggestions';
import ParentLayout from "./components/layout/parent/layout";
import ParentSignUp from "./pages/parent/signup/signup";
import ParentDashboard from "./pages/parent/dashboard/dashboard";
import ParentStudents from './pages/parent/students/students';
import ParentEducators from './pages/parent/educators/educators';
import ParentMessages from './pages/parent/messages/messages';
import ParentSuggestions from './pages/parent/suggestions/suggestions';
import ParentExplore from "./pages/parent/explore/explore";
import ParentEducation from "./pages/parent/education/education";
import ParentLoans from "./pages/parent/loans/loans";
import ParentScholarships from "./pages/parent/scholarships/scholarships";
import ParentOccupation from "./pages/parent/occupation/occupation";
import ParentSettings from "./pages/parent/settings/settings";
import ParentSubscription from "./pages/parent/billing/subscription/subscription";
import ParentInvoices from "./pages/parent/billing/invoices/invoices";
import EducatorLayout from "./components/layout/educator/layout";
import EducatorRegister from "./pages/educator/register/register";
import EducatorDashboard from "./pages/educator/dashboard/dashboard";
import EducatorStudents from "./pages/educator/students/students";
import EducatorParents from "./pages/educator/parents/parents";
import EducatorEmployees from "./pages/educator/employees/employees";
import EducatorReports from "./pages/educator/reports/reports";
import EducatorDetails from "./pages/educator/details/details";
import EducatorSubscription from "./pages/educator/billing/subscription/subscription";
import EducatorInvoices from "./pages/educator/billing/invoices/invoices";
import EducatorBalance from "./pages/educator/billing/balance/balance";
import EducatorSettings from "./pages/educator/settings/settings";
import EducatorMessages from './pages/educator/messages/messages';
import EducatorJobs from "./pages/educator/jobs/jobs";
import EducatorScholarships from './pages/educator/scholarships/scholarships';
import EducatorAdvertisements from './pages/educator/advertisements/advertisements';
import PublicPortfolio from './pages/shared/public/portfolio';
import NoPage from "./pages/noPage";
import './index.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fa7918'
    },
    secondary: {
      main: '#777777'
    }
  }
});

ReactGA.initialize(gaTrackingId);

export default function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    let script = document.createElement('script');
    script.src=`//js.hs-scripts.com/${hubspotId}.js`;
    script.async = true;
    
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/students" element={<StudentsLayout />}>
              <Route path="dashboard" element={<StudentDashboard />} />
              <Route path="education" element={<StudentEducation />} />
              <Route path="loans" element={<StudentLoans />} />
              <Route path="scholarships" element={<StudentScholarships />} />
              <Route path="occupation" element={<StudentOccupation />} />
              <Route path="favorites" element={<StudentFavorites />} />
              <Route path="settings" element={<StudentSettings />} />
              <Route path="educators" element={<StudentEducators />} />
              <Route path="parents" element={<StudentParents />} />
              <Route path="portfolio" element={<StudentPortfolio />} />
              <Route path="resume" element={<StudentResume />} />
              <Route path="messages" element={<StudentMessages />} />
              <Route path="todo" element={<StudentToDo />} />
              <Route path="documents" element={<StudentDocuments />} />
              <Route path="suggestions" element={<StudentSuggestions />} />
              <Route path="billing">
                <Route path="subscription" element={<StudentSubscription />} />
                <Route path="invoices" element={<StudentInvoices />} />
              </Route>
            </Route>
            <Route path="/parents" element={<ParentLayout />}>
              <Route path="dashboard" element={<ParentDashboard />} />
              <Route path="students" element={<ParentStudents />} />
              <Route path="educators" element={<ParentEducators />} />
              <Route path="messages" element={<ParentMessages />} />
              <Route path="suggestions" element={<ParentSuggestions />} />
              <Route path="explore" element={<ParentExplore />} />
              <Route path="education" element={<ParentEducation />} />
              <Route path="occupation" element={<ParentOccupation />} />
              <Route path="loans" element={<ParentLoans />} />
              <Route path="scholarships" element={<ParentScholarships />} />
              <Route path="settings" element={<ParentSettings />} />
              <Route path="billing">
                <Route path="subscription" element={<ParentSubscription />} />
                <Route path="invoices" element={<ParentInvoices />} />
              </Route>
            </Route>
            <Route path="/educator" element={<EducatorLayout />}>
              <Route path="dashboard" element={<EducatorDashboard />} />
              <Route path="students" element={<EducatorStudents />} />
              <Route path="parents" element={<EducatorParents />} />
              <Route path="employees" element={<EducatorEmployees />} />
              <Route path="reports" element={<EducatorReports />} />
              <Route path="details" element={<EducatorDetails />} />
              <Route path="billing">
                <Route path="subscription" element={<EducatorSubscription />} />
                <Route path="advertising" element={<EducatorBalance />} />
                <Route path="invoices" element={<EducatorInvoices />} />
              </Route>
              <Route path="messages" element={<EducatorMessages />} />
              <Route path="settings" element={<EducatorSettings />} />
              <Route path="scholarships" element={<EducatorScholarships />} />
              <Route path="jobs" element={<EducatorJobs />} />
              <Route path="advertisements" element={<EducatorAdvertisements />} />
            </Route>
            <Route path="/public" element={<Layout />}>
              <Route path="portfolio/:id" element={<PublicPortfolio />} />
            </Route>
            <Route path="/" element={<Layout />}>
              <Route index element={ <Navigate to="/login" /> } />
              <Route path="login" element={<SharedLogin />} />
              <Route path="activation/:token" element={<SharedActivation />} />
              <Route path="recover" element={<SharedRequestRecover />} />
              <Route path="recover/:token" element={<SharedRecover />} />
              <Route path="signup" element={<StudentSignUp />} />
              <Route path="parent/signup" element={<ParentSignUp />} />
              <Route path="register" element={<EducatorRegister />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
