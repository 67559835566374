import React from 'react';
import { getStudentHistoryAsParent, getStudentHistoryAsEducator } from '../../../services/studentHistoryService';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export function HistoryList({type, userId, studentId}) {
    const [historyData, setHistoryData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const fetchHistory = async (page = 1, append = false) => {
        setLoading(true);
        try {
            let response;
            if (type === 'parent') {
                response = await getStudentHistoryAsParent(localStorage.getItem('token'), studentId, page);
            } else if (type === 'educator') {
                response = await getStudentHistoryAsEducator(localStorage.getItem('token'), userId, studentId, page);
            }

            if (response.status === true) {
                setHistoryData(prevData => append ? [...prevData, ...response.data] : response.data);
                setCurrentPage(response.pagination.current_page);
                setLastPage(response.pagination.last_page);
            }
        } catch (error) {
            console.error("Error fetching history:", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchHistory();
    }, [type, userId, studentId]);

    const loadMore = () => {
        if (currentPage < lastPage) {
            fetchHistory(currentPage + 1, true);
        }
    };

    return (
        <Container maxWidth={false} disableGutters={true}>
            {historyData?.length > 0 ? (
                <>
                    <Grid container alignItems="center" justifyContent="center" spacing={0}>
                        {historyData?.map((item, index) => (
                            <Grid item xs={12} key={index}>
                                <Typography color='secondary' sx={{fontSize:'14px'}}>{item.text}</Typography>
                                <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                            </Grid>
                        ))}
                    </Grid>
                    {currentPage < lastPage && (
                        <Grid container justifyContent="center" sx={{ mt: 2 }}>
                            <Button 
                                variant="contained" 
                                onClick={loadMore} 
                                disabled={loading}
                                disableElevation
                            >
                                {loading ? <CircularProgress size={24} /> : "Load More"}
                            </Button>
                        </Grid>
                    )}
                </>
            ) : (
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                    No student activity found.
                </Typography>
            )}
        </Container>
    );
}