import React, { PureComponent } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import { userAccount, checkType } from '../../../services/userService';
import { getOccupation, updateOccupation, updateIncome } from '../../../services/employmentService';
import { getAdvertisements } from '../../../services/advertisementsService';
import { AdvertisementDisplay } from '../../../components/global/advertisements/advertisementDisplay';
import { getSponsoredOccupations } from '../../../services/advertisementsService';
import { studentDisclaimer } from '../../../components/global/studentDisclaimer';
import { OccupationModal } from '../../../components/global/occupationModal';
import { IncomeModal } from '../../../components/global/incomeModal';
import { AddRemoveFavorite } from '../../../components/global/favorites/addRemoveFavorite';
import { getFavorites } from '../../../services/favoriteService';
import { SuggestionsView } from '../../../components/global/suggestions/suggestionsView';
import { getComparisons } from '../../../services/suggestionsService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
        {value}
      </text>
    );
  }
}

const Occupation = () => {
  const [userType, setUserType] = React.useState(1);
  const [userData, setUserData] = React.useState({});
  const [openChangeOccupationModal, setOpenChangeOccupationModal] = React.useState(false);
  const [openChangeIncomeModal, setOpenChangeIncomeModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [investmentId, setInvestmentId] = React.useState(null);
  const [investmentData, setInvestmentData] = React.useState(null);
  const [userIncome, setUserIncome] = React.useState([]);
  const [occupationData, setOccupationData] = React.useState([]);
  const [sponsoredOccupations, setSponsoredOccupations] = React.useState([]);
  const [advertisements, setAdvertisements] = React.useState([]);
  const [favorites, setFavorites] = React.useState([]);
  const [occupationJobsData, setOccupationJobsData] = React.useState([]);
  const [industryJobsData, setIndustryJobsData] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
  const [specificType, setSpecificType] = React.useState(false);
  const [reloadPage, setReloadPage] = React.useState(false);
  const handleOpenChangeOccupationModal = () => setOpenChangeOccupationModal(true);
  const handleCloseChangeOccupationModal = () => setOpenChangeOccupationModal(false);
  const handleOpenChangeIncomeModal = () => setOpenChangeIncomeModal(true);
  const handleCloseChangeIncomeModal = () => setOpenChangeIncomeModal(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);

  const handleCloseDrawer = () => {
      setOpenSuggestionsDrawer(false);
      setSelectedSuggestion({});
      setSpecificType(false);
  }

  const drawerStyle = {
      width: '80%',
      '@media (max-width: 800px)': {
          width: '100%'
      }
  }

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
      setUserType(requestData.data?.type);
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUserData(userData.data)
        setUserIncome(userData.data.investments[0].custom_wage);
        setInvestmentId(userData.data.investments[0].id);

        getOccupation(localStorage.getItem('token'), userData.data.investments[0].id).then(occupationData => {
          if (occupationData.status == true) {
            setOccupationData(occupationData.data);
            let year = new Date().getFullYear();
            let beginningYear = Math.floor(year/10)*10;
            let endYear = Math.ceil(year/10)*10;
            for (var i = beginningYear; i <= endYear; i++) {
              occupationJobsData.push({'year': i});
            }
            for (let i = 0; i < occupationJobsData.length; i++) {
              let yearlyEmploymentIncrease = occupationData.data.occupation.employment_current + ((occupationData.data.occupation.employment_change / (endYear - beginningYear)) * i);
              occupationJobsData[i]['noj'] = yearlyEmploymentIncrease;
            }
            if (occupationData.data?.industry && Object.keys(occupationData.data?.industry).length) {
              for (var i = beginningYear; i <= endYear; i++) {
                industryJobsData.push({'year': i});
              }
              for (let i = 0; i < industryJobsData.length; i++) {
                let yearlyEmploymentIncrease = occupationData.data.industry.employment_current + ((occupationData.data.industry.employment_change / (endYear - beginningYear)) * i);
                industryJobsData[i]['noj'] = yearlyEmploymentIncrease;
              }
            }
            getSponsoredOccupations(localStorage.getItem('token'), userData.data.investments[0].id).then(sponsoredOccupationsData => {
              if (sponsoredOccupationsData.status == true) {
                setSponsoredOccupations(sponsoredOccupationsData.data);
              } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(sponsoredOccupationsData.message);
              }
            }).catch(err => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            });
            getAdvertisements(localStorage.getItem('token'), 'advertisement').then(advertisements => {
              if (advertisements.status == true) {
                setAdvertisements(advertisements.data);
              } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(advertisements.message);
              }
            }).catch(err => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            });
            getFavorites(localStorage.getItem('token'), '*').then(favorites => {
              if (favorites.status == true) {
                setFavorites(favorites.data);
              } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(favorites.message);
              }
            }).catch(err => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            });
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(occupationData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const handleRemoveIncome = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'custom_wage':null,
      'yearly_increase_percentage':null
    });

    updateIncome(localStorage.getItem('token'), investmentId, data).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  // could be used for later
  const handleChangeIndustry = (e, id) => {
    e.preventDefault();

    let data = new URLSearchParams({
      'occupation':occupationData.occupation.occupation_code,
      'industry':id
    });

    updateOccupation(localStorage.getItem('token'), investmentId, data).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }

  const handleCompareIndustry = (e, id) => {
    getComparisons(localStorage.getItem('token'), userData.id, id, 'industry').then(comparisonData => {
        if (comparisonData.status == true) {
            setOpenSuggestionsDrawer(true);
            setSelectedSuggestion(comparisonData.data);
            setSpecificType('industry');
        } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(comparisonData.message);
            setOpenSuggestionsDrawer(false);
            setSelectedSuggestion({});
            setSpecificType(false);
        }
    }).catch(err => {
        setOpenErrorModal(true);
        setOpenErrorModalText(err);
        setOpenSuggestionsDrawer(false);
        setSelectedSuggestion({});
        setSpecificType(false);
    });
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container spacing={3}>
        <Grid item sm={12} md={(sponsoredOccupations?.length > 0 || advertisements?.length) ? 9 : 12}>
          <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mb: 2 }}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Occupation Information
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={handleOpenChangeOccupationModal}
              >
                Change Occupation
              </Button>
            </Grid>
          </Grid>
          {occupationData?.occupation &&
          <Paper sx={{ p: 2, mb: 2, position:'relative' }}>
            <Box
              sx={{
                position:'absolute',
                right: 1,
                top: 1,
                zIndex: 9
              }}
            >
              <AddRemoveFavorite
                allFavorites={favorites}
                setAllFavorites={setFavorites}
                potentialFavorite={{
                  id: occupationData?.occupation?.occupation_code,
                  type: 2,
                  title: occupationData?.occupation?.occupation_title
                }}
                size={'large'}
                setError={setOpenErrorModal}
                setErrorText={setOpenErrorModalText}
              />
            </Box>
            <Typography color='primary' variant='h3' component='div'>
              {occupationData.occupation.occupation_title}
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />


            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <Grid
                  sx={{
                    pt: 2,
                    pb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 455,
                  }}
                >
                  {occupationJobsData.length > 0 && (
                  <ResponsiveContainer width='100%' height='100%'>
                    <LineChart
                      data={occupationJobsData}
                      margin={{
                        top: 5,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='year' />
                      <YAxis />
                      <Legend />
                      <Line type='monotone' dataKey='noj' name='Projected Number of Jobs' stroke='#fa7918' label={<CustomizedLabel />} />
                    </LineChart>
                  </ResponsiveContainer>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid
                  sx={{
                    pt: 2,
                    pb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  >
                    {userIncome ? (
                      <>
                        <Typography color='secondary'component='div'>
                          <Button variant="text" onClick={handleOpenChangeIncomeModal} color='secondary' sx={{padding:0, minHeight: 0, minWidth: 0}} endIcon={<EditIcon />}>
                            Your Income
                          </Button>
                        </Typography>
                        <Typography color='primary' variant='h4' component='div'>
                          ${userIncome?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                        </Typography>
                        <Typography color='secondary'component='div'>
                          <Button variant="text" onClick={handleRemoveIncome} color='secondary' sx={{padding:0, minHeight: 0, minWidth: 0, fontSize: '10px'}}>
                            Remove X
                          </Button>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography color='secondary'component='div'>
                          <Button variant="text" onClick={handleOpenChangeIncomeModal} color='secondary' sx={{padding:0, minHeight: 0, minWidth: 0}} endIcon={<EditIcon />}>
                            Avg Salary
                          </Button>
                        </Typography>
                        <Typography color='primary' variant='h4' component='div'>
                          ${occupationData.occupation.median_annual_wage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                        </Typography>
                      </>
                    )}
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Typography color='secondary'component='div'>
                      Education Requirements
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {occupationData.occupation.entry_level_education}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Typography color='secondary'component='div'>
                      Work Experience Requirements
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {occupationData.occupation.work_experience_requirements}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Typography color='secondary'component='div'>
                      On The Job Training
                    </Typography>
                    <Typography color='primary' variant='h4' component='div'>
                      {occupationData.occupation.on_the_job_training}
                    </Typography>
                </Grid>
              </Grid>
            </Grid>


          </Paper>
          }
          {occupationData?.industry && Object.keys(occupationData?.industry).length > 0 &&
          <>
            <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mb: 2, mt: 2 }}>
              <Grid item sm={12} md={6}>
                <Typography color='secondary' variant='h4' component='div'>
                  Industry Information
                </Typography>
              </Grid>
              <Grid item sm={12} md={6} align='right'>
                <Button
                  variant='contained'
                  disableElevation
                  size='large'
                  onClick={handleOpenChangeOccupationModal}
                >
                  Change Industry
                </Button>
              </Grid>
            </Grid>
            <Paper sx={{ p: 2 }}>
              <Typography color='primary' variant='h4' component='div'>
                {occupationData.industry.industry_title}
              </Typography>
              <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />


              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  <Grid
                    sx={{
                      pt: 2,
                      pb: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 355,
                    }}
                  >
                    <ResponsiveContainer width='100%' height='100%'>
                      <LineChart
                        data={industryJobsData}
                        margin={{
                          top: 5,
                          left: 20,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='year' />
                        <YAxis />
                        <Legend />
                        <Line type='monotone' dataKey='noj' name='Projected Number of Jobs' stroke='#fa7918' label={<CustomizedLabel />} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid
                    sx={{
                      pt: 2,
                      pb: 2,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    >
                      <Typography color='secondary'component='div'>
                        Employment % Change
                      </Typography>
                      <Typography color='primary' variant='h4' component='div'>
                        {occupationData.industry.employment_percent_change}%
                      </Typography>
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Typography color='secondary'component='div'>
                        % of Occupation
                      </Typography>
                      <Typography color='primary' variant='h4' component='div'>
                        {occupationData.industry.percent_of_population_current}%
                      </Typography>
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Typography color='secondary'component='div'>
                        % of Industry
                      </Typography>
                      <Typography color='primary' variant='h4' component='div'>
                        {occupationData.industry.percent_of_industry_current}%
                      </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </>
          }
        </Grid>
        {(sponsoredOccupations?.length > 0 || advertisements?.length > 0) &&
          <Grid item sm={12} md={3}>
            {advertisements?.length > 0 ? (
              <>
                <Tooltip enterTouchDelay={0} title='These are sponsored advertisements based on your interests.' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 3, textAlign: 'center', justifyContent: 'center' }}>
                    Sponsored <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                {advertisements?.map((advertisement, index) => (
                    <AdvertisementDisplay 
                        advertisement={advertisement}
                        selectedCategory={advertisement?.category_id}
                        type={'live'}
                        typeTitle={null}
                        setError={openErrorModal}
                        setErrorText={openErrorModalText}
                    />
                ))}
              </>
            ) : (
              <>
                <Tooltip enterTouchDelay={0} title='Other occupations and industries you may want to consider in your search!' placement='bottom' arrow>
                  <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mb: 4, textAlign: 'center', justifyContent: 'center' }}>
                    Industries To Consider <InfoIcon sx={{fontSize: 'medium'}} />
                  </Typography>
                </Tooltip>
                {sponsoredOccupations?.map((occupation, index) => (
                  <Paper sx={{ p: 2, mb:2 }} key={index}>
                    <Typography color='secondary' variant='h6' sx={{mb:1}}><b>{occupation.title}</b></Typography>
                    <Typography color='secondary' sx={{mb:1}}>Total Citizens Employed: <b>{occupation.employment_current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></Typography>
                    <Button
                      variant='outlined'
                      size='medium'
                      fullWidth
                      onClick={e => handleCompareIndustry(e, occupation.code)}
                    >
                      <b>Compare Statistics</b>
                    </Button>
                  </Paper>
                ))}
              </>
            )}
          </Grid>
        }
      </Grid>
      {studentDisclaimer}
      <Modal
        open={openChangeOccupationModal}
        onClose={handleCloseChangeOccupationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {<OccupationModal userType={userType} />}
        </Box>
      </Modal>
      <Modal
        open={openChangeIncomeModal}
        onClose={handleCloseChangeIncomeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {<IncomeModal userType={userType} />}
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
      <Drawer
          open={openSuggestionsDrawer}
          onClose={handleCloseDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <SuggestionsView
              type={'student'}
              selectedSuggestion={selectedSuggestion}
              specificType={specificType}
              student={userData}
              setReloadList={setReloadPage}
              handleCloseDrawer={handleCloseDrawer}
              setError={setOpenErrorModal}
              setErrorText={setOpenErrorModalText}
          />
      </Drawer>
    </Container>
  );
};

export default Occupation;
