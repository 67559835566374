import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { getStudentFavoritesAsParent } from '../../../../../services/favoriteService';
import { ListItemFavorite } from '../../../../../components/global/favorites/listItemFavorite';
import { SuggestionsView } from '../../../../../components/global/suggestions/suggestionsView';

export function FavoritesComponent({student, openFavorites, setError, setErrorText}) {
    const [favorites, setFavorites] = React.useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
    const [specificType, setSpecificType] = React.useState(false);
    const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);

    const handleCloseDrawer = () => {
        setOpenSuggestionsDrawer(false);
        setSelectedSuggestion({});
        setSpecificType(false);
    }

    const drawerStyle = {
        width: '80%',
        '@media (max-width: 800px)': {
            width: '100%'
        }
    }

    React.useEffect(() => {
        getStudentFavoritesAsParent(localStorage.getItem('token'), student?.id).then(favorites => {
            if (favorites.status == true) {
                setFavorites(favorites.data);
            } else {
              setError(true);
              setErrorText(favorites.message);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
    }, [openFavorites]);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div' sx={{mb:2}}>
                Student Favorites
            </Typography>
            {favorites?.length > 0 ? (
                <>
                {favorites?.map((favorite, index) => (
                    <ListItemFavorite
                        student={student}
                        type={'educator'}
                        setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                        setSelectedSuggestion={setSelectedSuggestion}
                        setSpecificType={setSpecificType}
                        currentFavorites={favorites}
                        setCurrentFavorites={setFavorites}
                        favorite={favorite}
                        searchType={'*'}
                        setError={setError}
                        setErrorText={setErrorText}
                        index={index}
                    />
                ))}
                </>
            ) : (
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10 }}>
                    This student has not added any favorites to their account.
                </Typography>
            )}
            <Drawer
                open={openSuggestionsDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <SuggestionsView
                    type={'educator'}
                    selectedSuggestion={selectedSuggestion}
                    specificType={specificType}
                    student={student}
                    setReloadList={false}
                    handleCloseDrawer={handleCloseDrawer}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Drawer>
        </>
    )
}