import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Modal from '@mui/material/Modal';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { userAccount, updateUserAccount, checkType, updateStudentPhoto } from '../../../services/userService';
import { getStates, getTaxFilingStatuses } from '../../../services/generalService';
import { storageUrl } from '../../../constants/globals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Settings = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [income, setIncome] = useState(null);
  const [investmentId, setInvestmentId] = React.useState(null);
  const [investment, setInvestment] = React.useState(null);
  const [yearlyIncreasePercentage, setYearlyIncreasePercentage] = useState(null);
  const [password, setPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [states, setStates] = useState([]);
  const [taxFilingStatuses, setTaxFilingStatuses] = useState([]);
  const [taxStatus, setTaxStatus] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [familySize, setFamilySize] = React.useState(null);
  const [spouseIncome, setSpouseIncome] = useState(null);
  const [spouseYearlyIncreasePercentage, setSpouseYearlyIncreasePercentage] = useState(null);
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [openConfirmPasswordModal, setOpenConfirmPasswordModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [formCompleted, setFormCompleted] = React.useState(false);
  const [oldPasswordCompleted, setOldPasswordCompleted] = React.useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const imageUploader = React.useRef(null);
  const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleOpenConfirmPasswordModal = () => setOpenConfirmPasswordModal(true);
  const handleCloseConfirmPasswordModal = () => setOpenConfirmPasswordModal(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  if (!localStorage.getItem('token')) {
    window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setNewsletterChecked(userData.data.newsletter_opt_in === true ? true : false);
        setFirstName(userData.data.firstName);
        setLastName(userData.data.lastName);
        setEmail(userData.data.email);
        setIncome(userData.data.investments[0].custom_wage);
        setYearlyIncreasePercentage(userData.data.investments[0].yearly_increase_percentage);
        setInvestmentId(userData.data.investments[0].id);
        setInvestment(userData.data.investments[0]);
        setFamilySize(userData.data.investments[0].family_size);
        setSpouseIncome(userData.data.investments[0].spouse_income);
        setSpouseYearlyIncreasePercentage(userData.data.investments[0].spouse_income_yearly_increase_percentage);
        setFormCompleted(true);
        setTaxStatus(userData.data.investments[0].tax_filing_status);
        setState(userData.data.investments[0].state);
        if (userData.data.profile_image !== null) {
          setProfileImage(storageUrl + userData.data.profile_image);
        }
        getStates().then(states => {
          if (states.status == true) {
            setStates(states.data);
            if (userData.data.investments[0].state !== null && userData.data.investments[0].city !== null) {
              setCity(states.data?.find(region => region.id === userData.data.investments[0].state)?.cities?.find(city => city.id === userData.data.investments[0].city));
            }
          }
        });
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getTaxFilingStatuses().then(filingStatuses => {
      setTaxFilingStatuses(filingStatuses.data);
    });
  }, []);

  const handleImageUpload = (event) => {

    const data = new FormData();
    data.append('profile_image', event.target.files[0]);

    updateStudentPhoto(localStorage.getItem('token'), data).then(photoData => {
      if (photoData.status) {
        setProfileImage(storageUrl + photoData.data);
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(photoData.message);
      }
    }).catch(err => {
      setOpenErrorModal(true);
      setOpenErrorModalText('There was an error uploading the image.');
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new URLSearchParams({
      'first_name':firstName,
      'last_name':lastName,
      'email':email,
      'password':password,
      'old_password':oldPassword,
      'income':income,
      'yearly_increase_percentage':yearlyIncreasePercentage,
      'family_size':familySize,
      'state':state,
      'city': (city === null) ? null : city?.id,
      'tax_filing_status':taxStatus,
      'spouse_income':spouseIncome,
      'spouse_income_yearly_increase_percentage':spouseYearlyIncreasePercentage,
      'newsletter_opt_in':newsletterChecked
    });

    updateUserAccount(localStorage.getItem('token'), investmentId, data).then(update => {
      setOpenConfirmPasswordModal(false);
      setOpenErrorModal(true);
      setOpenErrorModalText(update.message);
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  const onFirstNameChange = (event, values) => {
    setFirstName(event.target.value);
    submitCheck(event.target.value, lastName, email);
  }

  const onLastNameChange = (event, values) => {
    setLastName(event.target.value);
    submitCheck(firstName, event.target.value, email);
  }

  const onEmailChange = (event, values) => {
    setEmail(event.target.value);
    submitCheck(firstName, lastName, event.target.value);
  }

  const onNewsletterChange = (event, values) => {
    setNewsletterChecked(event.target.checked);
  }

  const onTaxFilingStatusChange = (event, values) => {
    setTaxStatus(event.target.value);
  }

  const onFamilySizeChange = (event, values) => {
    setFamilySize(event.target.value);
  }

  const onStateChange = (event, values) => {
    setState(event.target.value);
  }

  const onCityChange = (event, values) => {
    setCity(states?.find(region => region.id === state)?.cities?.find(city => city.id === values?.id));
  }

  const onIncomeChange = (event, values) => {
    setIncome(event.target.value);
  }

  const onYearlyIncreasePercentageChange = (event, values) => {
    setYearlyIncreasePercentage(event.target.value);
  }

  const onSpouseIncomeChange = (event, values) => {
    setSpouseIncome(event.target.value);
  }

  const onSpouseYearlyIncreasePercentageChange = (event, values) => {
    setSpouseYearlyIncreasePercentage(event.target.value);
  }

  const onPasswordChange = (event, values) => {
    setPassword(event.target.value);
  }

  const onOldPasswordChange = (event, values) => {
    setOldPasswordCompleted(false);
    setOldPassword('');
    if (event.target.value) {
      setOldPassword(event.target.value);
    }
    if (
      validatePassword(event.target.value)
    ) {
      setOldPasswordCompleted(true);
    }
  }

  const submitCheck = (first, last, mail) => {
    setFormCompleted(false);
    if (
      first &&
      last &&
      validateEmail(mail)
    ) {
      setFormCompleted(true);
    }
  }

  const validateEmail = (email) => {
    return String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const validatePassword = (password) => {
    return /[A-Z]/       .test(password) &&
           /[a-z]/       .test(password) &&
           /[0-9]/       .test(password) &&
           /[^A-Za-z0-9]/.test(password) &&
           password.length > 4;
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='lg'>
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item sm={12} md={6}>
            <Typography color='secondary' variant='h4' component='div'>
              Account Settings
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} align='right'>
            <Button
              variant='contained'
              disableElevation
              size='large'
              onClick={handleOpenConfirmPasswordModal}
              disabled={formCompleted ? false : true}
            >
              Save Settings
            </Button>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="first_name"
              required
              fullWidth
              id="first_name"
              label="First Name"
              autoFocus
              value={firstName || ''}
              type="text"
              onChange={onFirstNameChange}
            />
            <TextField
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="family-name"
              value={lastName || ''}
              type="text"
              sx={{ mt: 3 }}
              onChange={onLastNameChange}
            />
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email || ''}
              type="email"
              sx={{ mt: 3 }}
              onChange={onEmailChange}
            />
            <Tooltip enterTouchDelay={0} title="Must include at least one uppercase character, number and special character" arrow>
              <TextField
                fullWidth
                name="password"
                label="New Password (Optional)"
                id="password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                sx={{ mt: 3 }}
                onChange={onPasswordChange}
                value={password || ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none"
                }}
              />
              <div
                style={{
                  height: "210px",
                  width: "210px",
                  border: "1px dashed #777",
                  borderRadius: "200px",
                  overflow: "hidden",
                  cursor: "pointer",
                  position: "relative"
                }}
                onClick={() => imageUploader.current.click()}
              >
                {!profileImage && (
                  <>
                    <PhotoCameraIcon
                      fontSize="large"
                      style={{
                        position: "absolute",
                        top: "43%",
                        left: "43%",
                        transform: "translateX(-50%) translateY(-50%)",
                        zIndex: 0,
                        color: "#999",
                        transform: "scale(4)"
                      }}
                    />
                  </>
                )}
                {profileImage && (
                  <>
                    <img
                      src={profileImage}
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        zIndex: 1
                      }}
                    />
                  </>
                )}
              </div>
            </div>

          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Tax Information</Divider>
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="tax_filing_status_label">Tax Filing Status *</InputLabel>
                <Select
                  labelId="tax_filing_status_label"
                  id="tax_filing_status"
                  value={taxStatus || ''}
                  label="Tax Filing Status *"
                  onChange={onTaxFilingStatusChange}
                >
                  {taxFilingStatuses.map((taxStatus) => (
                    <MenuItem key={taxStatus.id} value={taxStatus.id}>{taxStatus.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                name="family_size"
                fullWidth
                id="family_size"
                label="Family Size"
                required
                type="number"
                value={familySize || ''}
                onChange={onFamilySizeChange}
              />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="state_label">State (Optional)</InputLabel>
              <Select
                labelId="state_label"
                id="state"
                value={state || ''}
                label="State (Optional)"
                onChange={onStateChange}
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.id}>{state.name} - ({state.code})</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {(state !== null) && (
            <Grid item xs={12}>
              <Autocomplete
                  disablePortal
                  id='cities'
                  options={states?.find(region => region.id === state)?.cities}
                  name='cities'
                  fullWidth
                  onChange={(event, values) => {onCityChange(event, values)}}
                  getOptionLabel={(option) => option ? option.name : ''}
                  value={city}
                  renderInput={(params) => <TextField {...params} label="City (Optional)" />}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Income Information</Divider>
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                name="income"
                fullWidth
                id="income"
                label="Your Approximate Income (Optional)"
                type="number"
                value={income || ''}
                onChange={onIncomeChange}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                name="yearly_increase_percentage"
                fullWidth
                id="yearly_increase_percentage"
                label="Yearly Percentage Increase of Income (Optional)"
                type="number"
                value={yearlyIncreasePercentage || ''}
                onChange={onYearlyIncreasePercentageChange}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                name="spouse_income"
                fullWidth
                id="spouse_income"
                label="Your Spouses Income (Optional)"
                type="number"
                value={spouseIncome || ''}
                onChange={onSpouseIncomeChange}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                name="spouse_income_yearly_increase_percentage"
                fullWidth
                id="spouse_income_yearly_increase_percentage"
                label="Spouses Yearly Percentage Increase of Income (Optional)"
                type="number"
                value={spouseYearlyIncreasePercentage || ''}
                onChange={onSpouseYearlyIncreasePercentageChange}
              />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox
                value="allowExtraEmails"
                color="primary"
                name="newsletter_opt_in"
                checked={newsletterChecked}
                onChange={onNewsletterChange}
              />}
              label="Newsletter opt in."
            />
          </Grid>

        </Grid>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
        <Grid container alignItems="right" justifyContent="right" spacing={3}>
          <Grid item align='right'>
            <Button
              variant='contained'
              disableElevation
              size='large'
              onClick={handleOpenConfirmPasswordModal}
              disabled={formCompleted ? false : true}
            >
              Save Settings
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        open={openConfirmPasswordModal}
        onClose={handleCloseConfirmPasswordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        component="form"
        onSubmit={handleSubmit}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm your account password to update your settings.
          </Typography>
          <TextField
            fullWidth
            name="old_password"
            label="Current Password"
            id="old_password"
            type={showCurrentPassword ? "text" : "password"}
            sx={{ mt: 2, mb: 2 }}
            onChange={onOldPasswordChange}
            value={oldPassword || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownCurrentPassword}
                  >
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            type="submit"
            disableElevation
            size="large"
            fullWidth
            disabled={oldPasswordCompleted ? false : true}
          >
            Save Settings
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Settings;
