import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getEducators } from '../../../../../services/parentActionsService';
import { revokeEducatorInvite, approveEducators, disapproveEducators, inviteEducator } from '../../../../../services/parentActionsService';

export function EducatorsComponent({user, student, setError, setErrorText}) {
    const [educators, setEducators] = React.useState({});

    const revokeEducatorConnection = (id) => {
        let data = new URLSearchParams({
            'id':id
        });

        revokeEducatorInvite(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                window.location.reload();
            } else {
                setError(true);
                setErrorText(update?.message);
            }
        });
    };

    const disapproveEducatorConnection = (id) => {
        let data = new URLSearchParams({
            'id':id
        });

        disapproveEducators(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                window.location.reload();
            } else {
                setError(true);
                setErrorText(update?.message);
            }
        })
    };

    const approveEducatorConnection = (id) => {
        let data = new URLSearchParams({
            'id':id
        });

        approveEducators(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                window.location.reload();
            } else {
                setError(true);
                setErrorText(update?.message);
            }
        })
    };

    const messageEducator = () => {
        window.location.href = '/parents/messages';
    };

    const addEducator = (educator) => {
        let data = new URLSearchParams({
          'email': educator.email,
          'message': ''
        });
    
        inviteEducator(localStorage.getItem('token'), data).then(update => {
          if (update?.status == true) {
            getEducators(localStorage.getItem('token')).then(educators => {
                if (educators.status == true) {
                    setEducators(educators.data);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
          } else {
              setError(true);
              setErrorText(update?.message);
          }
        });
    }

    React.useEffect(() => {
      getEducators(localStorage.getItem('token')).then(educators => {
        if (educators.status == true) {
          setEducators(educators.data);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }, []);

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Students Educators
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid 
                container 
                alignItems="center" 
                justifyContent="center" 
                spacing={3}
            >
                <Grid item xs={12}>
                    {student?.educators?.length > 0 ? (
                        <>
                            {student?.educators?.map((educator, index) => (
                                <Box sx={{ width: '100%' }} key={index} >
                                    <Grid 
                                        container 
                                        spacing={2}
                                        alignItems="center" 
                                        justifyContent="center" 
                                    >
                                        <Grid item xs={12} sm={8}>
                                            {!educator?.first_name && !educator?.last_name ? (
                                                <Typography color='secondary' variant='h6'>{educator?.email}</Typography>
                                            ) : (
                                                <>
                                                    <Typography color='secondary' variant='h6'>{educator?.first_name} {educator?.last_name}</Typography>
                                                    <Typography color='secondary' sx={{mb:1}}>{educator?.email}</Typography>
                                                </>
                                            )}
                                            {educator?.pending === 2 ? (
                                                <Chip size='small' color='primary' sx={{color:'#fff', mr:1}} label='Pending Educator Approval' />
                                            ) : (
                                                <>
                                                    {educator?.active === 0 && (
                                                        <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                                    )} 
                                                    {educator?.active === 1 && (
                                                        <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                                    )}
                                                    {educator?.pending === 1 && (
                                                        <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Student Approval' />
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={4} align='right' alignItems="center" justifyContent="center" sx={{pr:1}}>
                                            {(() => {
                                                const parentEducator = Object.values(educators)?.find(
                                                    parentEducator => parentEducator.id === educator.id
                                                );

                                                return parentEducator ? (
                                                    <>
                                                        {parentEducator?.pending === 1 ? (
                                                            <>
                                                                <Tooltip
                                                                    arrow
                                                                    title="This educator would like to connect, click to approve them. NOTE: By approving this educator they will be able to see your profile including scholarships and loans."
                                                                >
                                                                    <IconButton
                                                                        variant="contained"
                                                                        size="small"
                                                                        disabled={user?.account_locked !== true ? false : true}
                                                                        onClick={() => {
                                                                            approveEducatorConnection(parentEducator.id);
                                                                        }}
                                                                    >
                                                                        <CheckIcon sx={{ color: "green" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip arrow title="This educator would like to connect, click to disapprove them.">
                                                                    <IconButton
                                                                        variant="contained"
                                                                        size="small"
                                                                        disabled={user?.account_locked !== true ? false : true}
                                                                        onClick={() => {
                                                                            disapproveEducatorConnection(parentEducator.id);
                                                                        }}
                                                                    >
                                                                        <CloseIcon sx={{ color: "red" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    disableElevation
                                                                    size="small"
                                                                    disabled={!user?.account_locked && parentEducator?.pending !== 2 ? false : true}
                                                                    onClick={() => { messageEducator() }}
                                                                >
                                                                    Message
                                                                </Button>
                                                                {parentEducator?.pending === 2 && (
                                                                    <Tooltip arrow title="Revoke your request to connect with this educator.">
                                                                        <IconButton
                                                                            variant="contained"
                                                                            size="small"
                                                                            disabled={user?.account_locked !== true ? false : true}
                                                                            onClick={() => {
                                                                                revokeEducatorConnection(parentEducator?.id);
                                                                            }}
                                                                        >
                                                                            <CloseIcon sx={{ color: "red" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Tooltip
                                                        arrow
                                                        title="This educator is not part of your network. Click to add them to your network."
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            disableElevation
                                                            size="small"
                                                            disabled={!user?.account_soft_locked ? false : true}
                                                            onClick={() => { addEducator(educator) }}
                                                        >
                                                            Add
                                                        </Button>
                                                    </Tooltip>
                                                );
                                            })()}
                                        </Grid>
                                    </Grid>
                                    {index !== student?.educators?.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                                </Box>
                            ))}
                        </>
                    ) : (
                        <>
                            <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                                No Students Found.
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}