import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PersonIcon from '@mui/icons-material/Person';
import { storageUrl } from '../../../../../constants/globals';

export function OverviewComponent({parent}) {

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
                Overview
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid 
                container 
                spacing={2} 
                sx={{pr:1}}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={12} lg={6} order={{xs: 2, sm: 2, md: 2, lg: 1}}>
                        <Table>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <Typography color='secondary' component='div' sx={{fontWeight:'bold'}}>
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" color='primary'>
                                        <Typography color='primary' component='div'>
                                            {parent?.email}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                </Grid>
                <Grid item md={12} lg={6} textAlign={'center'} order={{xs: 1, sm: 1, md: 1, lg: 2}}>
                    

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div style={{
                            height: "130px",
                            width: "130px",
                            border: "1px solid #777",
                            overflow: "hidden",
                            cursor: "pointer",
                            position: "relative",
                            borderRadius: '4px'
                        }}>
                            {!parent?.profile_image && (
                            <>
                                <PersonIcon
                                    fontSize="large"
                                    style={{
                                        position: "absolute",
                                        top: "37%",
                                        left: "37%",
                                        transform: "translateX(-50%) translateY(-50%)",
                                        zIndex: 0,
                                        color: "#999",
                                        transform: "scale(4)"
                                    }}
                                />
                            </>
                            )}
                            {parent?.profile_image && (
                            <>
                                <img
                                    src={storageUrl + parent?.profile_image}
                                    style={{
                                        height: "100%",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translateX(-50%) translateY(-50%)",
                                        zIndex: 1
                                    }}
                                />
                            </>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}