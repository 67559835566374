import React, { Suspense } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { userAccount, checkType } from '../../../services/userService';
import { storageUrl, appUrl } from '../../../constants/globals';
import { getAllUserPortfolioItems } from '../../../services/portfolioService';
import { PortfolioItemList } from '../../../components/global/portfolio/portfolioList';
import { PortfolioItemView } from '../../../components/global/portfolio/portfolioView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Portfolio = () => {
  const [profileImage, setProfileImage] = React.useState(null);
  const [userData, setUserData] = React.useState({});
  const [portfolioData, setPortfolioData] = React.useState({});
  const [selectedPortfolioItem, setSelectedPortfolioItem] = React.useState({});
  const [openError, setOpenError] = React.useState(false);
  const [openErrorText, setOpenErrorText] = React.useState('');
  const handleCloseError = () => setOpenError(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleCloseSuccess = () => setOpenSuccess(false);
  const [openCopied, setOpenCopied] = React.useState(false);
  const handleCloseCopied = () => setOpenCopied(false);
  const [reloadList, setReloadList] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const handleCloseShareModal = () => setOpenShareModal(false);
  
  const handleCloseDrawer = () => {
    setSelectedPortfolioItem({});
    setOpenDrawer(false);
  }

  const handleOpenDrawer = (portfolioItem) => {
    setSelectedPortfolioItem(portfolioItem);
    setOpenDrawer(true);
  }

  const handleCopyToClipboard = (url) => {
    setOpenCopied(true);
    navigator.clipboard.writeText(url);
  }

  const drawerStyle = {
      width: '60%',
      '@media (max-width: 800px)': {
          width: '100%'
      }
  }

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data.educator_count === 0) {
          window.location.href = '/students/dashboard';
        }
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUserData(userData.data);
        if (userData.data.profile_image !== null) {
          setProfileImage(storageUrl + userData.data.profile_image);
        }
        getAllUserPortfolioItems(localStorage.getItem('token')).then(portfolioItems => {
          if (portfolioItems.status == true) {
            setPortfolioData(portfolioItems.data);
          } else {
            setOpenError(true);
            setOpenErrorText(portfolioItems.message);
          }
        });
      } else {
        setOpenError(true);
        setOpenErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
    getAllUserPortfolioItems(localStorage.getItem('token')).then(portfolioItems => {
          if (portfolioItems.status == true) {
            setPortfolioData(portfolioItems.data);
          } else {
            setOpenError(true);
            setOpenErrorText(portfolioItems.message);
          }
      }).catch(err => {
        setOpenError(true);
        setOpenErrorText(err);
      });
      setReloadList(false);
  }, [userData, setOpenDrawer, reloadList]);

  return (
    <>
      <Container sx={{ mt: 4, mb: 4 }} maxWidth='lg'>
        <Paper sx={{ p: 2 }}>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Portfolio
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={() => handleOpenDrawer({})}
                disabled={!userData?.account_locked ? false : true}
              >
                Add Portfolio Item
              </Button>
              <Tooltip 
                  arrow 
                  title='Share your portfolio.'
              >  
                <IconButton aria-label="share" color="primary" size='large' onClick={() => setOpenShareModal(true)}>
                    <ShareIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ width: '100%' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <PortfolioItemList
                  type={'student'}
                  student={userData}
                  portfolioItemList={portfolioData}
                  setPortfolioItemList={setPortfolioData}
                  setReloadList={setReloadList}
                  handleOpenDrawer={handleOpenDrawer}
                  setError={setOpenError}
                  setErrorText={setOpenErrorText}
                />
              </Suspense>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={openError}
        onClose={handleCloseError}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {openErrorText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        onClose={handleCloseSuccess}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%', maxWidth: '500px' }}>
          Success
        </Alert>
      </Snackbar>
      <Snackbar
        open={openCopied}
        onClose={handleCloseCopied}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseCopied} severity="success" sx={{ width: '100%', maxWidth: '500px' }}>
          Copied To Clipboard
        </Alert>
      </Snackbar>
      <Drawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <PortfolioItemView
            type={'student'}
            student={userData}
            selectedPortfolioItem={selectedPortfolioItem}
            handleCloseDrawer={handleCloseDrawer}
            setReloadList={setReloadList}
            setError={setOpenError}
            setErrorText={setOpenErrorText}
          />
      </Drawer>
      <Modal
          open={openShareModal}
          onClose={handleCloseShareModal}
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography textAlign={'center'} color='secondary' variant='h6' component='div'>
                  Here is your public portfolio link!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  fullWidth
                  name="url"
                  id="url"
                  label="Url"
                  type="text"
                  value={appUrl + '/public/portfolio/' + userData?.id}
                  onClick={() => handleCopyToClipboard(appUrl + '/public/portfolio/' + userData?.id)}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleCopyToClipboard(appUrl + '/public/portfolio/' + userData?.id)}
                          edge="end"
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Portfolio;