import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { userAccount, checkType } from '../../../services/userService';
import { SuggestionsView } from '../../../components/global/suggestions/suggestionsView';
import { getFavorites } from '../../../services/favoriteService';
import { getOccupation } from '../../../services/employmentService';
import { getInstitution } from '../../../services/educationService';
import { ListItemFavorite } from '../../../components/global/favorites/listItemFavorite';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`
    };
}

const drawerStyle = {
    width: '80%',
    '@media (max-width: 800px)': {
        width: '100%'
    }
}

const Favorites = () => {
    const [user, setUser] = React.useState({});
    const [institutionFavorites, setInstitutionFavorites] = React.useState([]);
    const [occupationFavorites, setOccupationFavorites] = React.useState([]);
    const [educationData, setEducationData] = React.useState({});
    const [occupationData, setOccupationData] = React.useState({});
    const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
    const [specificType, setSpecificType] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [reloadPage, setReloadPage] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const handleCloseError = () => setError(false);
  
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
  
    if (!localStorage.getItem('token')) {
        window.location.href = '/login';
    }
  
    const handleOpenSuggestionsDrawer = () => {
        setOpenSuggestionsDrawer(true);
    };
  
    const handleCloseDrawer = () => {
        setOpenSuggestionsDrawer(false);
        setSelectedSuggestion({});
        setSpecificType(false);
    }

    React.useEffect(() => {
      if (reloadPage) {
        window.location.reload();
      }
    }, [reloadPage]);

    React.useEffect(() => {
      checkType(localStorage.getItem('token')).then(requestData => {
        if (requestData.status == true) {
          if (requestData.data?.type === 2) {
            window.location.href = '/educator/dashboard';
          } else if (requestData.data?.type === 3) {
            window.location.href = '/parents/dashboard';
          }
        }
      });
      userAccount(localStorage.getItem('token')).then(userData => {
        if (userData.status == true) {
            if (userData.data?.account_locked || userData.data?.account_soft_locked) {
                window.location.href = '/students/dashboard';
            }
            setUser(userData.data);
            getFavorites(localStorage.getItem('token'), 1).then(favorites => {
                if (favorites.status == true) {
                    setInstitutionFavorites(favorites.data);
                } else {
                  setError(true);
                  setErrorText(favorites.message);
                }
            }).catch(err => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            });
            getFavorites(localStorage.getItem('token'), 2).then(favorites => {
                if (favorites.status == true) {
                    setOccupationFavorites(favorites.data);
                } else {
                  setError(true);
                  setErrorText(favorites.message);
                }
            }).catch(err => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            });
            getInstitution(localStorage.getItem('token'), userData.data.investments[0].id).then(educationData => {
              if (educationData.status == true) {
                setEducationData(educationData.data);
              } else {
                setError(true);
                setErrorText(educationData.message);
              }
            }).catch(err => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            });
            getOccupation(localStorage.getItem('token'), userData.data.investments[0].id).then(occupationData => {
              if (occupationData.status == true) {
                setOccupationData(occupationData.data);
              } else {
                setError(true);
                setErrorText(occupationData.message);
              }
            }).catch(err => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            });
        } else {
          setError(true);
          setErrorText(userData.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }, []);

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item sm={12} md={6}>
                    <Typography color='secondary' variant='h4' component='div'>
                        Your Favorites
                    </Typography>
                </Grid>
                <Grid item sm={12} md={6} align='right'>
                    <Button
                        variant='contained'
                        disableElevation
                        size='large'
                        onClick={handleOpenSuggestionsDrawer}
                        disabled={!user?.account_locked ? false : true}
                    >
                        Compare Others
                    </Button>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="School/Institution" {...a11yProps(0)} sx={{width:'50%', maxWidth:'50%'}} />
                    <Tab label="Occupation" {...a11yProps(1)} sx={{width:'50%', maxWidth:'50%'}} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <Grid container alignItems={"top"} justifyContent={"top"}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper sx={{p:2}}>
                            <Typography color='primary' variant='h6' component='div' sx={{fontWeight:'bold'}}>
                                Selected Institution
                            </Typography>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                            <Table size='small'>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>School Name</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>{educationData.college_name}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Yearly Tuition</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>${educationData.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Average Net Price</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>${educationData.average_net_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Average In State Tuition</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>${educationData.in_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Average Out Of-State Tuition</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>${educationData.out_of_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Graduation Rate</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>${educationData.graduation_rate}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Total Students Admitted</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>
                                                {educationData.total_admitted ? (
                                                <>
                                                    {educationData.total_admitted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </>
                                                ) : (
                                                <>
                                                    N/A
                                                </>
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Total Students Enrolled</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>
                                                {educationData.total_enrolled ? (
                                                <>
                                                    {educationData.total_enrolled?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </>
                                                ) : (
                                                <>
                                                    N/A
                                                </>
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Avg Earnings After Graduation</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>${educationData.median_earnings_six_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {!user?.account_locked && !user?.account_soft_locked && (
                                <Link 
                                color="primary" 
                                href="/students/education" 
                                align="left" 
                                sx={{ mt: 3, display: 'block' }}
                                >
                                See More Information
                                </Link>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{backgroundColor:'#fff', border: '1px solid #E5E5E5'}}>
                        {institutionFavorites?.length > 0 ? (
                            <>
                                <Typography 
                                    variant='h6' 
                                    component='div' 
                                    sx={{
                                        pl:2,
                                        pt:2,
                                        pb:1
                                    }}
                                >
                                    Your Favorites
                                </Typography>
                                {institutionFavorites?.map((favorite, index) => (
                                    <ListItemFavorite
                                        student={user}
                                        type={'student'}
                                        setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                                        setSelectedSuggestion={setSelectedSuggestion}
                                        setSpecificType={setSpecificType}
                                        currentFavorites={institutionFavorites}
                                        setCurrentFavorites={setInstitutionFavorites}
                                        favorite={favorite}
                                        searchType={1}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                        index={index}
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', p:2 }}>
                                    You have no favorited institutions<br />at this time.
                                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                                    <Button
                                        variant='contained'
                                        disableElevation
                                        color='primary'
                                        size='large'
                                        href='/students/suggestions'
                                        sx={{margin:'10px auto'}}
                                    >
                                        Discover Alternatives
                                    </Button>
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <Grid container alignItems={"top"} justifyContent={"top"}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper sx={{p:2}}>
                            <Typography color='primary' variant='h6' component='div' sx={{fontWeight:'bold'}}>
                                Selected Occupation
                            </Typography>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                            <Table size='small'>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Occupation Title</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.occupation_title}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Current Estimated Number of Jobs</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Projected Number of Jobs (10 Years)</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Projected Number of Openings</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_change?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold'>Projected Annual Wage</Typography>
                                        </TableCell>
                                        <TableCell align='right' sx={{pr:0}}>
                                            <Typography fontWeight='bold' color='primary'>${
                                                Number(user?.investments?.[0]?.custom_wage ?? 0) > 0
                                                ? Number(user?.investments?.[0]?.custom_wage ?? 0).toLocaleString()
                                                : Number(occupationData?.occupation?.median_annual_wage ?? 0).toLocaleString() ?? "N/A"
                                            }</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {!user?.account_locked && !user?.account_soft_locked && (
                                <Link 
                                color="primary" 
                                href="/students/occupation" 
                                align="left" 
                                sx={{ mt: 3, display: 'block' }}
                                >
                                See More Information
                                </Link>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{backgroundColor:'#fff', border: '1px solid #E5E5E5'}}>
                        {occupationFavorites?.length > 0 ? (
                            <>
                                <Typography 
                                    variant='h6' 
                                    component='div' 
                                    sx={{
                                        pl:2,
                                        pt:2,
                                        pb:1
                                    }}
                                >
                                    Your Favorites
                                </Typography>
                                {occupationFavorites?.map((favorite, index) => (
                                    <ListItemFavorite
                                        student={user}
                                        type={'student'}
                                        setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                                        setSelectedSuggestion={setSelectedSuggestion}
                                        setSpecificType={setSpecificType}
                                        currentFavorites={occupationFavorites}
                                        setCurrentFavorites={setOccupationFavorites}
                                        favorite={favorite}
                                        searchType={2}
                                        setError={setError}
                                        setErrorText={setErrorText}
                                        index={index}
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', p:2 }}>
                                    You have no favorited institutions<br />at this time.
                                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                                    <Button
                                        variant='contained'
                                        disableElevation
                                        color='primary'
                                        size='large'
                                        href='/students/suggestions'
                                        sx={{margin:'10px auto'}}
                                    >
                                        Discover Alternatives
                                    </Button>
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
            </CustomTabPanel>
            <Drawer
                open={openSuggestionsDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <SuggestionsView
                    type={'student'}
                    selectedSuggestion={selectedSuggestion}
                    specificType={specificType}
                    student={user}
                    setReloadList={setReloadPage}
                    handleCloseDrawer={handleCloseDrawer}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Drawer>
            <Snackbar
                open={error}
                onClose={handleCloseError}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
                {errorText}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Favorites;