import React, { PureComponent } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import './explore.scss';
import { studentDisclaimer } from '../../../components/global/studentDisclaimer';
import { parentAccount, parentInvestmentCalculator, checkType } from '../../../services/userService';
import { getStates } from '../../../services/generalService';
import { getParentOccupation } from '../../../services/employmentService';
import { getParentInstitution } from '../../../services/educationService';
import { getSponsoredLoans } from '../../../services/advertisementsService';
import { IncomeModal } from '../../../components/global/incomeModal';
import { SuggestionsView } from '../../../components/global/suggestions/suggestionsView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const centerStyle = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center'
}

const drawerStyle = {
    width: '80%',
    '@media (max-width: 800px)': {
        width: '100%'
    }
}

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
        {value}
      </text>
    );
  }
}

const Explore = () => {
  const [userType, setUserType] = React.useState(1);
  const [userData, setUserData] = React.useState({});
  const [state, setState] = React.useState(null);
  const [educationData, setEducationData] = React.useState([]);
  const [occupationData, setOccupationData] = React.useState([]);
  const [dashboardData, setDashboardData] = React.useState([]);
  const [loanAmount, setLoanAmount] = React.useState(0);
  const [sponsoredLoans, setSponsoredLoans] = React.useState([]);
  const [investmentId, setInvestmentId] = React.useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
  const [specificType, setSpecificType] = React.useState(false);
  const [openChangeIncomeModal, setOpenChangeIncomeModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [reloadPage, setReloadPage] = React.useState(false);
  const handleOpenChangeIncomeModal = () => setOpenChangeIncomeModal(true);
  const handleCloseChangeIncomeModal = () => setOpenChangeIncomeModal(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleOpenSuggestionsDrawer = () => {
      setOpenSuggestionsDrawer(true);
  };

  const handleOpenOccupationSuggestionsDrawer = () => {
      setOpenSuggestionsDrawer(true);
      setSpecificType('occupation');
  };

  const handleOpenEducationSuggestionsDrawer = () => {
      setOpenSuggestionsDrawer(true);
      setSpecificType('education');
  };

  const handleCloseDrawer = () => {
      setOpenSuggestionsDrawer(false);
      setSelectedSuggestion({}); 
      setSpecificType(false);
  }

  React.useEffect(() => {
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
      setUserType(requestData.data?.type);
    });
    parentAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setUserData(userData.data);
        setInvestmentId(userData.data.investments[0].id);
        getStates().then(states => {
          if (states.status == true) {
            if (userData.data.investments[0].state !== null) {
              setState(states.data?.find(region => region.id === userData.data.investments[0].state));
            }
          }
        });

        parentInvestmentCalculator(localStorage.getItem('token'), userData.data.investments[0].id).then(investmentData => {
          if (investmentData.status == true) {
            setDashboardData(investmentData.data);
            setLoanAmount(Number(investmentData.data.payoff_breakdown[0]?.la));
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(investmentData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getParentInstitution(localStorage.getItem('token'), userData.data.investments[0].id).then(educationData => {
          if (educationData.status == true) {
            setEducationData(educationData.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(educationData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getParentOccupation(localStorage.getItem('token'), userData.data.investments[0].id).then(occupationData => {
          if (occupationData.status == true) {
            setOccupationData(occupationData.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(occupationData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getSponsoredLoans(localStorage.getItem('token')).then(sponsoredLoansData => {
          if (sponsoredLoansData.status == true) {
            setSponsoredLoans(sponsoredLoansData.data);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(sponsoredLoansData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <>
        <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mb: 2 }}>
          <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                  Dashboard
              </Typography>
          </Grid>
          <Grid item sm={12} md={6} align='right'>
              <Button
                  variant='contained'
                  disableElevation
                  size='medium'
                  onClick={handleOpenSuggestionsDrawer}
                  disabled={!userData?.account_soft_locked ? false : true}
              >
                  Compare With Others
              </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Paper variant='outlined' sx={{p:2}}>
              <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    variant="p"
                    component="p"
                    color='secondary'
                    sx={{
                      fontSize:'12px',
                      fontWeight: 'bold',
                      mb:1
                    }}
                  >
                    Your Chosen Institution
                  </Typography>
                  <Icon color="secondary" sx={{mb: '-5px', mr: '5px'}}>
                    <SchoolIcon />
                  </Icon>
                  <Typography
                    variant="p"
                    component="p"
                    color='secondary'
                    sx={{
                      display:'inline-block',
                      fontSize:'20px'
                    }}
                  >
                    {educationData?.college_name?.length > 50 
                    ? `${educationData?.college_name?.slice(0, 50)}...` 
                    : educationData?.college_name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display={{xs:'block', sm:'flex'}} justifyContent="flex-end">
                    <Button 
                      variant="outlined" 
                      size="small" 
                      color="secondary" 
                      sx={{
                        mr:{sm: 1},
                        mb: {xs: 1, sm: 'auto'},
                        width:{xs: '100%', sm: 'auto'},
                        display: {xs: 'block'}
                      }}
                      onClick={handleOpenEducationSuggestionsDrawer}
                      disabled={!userData?.account_soft_locked ? false : true}
                    >
                      Compare
                    </Button>
                    <Button 
                      onClick={() => window.location.assign("/parents/education")} 
                      variant="outlined" 
                      size="small"
                      color="primary"
                      disabled={!userData?.account_soft_locked ? false : true}
                      sx={{
                        width:{xs: '100%', sm: 'auto'},
                        display: {xs: 'block'}
                      }}
                    >
                      See More
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Paper variant='outlined' sx={{p:2}}>
              <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    variant="p"
                    component="p"
                    color='secondary'
                    sx={{
                      fontSize:'12px',
                      fontWeight: 'bold',
                      mb:1
                    }}
                  >
                    Your Chosen Occupation
                  </Typography>
                  <Icon color="secondary" sx={{mb: '-5px', mr: '5px'}}>
                    <WorkIcon />
                  </Icon>
                  <Typography
                    variant="p"
                    component="p"
                    color='secondary'
                    sx={{
                      display:'inline-block',
                      fontSize:'20px'
                    }}
                  >
                    {occupationData?.occupation?.occupation_title?.length > 50 
                    ? `${occupationData?.occupation.occupation_title?.slice(0, 50)}...` 
                    : occupationData?.occupation?.occupation_title}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display={{xs:'block', sm:'flex'}} justifyContent="flex-end">
                    <Button 
                      variant="outlined" 
                      size="small" 
                      color="secondary" 
                      sx={{
                        mr:{sm: 1},
                        mb: {xs: 1, sm: 'auto'},
                        width:{xs: '100%', sm: 'auto'},
                        display: {xs: 'block'}
                      }}
                      onClick={handleOpenOccupationSuggestionsDrawer}
                      disabled={!userData?.account_soft_locked ? false : true}
                    >
                      Compare
                    </Button>
                    <Button 
                      onClick={() => window.location.assign("/parents/occupation")} 
                      variant="outlined" 
                      size="small"
                      color="primary"
                      disabled={!userData?.account_soft_locked ? false : true}
                      sx={{
                        width:{xs: '100%', sm: 'auto'},
                        display: {xs: 'block'}
                      }}
                    >
                      See More
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            sx={{
              order: {
                xs: 3,
                xl: 1
              },
            }}
          >
            <Paper sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 355,
            }}>
              <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                  data={dashboardData.payoff_breakdown}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='year' />
                  <YAxis domain={[0,Math.round(loanAmount/10000)*10000]} />
                  <Legend />
                  <Line type='monotone' dataKey='la' name='Loan Principal Amount' stroke='#fa7918' label={<CustomizedLabel />} />
                  <Line type='monotone' dataKey='pi' name='Projected Income' stroke='#777777' label={<CustomizedLabel />} />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            sx={{
              order: {
                xs: 1,
                xl: 2
              },
            }}
          >
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 250,
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography component='div' fontWeight='bold' color='secondary'>
                YOUR INVESTMENT RATING
              </Typography>
              <Typography color='primary' variant='h1' component='div'  sx={{ mt: 2, mb: 2 }} fontSize='120px'>
                {dashboardData.rating}
              </Typography>
              <Tooltip enterTouchDelay={0} title='This rating is calculated based on the average amount of money you are going to make in your industry versus the loan amount to payoff. Click for more information on how our calculations work.' placement='bottom' arrow>
                <Button
                  href="https://educationadvisor.org/how-it-works/"
                  variant='text'
                  color='secondary'
                  size='small'
                >
                  How Is This Rating Calculated?
                </Button>
              </Tooltip>
            </Paper>
            <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{ mt: 1, mb: 1 }}>
              <Grid item md={12} lg={6} sx={{width:'50%'}}>
                <Button
                  variant='contained'
                  fullWidth
                  disableElevation
                  endIcon={<EditIcon />}
                  href="/parents/loans"
                  disabled={userData?.account_locked || userData?.account_soft_locked ? true : false}
                >
                  Loans
                </Button>
              </Grid>
              <Grid item md={12} lg={6} sx={{width:'50%'}}>
                <Button
                  variant='contained'
                  fullWidth
                  disableElevation
                  endIcon={<EditIcon />}
                  href="/parents/scholarships"
                  disabled={userData?.account_locked || userData?.account_soft_locked ? true : false}
                >
                  Scholarships
                </Button>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
              <Button
                variant='contained'
                fullWidth
                disableElevation
                color='secondary'
                endIcon={<EditIcon />}
                onClick={handleOpenChangeIncomeModal}
                disabled={userData?.account_locked || userData?.account_soft_locked ? true : false}
              >
                Income
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            sx={{
              order: {
                xs: 2,
                xl: 3
              },
            }}
          >
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 353,
                justifyContent: 'center',
                overflowY: 'none'
              }}
            >
              <Typography 
                  variant='h6' 
                  component='div'
                  color='secondary'
                  sx={{
                      textAlign:'left',
                      mb: 1
                  }}
              >
                  Chart Breakdown
                  {state?.name !== educationData?.state ? (
                    <>
                    <Tooltip enterTouchDelay={0} title='We assume you will pay out of state tuition since this institution is either not in your state of residence or you have not provided us your state of residence.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                        <InfoIcon sx={{fontSize: 'medium', ml: 1, mb: '-2px'}} />
                    </Tooltip>
                    </>
                  ) : (
                    <>
                    {educationData?.in_state_tuition ? (
                      <>
                      <Tooltip enterTouchDelay={0} title='Since this institution is in your state of residence we assume you will pay in state tuition.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                        <InfoIcon sx={{fontSize: 'medium', ml: 1, mb: '-2px'}} />
                      </Tooltip>
                      </>
                    ) : (
                      <>
                      <Tooltip enterTouchDelay={0} title='We assume you will pay out of state tuition since this institution is either not in your state of residence or you have not provided us your state of residence.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                        <InfoIcon sx={{fontSize: 'medium', ml: 1, mb: '-2px'}} />
                      </Tooltip>
                      </>
                    )}
                    </>
                  )}
              </Typography>
              <Divider variant="fullWidth" sx={{ mb: 2 }} />
              <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip enterTouchDelay={0} title='The approximate monthly payment to pay off your schooling debt.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                            <Typography component='div' fontWeight='bold' color='secondary'>
                                Monthly Payment <InfoIcon sx={{fontSize: 'small'}} />
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Typography color='primary' component='div' fontWeight='bold'>
                        {dashboardData.approximate_monthly_payments?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip enterTouchDelay={0} title='The total number of years you will be paying off loans. If you have multiple loans some of those loans may be paid off before the end of this time frame.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                            <Typography component='div' fontWeight='bold' color='secondary'>
                                Payoff Years <InfoIcon sx={{fontSize: 'small'}} />
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Typography color='primary' component='div' fontWeight='bold'>
                        {dashboardData.total_payoff_years} Years
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip enterTouchDelay={0} title='The total amount you will pay off including interest.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                            <Typography component='div' fontWeight='bold' color='secondary'>
                                Total Payment <InfoIcon sx={{fontSize: 'small'}} />
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Typography color='primary' component='div' fontWeight='bold'>
                        ${dashboardData.total_payment?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip enterTouchDelay={0} title='The total amount of interest you will be paying off.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                            <Typography component='div' fontWeight='bold' color='secondary'>
                                Interest <InfoIcon sx={{fontSize: 'small'}} />
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Typography color='primary' component='div' fontWeight='bold'>
                        ${dashboardData.total_interest?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip enterTouchDelay={0} title='The percentage of your paycheck going towards paying off your student loans. This assumes a bi-weekly paycheck before taxes are taken out.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                            <Typography component='div' fontWeight='bold' color='secondary'>
                                % of Paycheck <InfoIcon sx={{fontSize: 'small'}} />
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Typography color='primary' component='div' fontWeight='bold'>
                        {dashboardData.approximate_percentage_of_paycheck}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item sm={12} lg={6} sx={{maxWidth:'100%'}}>
            <Paper sx={centerStyle}>
              <Typography component='div' variant='h5' fontWeight='bold' color='secondary'>
                Income Information
              </Typography>
              <Grid sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Table size='small'>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Occupation Title</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.occupation_title}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Current Estimated Number of Jobs</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Projected Number of Jobs (10 Years)</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Projected Number of Openings</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_change?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Projected Annual Wage</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>${
                          Number(userData?.investments?.[0]?.custom_wage ?? 0) > 0
                          ? Number(userData?.investments?.[0]?.custom_wage ?? 0).toLocaleString()
                          : Number(occupationData?.occupation?.median_annual_wage ?? 0).toLocaleString() ?? "N/A"
                        }</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {!userData?.account_locked && !userData?.account_soft_locked && (
                  <Link 
                    color="primary" 
                    href="/parents/occupation" 
                    align="left" 
                    sx={{ mt: 3, display: 'block' }}
                  >
                    See More Information
                  </Link>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={12} lg={6} sx={{maxWidth:'100%'}}>
            <Paper sx={centerStyle}>
              <Typography component='div' variant='h5' fontWeight='bold' color='secondary'>
                Education Information
              </Typography>
              <Grid sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Table size='small'>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>School Name</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>{educationData.college_name}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Yearly Tuition</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>
                          {state?.name !== educationData?.state ? (
                            <>
                              {educationData.average_cost_of_attendance ? (
                                <>
                                ${educationData.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (Out of State)
                                </>
                              ) : (
                                <>Not Found</>
                              )}
                            </>
                          ) : (
                            <>
                            {educationData?.in_state_tuition ? (
                              <>
                                {educationData.in_state_tuition ? (
                                  <>
                                    ${educationData.in_state_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (In State)
                                  </>
                                ) : (
                                  <>Not Found</>
                                )}
                              </>
                            ) : (
                              <>
                                {educationData.average_cost_of_attendance ? (
                                  <>
                                    ${educationData.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (In State)
                                  </>
                                ) : (
                                  <>Not Found</>
                                )}
                              </>
                            )}
                            </>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Total Students Admitted</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>
                          {educationData.total_admitted ? (
                            <>
                              {educationData.total_admitted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </>
                          ) : (
                            <>
                              Not Found
                            </>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Total Students Enrolled</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>
                          {educationData.total_enrolled ? (
                            <>
                              {educationData.total_enrolled?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </>
                          ) : (
                            <>
                              Not Found
                            </>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight='bold'>Avg Earnings After Graduation</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography fontWeight='bold' color='primary'>
                          {educationData.median_earnings_six_years_after_entry ? (
                            <>
                              ${educationData.median_earnings_six_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </>
                          ) : (
                            <>
                              Not Found
                            </>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {!userData?.account_locked && !userData?.account_soft_locked && (
                  <Link 
                    color="primary" 
                    href="/parents/education" 
                    align="left" 
                    sx={{ mt: 3, display: 'block' }}
                  >
                    See More Information
                  </Link>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {sponsoredLoans?.length > 0 &&
          <Tooltip enterTouchDelay={0} title='We have compiled a list of loan offers that may help you refinance or acquire a loan to kick start your education!' placement='bottom' arrow>
            <Typography component='div' variant='h5' fontWeight='bold' color='secondary' sx={{ mt: 4, mb: 4, textAlign: 'center', justifyContent: 'center' }}>
              Loan Options <InfoIcon sx={{fontSize: 'medium'}} />
            </Typography>
          </Tooltip>
        }
        {sponsoredLoans?.length > 0 &&
          <Grid container spacing={3} sx={{ textAlign: 'center', justifyContent: 'center' }}>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 1
              </Paper>
            </Grid>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 2
              </Paper>
            </Grid>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 3
              </Paper>
            </Grid>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 4
              </Paper>
            </Grid>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 5
              </Paper>
            </Grid>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 6
              </Paper>
            </Grid>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 7
              </Paper>
            </Grid>
            <Grid item xm={12} sm={6} md={4} lg={3}>
              <Paper sx={centerStyle}>
                Loan 8
              </Paper>
            </Grid>
          </Grid>
        }
        {studentDisclaimer}
        <Modal
          open={openChangeIncomeModal}
          onClose={handleCloseChangeIncomeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {<IncomeModal userType={userType} />}
          </Box>
        </Modal>
        <Modal
          open={openErrorModal}
          onClose={handleCloseErrorModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {openErrorModalText}
            </Typography>
          </Box>
        </Modal>
        <Drawer
            open={openSuggestionsDrawer}
            onClose={handleCloseDrawer}
            anchor='right'
            sx={{zIndex: 9999}}
            PaperProps={{
                sx: drawerStyle,
            }}
        >
            <DialogTitle 
                disableTypography
                sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    padding: '0 !important'
                }}
            >
                <IconButton onClick={handleCloseDrawer}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <SuggestionsView
                type={'parent'}
                selectedSuggestion={selectedSuggestion}
                specificType={specificType}
                student={userData}
                setReloadList={setReloadPage}
                handleCloseDrawer={handleCloseDrawer}
                setError={setOpenErrorModal}
                setErrorText={setOpenErrorModalText}
            />
        </Drawer>
      </>
    </Container>
  );
};

export default Explore;
