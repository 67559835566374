import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { getStudentsByEmployee, getStudents, assignStudents } from '../../../../../services/educationActionsService';
import { getEmployees } from '../../../../../services/educationActionsService';
import { StudentAdd } from '../../../students/components/studentAdd';

export function StudentsComponent({parent, educator, selectedEducator, parentStudents}) {
    const [studentList, setStudentList] = React.useState([]);
    const [allStudentList, setAllStudentList] = React.useState([]);
    const [employees, setEmployees] = React.useState(false);
    const [openAssignModal, setOpenAssignModal] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [checkedStudents, setCheckedStudents] = React.useState([]);
    const handleCloseAssignModal = () => setOpenAssignModal(false);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const handleCloseAddModal = () => setOpenAddModal(false);

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 400,
      minWidth: 300,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      textAlign:'center'
    };

    React.useEffect(() => {
        let filteredStudents = [];
        if (selectedEducator?.students && parentStudents) {
            const educatorStudents = selectedEducator.students;
            filteredStudents = parentStudents.filter(parentStudent =>
                educatorStudents.some(eduStudent => eduStudent.id === parentStudent.id)
            );

            setStudentList(filteredStudents);
        }

        if (educator?.user_level === 1 || educator?.user_level === 2) {
            getStudents(localStorage.getItem('token')).then(studentsData => {
                if (studentsData.status == true) {
                    const finalizedFilteredStudents = studentsData.data?.filter((student) => !filteredStudents.some((p) => p.id === student.id));
                    
                    let finalizedAllStudentList = [];
                    for (let index = 0; index < finalizedFilteredStudents.length; index++) {
                        if (finalizedFilteredStudents[index].pending === 0 && finalizedFilteredStudents[index].account_locked === 0 && finalizedFilteredStudents[index].active === 1) {
                            finalizedAllStudentList.push(finalizedFilteredStudents[index])
                        }
                    }
                    setAllStudentList(finalizedAllStudentList);
                }
            });
            getEmployees(localStorage.getItem('token')).then(employees => {
              if (employees.status == true) {
                setEmployees(employees.data);
              }
            })
        } else {
            getStudentsByEmployee(localStorage.getItem('token'), educator.id).then(studentsData => {
            if (studentsData.status == true) {
                setAllStudentList(studentsData.data?.filter((student) => !filteredStudents.some((p) => p.id === student.id)));
            }
            });
        }
    }, [selectedEducator, parentStudents]);

    const viewStudent = (id) => {
        window.location.href = '/educator/students?student_id=' + id;
    };

    const handleStudentChange = (event) => {
      const {
        target: { value },
      } = event;
      setCheckedStudents(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleSelectRender = (selected) => {
        let selectedNames = [];
        selected.forEach(id => {
            allStudentList?.forEach(student => {
                if (id === student.id) {
                    selectedNames.push(student.first_name + ' ' + student.last_name);
                }
            });
        });
        return selectedNames.join(', ');
    }

    const getStudentName = (student) => {
        return student.first_name + ' ' + student.last_name;
    }

    const handleAddStudents = (event) => {
        let controlledStudents = '[' + [...new Set(checkedStudents)].toString() + ']';
        let data = new URLSearchParams({
            'students':controlledStudents,
        });
    
        assignStudents(localStorage.getItem('token'), parent.id, data).then(update => {
            if (update?.status == true) {
                setCheckedStudents([]);
                handleCloseAssignModal();
                window.location.href = '/educator/parents';
            } else {
                setErrorText(update.message);
            }
        });
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={8}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Parent Students
                    </Typography>
                </Grid>
                <Grid item xs={4} align='right'>
                    <Button
                        variant='contained'
                        disableElevation
                        size='small'
                        sx={{mr: 1}}
                        onClick={() => setOpenAssignModal(true)}
                        disabled={educator?.active || educator?.account_locked ? false : true}
                    >
                        Assign Student
                    </Button>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid 
                container 
                alignItems="center" 
                justifyContent="center" 
                spacing={3}
            >
                <Grid item xs={12}>
                    {studentList?.length > 0 ? (
                        <>
                            {studentList?.map((student, index) => (
                                <Box sx={{ width: '100%' }} key={index} >
                                    <Grid 
                                        container 
                                        spacing={2}
                                        alignItems="center" 
                                        justifyContent="center" 
                                    >
                                        <Grid item xs={12} sm={8}>
                                            {!student?.first_name && !student?.last_name ? (
                                                <Typography color='secondary' variant='h6'>{student?.email}</Typography>
                                            ) : (
                                                <>
                                                    <Typography color='secondary' variant='h6'>{student?.first_name} {student?.last_name}</Typography>
                                                    <Typography color='secondary' sx={{mb:1}}>{student?.email}</Typography>
                                                </>
                                            )}
                                            {student?.pending === 2 ? (
                                                <Chip size='small' color='primary' sx={{color:'#fff', mr:1}} label='Pending Educator Approval' />
                                            ) : (
                                                <>
                                                    {student?.active === 0 && (
                                                        <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                                    )} 
                                                    {student?.active === 1 && (
                                                        <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                                    )}
                                                    {student?.pending === 1 && (
                                                        <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Student Approval' />
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={4} align='right' alignItems="center" justifyContent="center" sx={{pr:1}}>
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                size="small"
                                                disabled={!educator?.account_locked ? false : true}
                                                onClick={() => { viewStudent(student?.id) }}
                                            >
                                                View
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {index !== studentList?.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                                </Box>
                            ))}
                        </>
                    ) : (
                        <>
                            <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                                No Students Found.
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
            <Modal
                open={openAssignModal}
                onClose={handleCloseAssignModal}
            >
                <Box sx={style}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                                Assign Students
                            </Typography>
                            {errorText && (
                                <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                                    {errorText}
                                </Typography>
                            )}
                        </Grid>
                        {allStudentList?.length > 0 ? (
                            <>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width:'100%', textAlign: 'left' }}>
                                        <InputLabel id="student-label">Assign A Student</InputLabel>
                                        <Select
                                            labelId="student-label"
                                            id="student-checkbox"
                                            multiple
                                            required
                                            fullWidth
                                            value={checkedStudents}
                                            onChange={handleStudentChange}
                                            input={<OutlinedInput label="Assign A Student" />}
                                            renderValue={(selected) => handleSelectRender(selected)}
                                        >
                                            {allStudentList?.map((student) => (
                                                <MenuItem key={student.id} value={student.id} disabled={educator.active && !educator.account_locked ? false : true}>
                                                    <Checkbox checked={checkedStudents.indexOf(student.id) > -1} />
                                                    <ListItemText primary={getStudentName(student)} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disableElevation
                                        size="large"
                                        fullWidth
                                        onClick={handleAddStudents}
                                        disabled={checkedStudents.length !== 0 ? false : true}
                                    >
                                        Assign Student(s)
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Typography textAlign={'center'} variant='h6' component='div'>
                                    No students found, please add a new parent to assign them.
                                </Typography>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='large'
                                    fullWidth
                                    sx={{mt:1}}
                                    onClick={() => setOpenAddModal(true)}
                                    disabled={educator?.active || educator?.account_locked ? false : true}
                                >
                                    Invite A Student
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openAddModal}
                onClose={handleCloseAddModal}
            >
                <>
                <StudentAdd
                    educator={educator}
                    employees={employees}
                    handleCloseAddModal={handleCloseAddModal}
                />
                </>
            </Modal>
        </>
    )
}