import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getInvoices } from '../../../../services/educatorBillingService';
import { educatorAccount, checkType } from '../../../../services/userService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

const InvoicesOverview = () => {
    const [invoices, setInvoices] = React.useState([]);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);

    if (!localStorage.getItem('token')) {
        window.location.href = '/login';
    }

    React.useEffect(() => {
        checkType(localStorage.getItem('token')).then(requestData => {
          if (requestData.status == true) {
            if (requestData.data?.type === 1) {
              window.location.href = '/student/dashboard';
            } else if (requestData.data?.type === 3) {
                window.location.href = '/parents/dashboard';
              }
          }
        });
        educatorAccount(localStorage.getItem('token')).then(userData => {
          if (userData.status == true) {
            if (userData.data?.user_level !== 1 || userData.data?.account_locked) {
              window.location.href = '/educator/dashboard';
            }
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(userData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getInvoices(localStorage.getItem('token')).then(invoices => {
            if (invoices.status == true) {
                setInvoices(invoices.data);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(invoices.errors);
            }
        });
    }, []);

    const convertDate = (invoiceDate) => {
        let date = new Date(invoiceDate * 1000);
        return date.toLocaleString("default", { month: "long" })
                + ' ' +
                date.toLocaleString("default", { day: "numeric" })
                + ', ' +
                date.toLocaleString("default", { year: "numeric" });
    }

    const handleDownloadInvoice = (e, file) => {
        window.location.href = file;
    }

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
            <Typography color='secondary' variant='h4' component='div'>
                Your Invoices
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Paper sx={{ p: 2 }}>
                <Box sx={{ width: '100%' }}>
                    {invoices?.length > 0 ? (
                        <Grid container>
                            {invoices?.map((invoice, index) => (
                                <Container 
                                    key={index} 
                                    maxWidth={false} 
                                    disableGutters={true} 
                                    sx={[
                                        {'&:hover': {boxShadow:'1px 1px #E5E5E5'}},
                                        {p: 1, mt:1, mb:1, border:'1px solid #E5E5E5', borderRadius:1}
                                    ]}
                                >
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={10}>
                                            <Typography variant='h6' color="secondary" component='div' sx={{mb: 1}}>
                                            Invoice <b>#{invoice.number}</b>
                                            </Typography>
                                            <Typography color='secondary' component={'span'}>
                                            ${invoice?.total ? invoice?.total?.toString().substring(0, (invoice?.total?.toString().length) - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} - {convertDate(invoice.created)}
                                            &nbsp;<Chip 
                                                    label={invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                                                    color={invoice.status === 'paid' ? 'primary' : 'secondary'}
                                                    size="small" 
                                                    sx={{
                                                        color:'white',
                                                        fontSize: '1'
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} align='right'>
                                            <IconButton aria-label="edit" color="secondary" onClick={e => handleDownloadInvoice(e, invoice.invoice_pdf)}>
                                                <FileDownloadIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Container>
                            ))}
                        </Grid>
                    ) : (
                        <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                        You have no invoices at this time.
                        </Typography>
                    )}
                </Box>
            </Paper>
            <Modal
            open={openErrorModal}
            onClose={handleCloseErrorModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                {openErrorModalText}
                </Typography>
            </Box>
            </Modal>
        </Container>
    )
}

export default InvoicesOverview;