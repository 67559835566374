import * as React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import './navigation.scss';

const pages = [
  {
    id: 'solutions', 
    url: '#', 
    name: 'Solutions', 
    variant: 'text', 
    color:'secondary',
    subpages: [
      {
        id: 'students',
        url: 'https://educationadvisor.org/solutions-for-students/',
        name: 'Students'
      },
      {
        id: 'educators',
        url: 'https://educationadvisor.org/solutions-for-educators/',
        name: 'Educators'
      },
      {
        id: 'businesses',
        url: 'https://educationadvisor.org/solutions-for-businesses/',
        name: 'Businesses'
      },
    ]
  },
  {
    id: 'resources', 
    url: 'https://educationadvisor.org/education-center/', 
    name: 'Resources', 
    variant: 'text', 
    color:'secondary',
    subpages: [
      {
        id: 'howItWorks',
        url: 'https://educationadvisor.org/how-it-works/',
        name: 'How It Works'
      },
      {
        id: 'guides',
        url: 'https://educationadvisor.org/company/guides/',
        name: 'Guides'
      },
      {
        id: 'faqs',
        url: 'https://educationadvisor.org/frequently-asked-questions/',
        name: 'FAQs'
      },
      {
        id: 'bestRoi',
        url: 'https://educationadvisor.org/best-roi/',
        name: 'Best ROI'
      },
      {
        id: 'educationCenter',
        url: 'https://educationadvisor.org/education-center/', 
        name: 'Education Center'
      },
    ]
  },
  {
    id: 'company', 
    url: 'https://educationadvisor.org/about-us/', 
    name: 'Company', 
    variant: 'text', 
    color:'secondary',
    subpages: [
      {
        id: 'aboutUs',
        url: 'https://educationadvisor.org/about-us/',
        name: 'About Us'
      },
      {
        id: 'contactUs',
        url: 'https://educationadvisor.org/contact-us/',
        name: 'Contact Us'
      },
      {
        id: 'coreValues',
        url: 'https://educationadvisor.org/about-us/core-values/',
        name: 'Core Values'
      },
      {
        id: 'companyNews',
        url: 'https://educationadvisor.org/company/news/',
        name: 'Company News'
      },
      {
        id: 'partners',
        url: 'https://educationadvisor.org/about-us/our-partners/',
        name: 'Partners'
      },
      {
        id: 'investors',
        url: 'https://educationadvisor.org/about-us/#investors',
        name: 'Investors'
      },
    ]
  },
  {
    id: 'discover', 
    url: '#', 
    name: 'Discover', 
    variant: 'text', 
    color:'secondary',
    subpages: [
      {
        id: 'majors',
        url: 'https://educationadvisor.org/majors-return-on-investment/',
        name: 'Majors'
      },
      {
        id: 'institutions',
        url: 'https://educationadvisor.org/institutions-return-on-investment/',
        name: 'Institutions'
      }
    ]
  },
  {
    id: 'login', 
    url: '/login', 
    name: 'Log In', 
    variant: 'outlined', 
    color:'secondary'
  },
  {
    id: 'signup', 
    url: '#', 
    name: 'Sign Up', 
    variant: 'contained', 
    color:'primary',
    subpages: [
      {
        id: 'studentSignUp',
        url: 'https://app.educationadvisor.org/signup',
        name: 'Student Sign Up'
      },
      {
        id: 'parentSignUp',
        url: 'https://app.educationadvisor.org/parent/signup',
        name: 'Parent Sign Up'
      },
      {
        id: 'educatorSignUp',
        url: 'https://app.educationadvisor.org/register',
        name: 'School/Business Sign Up'
      }
    ]
  }
];

const mobilePages = [
  {
    id: 'home', 
    url: 'https://educationadvisor.org/', 
    name: 'Home', 
    variant: 'text', 
    color:'secondary',
  },
  {
    id: 'students',
    url: 'https://educationadvisor.org/solutions-for-students/',
    name: 'Students', 
    variant: 'text', 
    color:'secondary',
  },
  {
    id: 'educators',
    url: 'https://educationadvisor.org/solutions-for-educators/',
    name: 'Educators', 
    variant: 'text', 
    color:'secondary',
  },
  {
    id: 'businesses',
    url: 'https://educationadvisor.org/solutions-for-businesses/',
    name: 'Businesses', 
    variant: 'text', 
    color:'secondary',
  },
  {
    id: 'resources', 
    url: 'https://educationadvisor.org/education-center/', 
    name: 'Resources', 
    variant: 'text', 
    color:'secondary',
  },
  {
    id: 'company', 
    url: 'https://educationadvisor.org/about-us/', 
    name: 'Company', 
    variant: 'text', 
    color:'secondary',
  },
  {
    id: 'bookADemo', 
    url: 'https://educationadvisor.org/book-a-demo/', 
    name: 'Book A Demo', 
    variant: 'text', 
    color:'secondary',
  },
  {
    id: 'login', 
    url: '/login', 
    name: 'Log In', 
    variant: 'outlined', 
    color:'secondary'
  },
  {
    id: 'studentSignUp',
    url: 'https://app.educationadvisor.org/signup',
    name: 'Student Sign Up',
    variant: 'contained', 
    color:'primary'
  },
  {
    id: 'parentSignUp',
    url: 'https://app.educationadvisor.org/parent/signup',
    name: 'Parent Sign Up',
    variant: 'contained', 
    color:'primary'
  },
  {
    id: 'studentSignUp',
    url: 'https://app.educationadvisor.org/register',
    name: 'School/Business Sign Up',
    variant: 'contained', 
    color:'primary'
  }
];

const Navigation = () => {
  const [openNav, setOpenNav] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setOpenNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setOpenNav(null);
  };

  return (
    <Box className="navigation">
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="primary"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          open={openNav}
          onClose={handleCloseNavMenu}
          anchor='left'
          sx={{zIndex: 99999}}
          PaperProps={{
              sx: {
                  width: '300px',
              }
          }}
        >
          {mobilePages.map((page) => (
              <Button
                href={page.url}
                key={page.id}
                sx={{ 
                  display: 'block',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #E5E5E5',
                  paddingLeft: '20px',
                  paddingRight: '20px'
                }}
                color={page.color}
                disableElevation
              >
                {page.name}
              </Button>
          ))}
        </Drawer>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="nav-desktop">
        {pages.map((page) => (
          <Box className='list-item'>
            <Button
              href={page.url}
              key={page.id}
              sx={{ display: 'block' }}
              color={page.color}
              variant={page.variant}
              disableElevation
            >
              {page.name} {page.hasOwnProperty('subpages') ? "\u25BE" : null}
            </Button>
            {page.hasOwnProperty('subpages') && (
              <>
                <Box className="subpages">
                  {page.subpages.map((subpage) => (
                    <Button
                      href={subpage.url}
                      key={subpage.id}
                      sx={{ display: 'block' }}
                      color='secondary'
                      variant='text'
                      disableElevation
                    >
                      {subpage.name}
                    </Button>
                  ))}
                </Box>
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
};

export default Navigation;
