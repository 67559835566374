import { apiUrl } from '../constants/globals';

export async function getEducators(token) {
    try {
        const response = await fetch(apiUrl + '/students/educators/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function inviteEducator(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/educators/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function approveEducators(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/approve/educator/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function disapproveEducators(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/disapprove/educator/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function revokeEducatorInvite(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/revoke/educator/invitation/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function activateEducators(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/activate/educator/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deactivateEducators(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/deactivate/educator/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getParents(token) {
  try {
      const response = await fetch(apiUrl + '/students/parents/', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function inviteParent(token, data) {
  try {
      const response = await fetch(apiUrl + '/students/parents/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function approveParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/students/approve/parent/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function disapproveParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/students/disapprove/parent/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function revokeParentInvite(token, data) {
  try {
      const response = await fetch(apiUrl + '/students/revoke/parent/invitation/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function activateParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/students/activate/parent/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function deactivateParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/students/deactivate/parent/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}