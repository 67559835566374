import React, { PureComponent } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import EmailIcon from '@mui/icons-material/Email';
import ScienceIcon from '@mui/icons-material/Science';
import WorkIcon from '@mui/icons-material/Work';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import PublicIcon from '@mui/icons-material/Public';
import { storageUrl } from '../../../constants/globals';
import { checkType, parentAccount } from '../../../services/userService';
import { HistoryList } from '../../../components/global/history/historyList';
import { getStudents, getEducators } from '../../../services/parentActionsService';
import { StudentAdd } from '../students/components/studentAdd';

const Dashboard = () => {
  const [userData, setUserData] = React.useState({});
  const [students, setStudents] = React.useState([]);
  const [educators, setEducators] = React.useState([]);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleCloseAddModal = () => setOpenAddModal(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
    parentAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setUserData(userData.data);
        getStudents(localStorage.getItem('token')).then(studentsData => {
          if (studentsData.status == true) {
            setStudents(studentsData.data);
          }
        });
        getEducators(localStorage.getItem('token')).then(educatorsData => {
          if (educatorsData.status == true) {
            setEducators(educatorsData.data);
          }
        });
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);
  
  const viewStudent = (id) => {
      window.location.href = '/parents/students?student_id=' + id;
  };
  
  const viewEducator = (id) => {
      window.location.href = '/parents/educators?educator_id=' + id;
  };
  
  const messagePage = () => {
      window.location.href = '/parents/messages';
  };
  
  const suggestionsPage = () => {
      window.location.href = '/parents/suggestions';
  };
  
  const schoolsPage = () => {
      window.location.href = '/parents/education';
  };
  
  const occupationPage = () => {
      window.location.href = '/parents/occupation';
  };
  
  const discoverPage = () => {
      window.location.href = '/parents/explore';
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography color='secondary' variant='h4' component='div'>
            Dashboard
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} align='right'>
          <Button
            variant='contained'
            disableElevation
            size='large'
            onClick={() => setOpenAddModal(true)}
            disabled={userData?.account_locked ? true : false}
          >
            Add Student
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper sx={{ p: 2, height:{xs:'460px', sm:'300px',md:'300px'}}}>
            <Typography 
              color='secondary' 
              variant='h6' 
              component='div'
            >
              Quick Actions
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />



            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant='text'
                    color='secondary'
                    onClick={() => setOpenAddModal(true)}
                    sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                    startIcon={<EscalatorWarningIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                    disabled={(userData?.account_locked || userData?.account_soft_locked) ? true : false}
                  >
                      Add Student
                  </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant='text'
                    color='secondary'
                    onClick={() => messagePage()}
                    sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                    startIcon={<EmailIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                    disabled={(userData?.account_locked || userData?.account_soft_locked) ? true : false}
                  >
                      Messages
                  </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant='text'
                    color='secondary'
                    onClick={() => suggestionsPage()}
                    sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                    startIcon={<ScienceIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                    disabled={(userData?.account_locked || userData?.account_soft_locked) ? true : false}
                  >
                      AI Suggestions
                  </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant='text'
                    color='secondary'
                    onClick={() => discoverPage()}
                    sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                    startIcon={<PublicIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                    disabled={(userData?.account_locked || userData?.account_soft_locked) ? true : false}
                  >
                      Discover More
                  </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant='text'
                    color='secondary'
                    onClick={() => schoolsPage()}
                    sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                    startIcon={<SchoolIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                    disabled={(userData?.account_locked || userData?.account_soft_locked) ? true : false}
                  >
                      Search Schools
                  </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant='text'
                    color='secondary'
                    onClick={() => occupationPage()}
                    sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                    startIcon={<WorkIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                    disabled={(userData?.account_locked || userData?.account_soft_locked) ? true : false}
                  >
                      Search Occupations
                  </Button>
              </Grid>
            </Grid>


          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper sx={{ p: 2, height:{xs:'460px', sm:'300px',md:'300px'}, overflowY: 'scroll'}}>
            <Typography 
              color='secondary' 
              variant='h6' 
              component='div'
            >
              Recent Student Activity
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <HistoryList type={'parent'} userId={userData} studentId={'*'} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper sx={{ p: 2, height:{xs:'460px', sm:'300px',md:'300px'}, overflowY: 'scroll'}}>
            <Typography 
              color='secondary' 
              variant='h6' 
              component='div'
            >
              Approved Students
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            {students?.length > 0 ? (
              <>
                {students?.map((student, index) => (
                  <>
                    {student.pending === 0 && (
                      <Box sx={{ width: '100%' }} key={index} >
                        <Grid 
                            container 
                            spacing={2}
                            alignItems="center" 
                            justifyContent="center" 
                        >
                            <Grid item xs={12} sm={8}>
                                {!student?.first_name && !student?.last_name ? (
                                    <Typography color='secondary' variant='h6'>{student?.email}</Typography>
                                ) : (
                                    <Typography color='secondary' variant='h6'>{student?.first_name} {student?.last_name}</Typography>
                                )}
                                {student?.active === 0 && (
                                    <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                )} 
                                {student?.active === 1 && (
                                    <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                )}
                                {student?.educators?.map((educator, index) => (
                                    <span key={index}>
                                        {educator.pending === 2 && (
                                            <Chip size='small' key={index} color='secondary' sx={{mr:1, mb:1}} label={educator?.first_name + ' ' + educator?.last_name + ' (Pending)'} />
                                        )}
                                    </span>
                                ))}
                            </Grid>
                            <Grid item xs={12} sm={4} align='right' alignItems="center" justifyContent="center">
                              <Button
                                  variant='contained'
                                  disableElevation
                                  size='small'
                                  disabled={userData?.account_soft_locked ? true : false}
                                  sx={{ml:2, mb:1}}
                                  onClick={() => { viewStudent(student.id) }}
                              >
                                  View
                              </Button>
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      </Box>
                    )}
                  </>
                ))}
              </>
            ) : (
              <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                  No students found.
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper sx={{ p: 2, height:{xs:'460px', sm:'300px',md:'300px'}, overflowY: 'scroll'}}>
            <Typography 
              color='secondary' 
              variant='h6' 
              component='div'
            >
              Approved Educators
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            {educators?.length > 0 ? (
              <>
                  {educators?.map((educator, index) => (
                    <>
                      {educator.pending === 0 && (


                        <Box sx={{ width:'100%'}} key={index} >
                          <Grid 
                              container 
                              spacing={2}
                              alignItems="center" 
                              justifyContent="center" 
                          >
                            <Grid item xs={12} sm={8}> 
                              <Grid container spacing={2} alignItems="center" justifyContent="center">
                                  <Grid item xs={12} sm={4} md={3} textAlign={'center'}>
                                      <div
                                          style={{
                                              height: "50px",
                                              width: "50px",
                                              border: "1px solid #777",
                                              borderRadius: "200px",
                                              overflow: "hidden",
                                              cursor: "pointer",
                                              position: "relative",
                                              margin:'auto'
                                          }}
                                      >
                                          {!educator?.profile_image && (
                                          <>
                                              <PersonIcon
                                                  fontSize="large"
                                                  style={{
                                                      position: "absolute",
                                                      top: "34%",
                                                      left: "26%",
                                                      transform: "translateX(-50%) translateY(-50%)",
                                                      zIndex: 0,
                                                      color: "#999",
                                                      transform: "scale(2.5)"
                                                  }}
                                              />
                                          </>
                                          )}
                                          {educator?.profile_image && (
                                          <>
                                              <img
                                              src={storageUrl + educator?.profile_image}
                                              style={{
                                                  height: "100%",
                                                  position: "absolute",
                                                  top: "50%",
                                                  left: "50%",
                                                  transform: "translateX(-50%) translateY(-50%)",
                                                  zIndex: 1
                                              }}
                                              />
                                          </>
                                          )}
                                      </div>

                                  </Grid>
                                  <Grid item xs={12} sm={8} md={9}>
                                    <Typography color='secondary' variant='h6'>{educator?.first_name} {educator?.last_name}</Typography>
                                    <Typography color='secondary' component='div' sx={{mb:1}}>{educator?.institution?.institution_name}</Typography>
                                    {educator?.active === 1 && (
                                      <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                    )}
                                    {educator?.active === 0 && (
                                      <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                    )} 
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={4} textAlign={'right'}>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='small'
                                    disabled={userData?.account_soft_locked ? true : false}
                                    sx={{ml:2, mb:1}}
                                    onClick={() => { viewEducator(educator.id) }}
                                >
                                    View
                                </Button>
                              </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                          </Box>
                      )}
                    </>
                  ))}
              </>
            ) : (
              <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                  No educators found.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
      >
        <StudentAdd
          educators={educators}
          handleCloseAddModal={handleCloseAddModal}
        />
      </Modal>
    </Container>
  );
};

export default Dashboard;
