import { apiUrl } from '../constants/globals';

export async function getStudentHistoryAsParent(token, studentId, page = 1) {
    try {
        const response = await fetch(`${apiUrl}/parents/student/history/${studentId}?page=${page}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + token
            })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentHistoryAsEducator(token, educatorId, studentId, page = 1) {
    try {
        const response = await fetch(`${apiUrl}/educator/student/history/${educatorId}/${studentId}?page=${page}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + token
            })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}