import React, { Suspense } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { CardList } from '../payments/list';
import { getBillingMethods, getSubscription } from '../../../../../../services/parentBillingService';
import { SubscriptionStop } from './stop';

const formatPrice = (price) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price / 100);

export function SubscriptionOverview({product, setError, setErrorText}) {
    const [subscription, setSubscription] = React.useState({});
    const [cards, setCards] = React.useState({});
    const [nextChargeDate, setNextChargeDate] = React.useState({});
    const [discountEndDate, setDiscountEndDate] = React.useState({});
    const [discountApplies, setDiscountApplies] = React.useState(false);
    const [discountAmount, setDiscountAmount] = React.useState(null);
    const [discountGrandTotal, setDiscountGrandTotal] = React.useState(null);
    const [openCancelModal, setOpenCancelModal] = React.useState(false);
    const handleCloseCancelModal = () => setOpenCancelModal(false);
    const handleOpenCancelModal = () => setOpenCancelModal(true);

    React.useEffect(() => {
        getBillingMethods(localStorage.getItem('token')).then(cards => {
            if (cards.status == true) {
                setCards(cards.data);
            } else {
                setError(true);
                setErrorText(cards.errors);
            }
        });
        getSubscription(localStorage.getItem('token')).then(subscription => {
            setSubscription(subscription?.data);
            let date = new Date(subscription?.data?.current_period_end * 1000);
            setNextChargeDate({
              day: date.toLocaleString("default", { day: "numeric" }),
              month: date.toLocaleString("default", { month: "long" }),
              year: date.toLocaleString("default", { year: "numeric" }),
            });
            if (subscription?.data?.discount) {
                let discountEndDate = new Date(subscription?.data?.discount?.end * 1000);
                let discountStartDate = new Date(subscription?.data?.discount?.start * 1000);
                if (discountEndDate > new Date()) {
                    setDiscountApplies(true);
                }
                if (subscription?.data?.discount?.end === null && discountStartDate < new Date()) {
                    setDiscountApplies(true);
                }
                setDiscountEndDate({
                    day: discountEndDate.toLocaleString("default", { day: "numeric" }),
                    month: discountEndDate.toLocaleString("default", { month: "long" }),
                    year: discountEndDate.toLocaleString("default", { year: "numeric" }),
                });
                var discountString = ((subscription?.data?.discount?.coupon.percent_off / 100) * (subscription?.data?.plan.amount * subscription?.data?.quantity)).toString();
                var decimaledDiscount = discountString.slice(0, -2) + "." + discountString.slice(-2);
                setDiscountAmount(
                    decimaledDiscount
                );
                var discountGrandTotalString = (subscription?.data?.plan.amount - ((subscription?.data?.discount?.coupon.percent_off / 100) * (subscription?.data?.plan.amount * subscription?.data?.quantity))).toString();
                var decimaledGrandTotalString = discountGrandTotalString.slice(0, -2) + "." + discountGrandTotalString.slice(-2);
                if (decimaledGrandTotalString === '.0') {
                    decimaledGrandTotalString = '0.00';
                }
                setDiscountGrandTotal(decimaledGrandTotalString);
            }
        });
    }, []);

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={5}>
                    <Grid item sm={12} md={6}>
                        <Typography color='secondary' variant='h6' component='div'>
                            Subscription Information
                        </Typography>
                        <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
                        <Grid container alignItems="center">
                            <Grid item xs={9}>
                                <strong>Monthly Subscription</strong>
                                <p>Grants full access to Education Advisor.</p>
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: 'right'}}>
                                {subscription?.plan?.amount ? (
                                    <>
                                        {formatPrice(subscription?.plan?.amount)}
                                    </>
                                ) : (
                                    <>
                                        $0.00
                                    </>
                                )}
                                &nbsp;/&nbsp;{subscription?.plan?.interval} per student
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        <Grid container>
                            <Grid item xs={9}>
                                <strong>Total Student Seats:</strong>
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: 'right'}}>
                                {subscription?.quantity ? (
                                    <>
                                        {subscription?.quantity}
                                    </>
                                ) : (
                                    <>
                                        0
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        <Grid container alignItems="center">
                            <Grid item md={12}>
                                <b><i>Your card will automatically be charged next on {nextChargeDate?.month} {nextChargeDate?.day}, {nextChargeDate?.year}</i></b>
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Total Due {nextChargeDate?.month} {nextChargeDate?.day}</Divider>
                        <Grid container alignItems="center" >
                            <Grid item xs={9}>
                                Subtotal:
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: 'right'}}>
                                {subscription?.plan?.amount ? (
                                    <>
                                        {formatPrice(subscription?.plan?.amount * subscription?.quantity)}
                                    </>
                                ) : (
                                    <>
                                        $0.00
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        <Grid container alignItems="center">
                            <Grid item xs={9}>
                                Taxes:
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: 'right'}}>
                                {subscription?.items?.data?.[0]?.tax_rates?.[0]?.percentage ? (
                                    <>
                                        {formatPrice((subscription?.items?.data?.[0]?.price?.unit_amount * subscription?.items?.data?.[0]?.quantity) * subscription?.items?.data?.[0]?.tax_rates?.[0]?.percentage)}
                                    </>
                                ) : (
                                    <>
                                        $0.00
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        {discountApplies && (
                            <>
                                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                                <Grid container alignItems="center" sx={{mb:2}}>
                                    <Grid item xs={9}>
                                        Discount: 
                                    </Grid>
                                    <Grid item xs={3} sx={{textAlign: 'right'}}>
                                        <strong>${discountAmount}</strong>
                                    </Grid>
                                </Grid>
                                {subscription?.discount?.end !== null && (
                                    <Grid container alignItems="center">
                                        <Grid item md={12}>
                                            <b><i>This discount expires on: {discountEndDate?.month} {discountEndDate?.day}, {discountEndDate?.year}</i></b>
                                        </Grid>
                                    </Grid>
                                )}                    
                            </>
                        )}
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        <Grid container alignItems="center">
                            <Grid item xs={9}>
                                <strong>Grand Total:</strong>
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: 'right'}}>
                                {subscription?.plan?.amount ? (
                                    <>
                                        <strong>{discountGrandTotal !== null ? discountGrandTotal : formatPrice(subscription?.plan?.amount * subscription?.quantity)}</strong>
                                    </>
                                ) : (
                                    <>
                                        <strong>$0.00</strong>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Divider variant='fullWidth' sx={{ mt: 2, mb: 2 }}>Modify Subscription</Divider>
                        <Button 
                            variant='outlined'
                            color='secondary'
                            sx={{width:'100%'}}
                            onClick={handleOpenCancelModal}
                        >
                            Stop Subscription
                        </Button>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <CardList
                            subscription={subscription}
                            cards={cards}
                            setCards={setCards}
                            setError={setError}
                            setErrorText={setErrorText}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Modal
                open={openCancelModal}
                onClose={handleCloseCancelModal}
            >
                <SubscriptionStop
                    handleCloseCancelModal={handleCloseCancelModal}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Modal>
        </>
    );
};