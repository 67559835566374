import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

export function StudentsComponent({user, educator}) {
    const viewStudent = (id) => {
        window.location.href = '/parents/students?student_id=' + id;
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Educator Students
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid 
                container 
                alignItems="center" 
                justifyContent="center" 
                spacing={3}
            >
                <Grid item xs={12}>
                    {educator?.students?.length > 0 ? (
                        <>
                            {educator?.students?.map((student, index) => (
                                <Box sx={{ width: '100%' }} key={index} >
                                    <Grid 
                                        container 
                                        spacing={2}
                                        alignItems="center" 
                                        justifyContent="center" 
                                    >
                                        <Grid item xs={12} sm={8}>
                                            {!student?.first_name && !student?.last_name ? (
                                                <Typography color='secondary' variant='h6'>{student?.email}</Typography>
                                            ) : (
                                                <>
                                                    <Typography color='secondary' variant='h6'>{student?.first_name} {student?.last_name}</Typography>
                                                    <Typography color='secondary' sx={{mb:1}}>{student?.email}</Typography>
                                                </>
                                            )}
                                            {student?.pending === 2 ? (
                                                <Chip size='small' color='primary' sx={{color:'#fff', mr:1}} label='Pending Educator Approval' />
                                            ) : (
                                                <>
                                                    {student?.active === 0 && (
                                                        <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                                    )} 
                                                    {student?.active === 1 && (
                                                        <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                                    )}
                                                    {student?.pending === 1 && (
                                                        <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Student Approval' />
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={4} align='right' alignItems="center" justifyContent="center" sx={{pr:1}}>
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                size="small"
                                                disabled={!user?.account_locked ? false : true}
                                                onClick={() => { viewStudent(student?.id) }}
                                            >
                                                View
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {index !== educator?.students?.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                                </Box>
                            ))}
                        </>
                    ) : (
                        <>
                            <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                                No Students Found.
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}