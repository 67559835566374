import React, { Suspense } from 'react';
import Typography from '@mui/material/Typography';

export function TermsAndConditions({}) {
    return (
        <>
            <Typography color='secondary' variant='h4' component='div'>
                Terms and Conditions
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                1. Subscription Overview
            </Typography>
            <Typography color='secondary' component='div'>
                1.1 By accessing and using our monthly subscription service ("Service"), you agree to be bound by the terms and conditions outlined herein.
            </Typography>
            <Typography color='secondary' component='div'>
                1.2 The Service is provided by Education Technologies LLC ("Company") and is subject to change without notice.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                2. Trial Period
            </Typography>
            <Typography color='secondary' component='div'>
                2.1 A trial period of seven (7) days is provided to new users upon sign-up.
            </Typography>
            <Typography color='secondary' component='div'>
                2.2 During the trial period, users have full access to all features and services offered by the Service.
            </Typography>
            <Typography color='secondary' component='div'>
                2.3 At the end of the trial period, users are required to subscribe to a monthly plan to continue using the Service.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                3. Subscription Fees
            </Typography>
            <Typography color='secondary' component='div'>
                3.1 The subscription fee is $14.99 per student per month.
            </Typography>
            <Typography color='secondary' component='div'>
                3.2 Payment will be automatically deducted from the user's chosen payment method on a monthly basis.
            </Typography>
            <Typography color='secondary' component='div'>
                3.3 Users are responsible for ensuring that their payment information is accurate and up-to-date.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                4. Educator Connection
            </Typography>
            <Typography color='secondary' component='div'>
                4.1 The users students who are connected to an educator are eligible for complimentary access to all services provided by the Service and the user will not be charge for those students.
            </Typography>
            <Typography color='secondary' component='div'>
                4.2 "Educator" refers to an individual with a valid and active educator account within the Service.
            </Typography>
            <Typography color='secondary' component='div'>
                4.3 The Company reserves the right to verify the educator status of connected users.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                5. Cancellation and Refunds
            </Typography>
            <Typography color='secondary' component='div'>
                5.1 Users may cancel their subscription at any time.
            </Typography>
            <Typography color='secondary' component='div'>
                5.2 Cancellation must be done before the next billing cycle to avoid being charged for the upcoming month.
            </Typography>
            <Typography color='secondary' component='div'>
                5.3 Refunds will be provided for partial months or unused portions of the subscription.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                6. Termination of Service
            </Typography>
            <Typography color='secondary' component='div'>
                6.1 The Company reserves the right to terminate or suspend the Service, in whole or in part, at any time without notice.
            </Typography>
            <Typography color='secondary' component='div'>
                6.2 In the event of termination by the Company, users will be notified via the email address associated with their account.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                7. Privacy and Data Security
            </Typography>
            <Typography color='secondary' component='div'>
                7.1 The Company is committed to protecting user privacy and securing personal information as outlined in the Privacy Policy.
            </Typography>
            <Typography color='secondary' component='div'>
                7.2 Users are responsible for maintaining the confidentiality of their account credentials.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                8. Changes to Terms and Conditions
            </Typography>
            <Typography color='secondary' component='div'>
                8.1 The Company reserves the right to modify these terms and conditions at any time.
            </Typography>
            <Typography color='secondary' component='div'>
                8.2 Users will be notified of any changes to the terms and conditions via email or through the Service.
            </Typography>
            <Typography color='secondary' variant='h6' component='div'>
                9. Contact Information
            </Typography>
            <Typography color='secondary' component='div'>
                9.1 For inquiries or concerns regarding these terms and conditions, please contact support@educationadvisor.org.
            </Typography>
        </>
    );
};