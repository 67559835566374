import { apiUrl } from '../constants/globals';

export async function getEmployee(token, employeeId) {
    try {
        const response = await fetch(apiUrl + '/institution/employees/' + employeeId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getEmployees(token) {
    try {
        const response = await fetch(apiUrl + '/institution/employees/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateEmployee(token, employeeId, data) {
    try {
        const response = await fetch(apiUrl + '/institution/employees/' + employeeId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addEmployee(token, data) {
    try {
        const response = await fetch(apiUrl + '/institution/employees/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudents(token) {
    try {
        const response = await fetch(apiUrl + '/institution/students/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentsByEmployee(token, employeeId) {
    try {
        const response = await fetch(apiUrl + '/institution/students/' + employeeId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateStudent(token, studentId, data) {
    try {
        const response = await fetch(apiUrl + '/institution/students/' + studentId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function inviteStudent(token, data) {
    try {
        const response = await fetch(apiUrl + '/institution/students/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function revokeStudentInvitation(token, data) {
    try {
        const response = await fetch(apiUrl + '/institution/revoke/students/invitation/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function approveStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/institution/approve/students/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function disapproveStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/institution/disapprove/students/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function activateStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/institution/activate/student/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deactivateStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/institution/deactivate/student/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getParents(token) {
  try {
      const response = await fetch(apiUrl + '/institution/parents/', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getParentsByEmployee(token, employeeId) {
  try {
      const response = await fetch(apiUrl + '/institution/parents/' + employeeId, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function updateParent(token, studentId, data) {
  try {
      const response = await fetch(apiUrl + '/institution/parents/' + studentId, {
        method: 'PUT',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function inviteParent(token, data) {
  try {
      const response = await fetch(apiUrl + '/institution/parents/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function revokeParentInvitation(token, data) {
  try {
      const response = await fetch(apiUrl + '/institution/revoke/parents/invitation/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function approveParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/institution/approve/parents/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function disapproveParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/institution/disapprove/parents/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function activateParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/institution/activate/parent/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function deactivateParents(token, data) {
  try {
      const response = await fetch(apiUrl + '/institution/deactivate/parent/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getReports(token, type) {
    try {
        const response = await fetch(apiUrl + '/educator/institution/reports/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getDashboard(token, type) {
    try {
        const response = await fetch(apiUrl + '/educator/dashboard/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getEducatorDashboard(token, type, id) {
    try {
        const response = await fetch(apiUrl + '/educator/dashboard/' + type + '/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function assignStudents(token, id, data) {
    try {
        const response = await fetch(apiUrl + '/institution/parents/assignStudents/' + id, {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function assignParents(token, id, data) {
    try {
        const response = await fetch(apiUrl + '/institution/students/assignParents/' + id, {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}