import React, { PureComponent } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
import { getBillingMethods, deleteBillingMethod, removeSubscription } from '../../../../../../services/educatorBillingService';

const formatPrice = (price) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price / 100);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

const AccountOverview = ({
    subscription, 
    selectedPricingData, 
    selectedProductData, 
    nextChargeDate,
    setAddPaymentPage,
    setUpdateSubscriptionPage
}) => {
    const [cards, setCards] = React.useState({});
    const [discountEndDate, setDiscountEndDate] = React.useState({});
    const [discountApplies, setDiscountApplies] = React.useState(false);
    const [discountAmount, setDiscountAmount] = React.useState(null);
    const [discountGrandTotal, setDiscountGrandTotal] = React.useState(null);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);
    const [openCancelModal, setOpenCancelModal] = React.useState(false);
    const handleCloseCancelModal = () => setOpenCancelModal(false);
    const handleOpenCancelModal = () => setOpenCancelModal(true);

    React.useEffect(() => {
        getBillingMethods(localStorage.getItem('token')).then(cards => {
            if (cards.status == true) {
                setCards(cards.data);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(cards.errors);
            }
        });
        if (subscription?.discount) {
            let discountEndDate = new Date(subscription?.discount?.end * 1000);
            let discountStartDate = new Date(subscription?.discount?.start * 1000);
            if (discountEndDate > new Date()) {
                setDiscountApplies(true);
            }
            if (subscription?.discount?.end === null && discountStartDate < new Date()) {
                setDiscountApplies(true);
            }
            setDiscountEndDate({
                day: discountEndDate.toLocaleString("default", { day: "numeric" }),
                month: discountEndDate.toLocaleString("default", { month: "long" }),
                year: discountEndDate.toLocaleString("default", { year: "numeric" }),
            });
            var discountString = ((subscription?.discount?.coupon.percent_off / 100) * subscription?.plan.amount).toString();
            var decimaledDiscount = discountString.slice(0, -2) + "." + discountString.slice(-2);
            setDiscountAmount(
                decimaledDiscount
            );
            var discountGrandTotalString = (subscription?.plan.amount - ((subscription?.discount?.coupon.percent_off / 100) * subscription?.plan.amount)).toString();
            var decimaledGrandTotalString = discountGrandTotalString.slice(0, -2) + "." + discountGrandTotalString.slice(-2);
            if (decimaledGrandTotalString === '.0') {
                decimaledGrandTotalString = '0.00';
            }
            setDiscountGrandTotal(decimaledGrandTotalString);
        }
    }, []);

    const deleteCard = (card) => {
        let cardData = new URLSearchParams({
            'card':card
        });

        deleteBillingMethod(localStorage.getItem('token'), cardData).then(response => {
            if (response.status == true) {
                const newCardList = cards.filter((cardList) => cardList.id !== card);
                setCards(newCardList);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(response.errors);
            }
        });
    };

    const handleCancelSubscription = () => {
        handleCloseCancelModal();
        removeSubscription(localStorage.getItem('token')).then(response => {
            if (response.status == true) {
                window.location.reload();
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(response.errors);
            }
        });
    };

    const setPaymentPage = () => {
        setAddPaymentPage(true);
        setUpdateSubscriptionPage(false);
        window.scrollTo(0, 0);
    };

    const setSubscriptionPage = () => {
        setAddPaymentPage(false);
        setUpdateSubscriptionPage(true);
        window.scrollTo(0, 0);
    };

    return (
        <Grid container spacing={5}>
            <Grid item sm={12} md={6}>
                <Typography color='secondary' variant='h6' component='div'>
                    Subscription Information
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
                <Grid container alignItems="center">
                    <Grid item xs={9}>
                        <strong>{selectedProductData?.name} Tier</strong>
                        <p>{selectedProductData?.description}</p>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'right'}}>
                        <p>${selectedPricingData?.unit_amount?.toString().substring(0, (selectedPricingData?.unit_amount?.toString().length) - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        &nbsp;/&nbsp;{selectedPricingData?.recurring?.interval}</p>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item md={12}>
                        <b><i>Your card will automatically be charged next on {nextChargeDate?.month} {nextChargeDate?.day}, {nextChargeDate?.year}</i></b>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Total Due {nextChargeDate?.month} {nextChargeDate?.day}</Divider>
                <Grid container alignItems="center" >
                    <Grid item xs={9}>
                        Subtotal:
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'right'}}>
                        ${selectedPricingData?.unit_amount?.toString().substring(0, selectedPricingData?.unit_amount?.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container alignItems="center">
                    <Grid item xs={9}>
                        Taxes:
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'right'}}>
                        {subscription?.items?.data?.[0]?.tax_rates?.[0]?.percentage ? (
                            <>
                                {formatPrice((subscription?.items?.data?.[0]?.price?.unit_amount * subscription?.items?.data?.[0]?.quantity) * subscription?.items?.data?.[0]?.tax_rates?.[0]?.percentage)}
                            </>
                        ) : (
                            <>
                                $0.00
                            </>
                        )}
                    </Grid>
                </Grid>
                {discountApplies && (
                    <>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        <Grid container alignItems="center" sx={{mb:2}}>
                            <Grid item xs={9}>
                                Discount: 
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: 'right'}}>
                                <strong>${discountAmount}</strong>
                            </Grid>
                        </Grid>
                        {subscription?.discount?.end !== null && (
                            <Grid container alignItems="center">
                                <Grid item md={12}>
                                    <b><i>This discount expires on: {discountEndDate?.month} {discountEndDate?.day}, {discountEndDate?.year}</i></b>
                                </Grid>
                            </Grid>
                        )}                    
                    </>
                )}
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container alignItems="center" sx={{mb:2}}>
                    <Grid item xs={9}>
                        <strong>Grand Total:</strong>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'right'}}>
                        <strong>${discountGrandTotal !== null ? discountGrandTotal : selectedPricingData?.unit_amount?.toString().substring(0, selectedPricingData?.unit_amount?.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.00'}</strong>
                    </Grid>
                </Grid>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 2 }}>Modify Subscription</Divider>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            variant='contained'
                            color='primary'
                            disableElevation
                            sx={{width:'100%'}}
                            onClick={setSubscriptionPage}
                        >
                            Edit Subscription
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} textAlign={'right'}>
                        <Button 
                            variant='outlined'
                            color='secondary'
                            sx={{width:'100%'}}
                            onClick={handleOpenCancelModal}
                        >
                            Stop Subscription
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md={6}>
                <Typography color='secondary' variant='h6' component='div'>
                    Payment Methods
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
                {cards?.length > 0 ? (
                    <>
                        {cards?.map((card, index) => (
                            <Grid 
                                key={index} 
                                container 
                                p={1} 
                                mb={2}
                                alignItems="center"
                                sx={[
                                    {'&:hover': {boxShadow:'1px 1px #E5E5E5'}},
                                    {border:'1px solid #E5E5E5', borderRadius:1}
                                ]}
                            >
                                <Grid item xs={9}>
                                    {card.card.brand.charAt(0).toUpperCase() + card.card.brand.slice(1)} &#8226; &#8226; &#8226; &#8226; {card.card.last4}
                                    {card?.is_primary && (
                                        <>
                                            &nbsp; <Chip 
                                                        label="Used For Your Subscription" 
                                                        color="primary" 
                                                        size="small" 
                                                        sx={{
                                                            color:'white',
                                                            fontSize: '1'
                                                        }}
                                                    />
                                        </>
                                    )}
                                    <br />
                                    <Typography fontSize="14px">
                                        Expires On {card.card.exp_month}/{card.card.exp_year}
                                    </Typography>
                                    <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }}></Divider>
                                    <Typography fontSize="14px">
                                        {card.billing_details.address.line1}, {card.billing_details.address.city} {card.billing_details.address.state}, {card.billing_details.address.postal_code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: 'right'}}>
                                    <Tooltip arrow title={card?.is_primary ? "You cannot delete a primary card. You must add a new card and add it as the primary card." : ""}>
                                        <span>
                                            <Button
                                                color="secondary"
                                                disabled={card?.is_primary && true}
                                                onClick={() => {deleteCard(card?.id)}}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Typography
                        color='secondary'
                        component='div'
                        sx={{ mt: 3, mb: 3, textAlign:'center'}}
                    >
                        No Payment Methods Found
                    </Typography>
                )}
                <Button 
                    variant="text" 
                    sx={{fontWeight:'bold'}}
                    onClick={setPaymentPage}
                >
                    + Add A New Payment Method
                </Button>
            </Grid>
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    {openErrorModalText}
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={openCancelModal}
                onClose={handleCloseCancelModal}
            >
                <Box sx={style}>
                    <WarningIcon
                        color="primary"
                        fontSize="large"
                    />
                    <Typography variant="h6" component="h2">
                    Are you sure you want to cancel your subscription? You will be prorated for any time left on your subscription.
                    </Typography>
                    <Grid container spacing={2} sx={{mt:1}}>
                        <Grid item xs={12} sm={6}>
                            <Button 
                                variant='contained'
                                color='primary'
                                disableElevation
                                sx={{width:'100%'}}
                                onClick={handleCloseCancelModal}
                            >
                                Go Back
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign={'right'}>
                            <Button 
                                variant='outlined'
                                color='secondary'
                                sx={{width:'100%'}}
                                onClick={handleCancelSubscription}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )
}

export default AccountOverview;