import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { parentGetStudentScholarships, getScholarshipTypes } from '../../../../../services/scholarshipService';

export function ScholarshipsComponent({student, selectedEducator, setOpenScholarships, setError, setErrorText}) {
    const [scholarships, setScholarships] = React.useState([]);
    const [scholarshipTypes, setScholarshipTypes] = React.useState([]);

    React.useEffect(() => {
      parentGetStudentScholarships(localStorage.getItem('token'), student?.id).then(scholarships => {
            if (scholarships.status == true) {
                setScholarships(scholarships.data);

                getScholarshipTypes(localStorage.getItem('token')).then(scholarshipTypesData => {
                    if (scholarshipTypesData.status == true) {
                        setScholarshipTypes(scholarshipTypesData.data);
                    }
                });
            } else {
                setError(true);
                setErrorText(scholarships.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
        });
    }, [setOpenScholarships]);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
                Scholarships
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            {scholarships?.length > 0 ? (
              <>
                {scholarships?.map((scholarship, index) => (
                  <Container key={index} maxWidth={false} disableGutters={true}>
                    <Grid container>
                        <Typography color='secondary' variant='h5' sx={{mb:1}}><b>{scholarship.name}</b></Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography color='secondary'>Total Scholarship Amount:</Typography>
                            <Typography color='primary' variant='h5'>${(scholarship.recurrent === 1) ? (scholarship.total * scholarship.recurrency_years).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : scholarship.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography color='secondary'>Scholarship Type:</Typography>
                            <Typography color='secondary' variant='h5'>{scholarshipTypes.find(type => type.id === scholarship.type)?.name}</Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                  </Container>
                ))}
              </>
            ) : (
              <>
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10 }}>
                    This student does not have any scholarships.
                </Typography>
              </>
            )}
        </>
    )
}