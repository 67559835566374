import React, { PureComponent, Suspense } from 'react';
import { useSearchParams } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import { checkType, parentAccount } from '../../../services/userService';
import { getStudents, getEducators } from '../../../services/parentActionsService';
import { StudentAdd } from './components/studentAdd';
import { StudentView } from './components/studentView';

const Students = () => {
  const [searchParams] = useSearchParams();
  const [parent, setParent] = React.useState(false);
  const [viewStudent, setViewStudent] = React.useState(false);
  const [students, setStudents] = React.useState({});
  const [educators, setEducators] = React.useState({});
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleCloseAddModal = () => setOpenAddModal(false);
  const StudentList = React.lazy(() => import('./components/studentList'));

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
    parentAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked) {
          window.location.href = '/parents/dashboard';
        }
        setParent(userData.data);
        getStudents(localStorage.getItem('token')).then(studentsData => {
          if (studentsData.status == true) {
            setStudents(studentsData.data);
          }
          const studentId = searchParams.get('student_id');
          studentsData.data.forEach(student => {
            if (studentId !== null && student?.id == studentId) {
              setViewStudent(student);
            }
          });
        });
        getEducators(localStorage.getItem('token')).then(educatorsData => {
          if (educatorsData.status == true) {
            setEducators(educatorsData.data);
          }
        });
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      {viewStudent ? (
        <Suspense fallback={<div>Loading...</div>}>
          <StudentView 
            student={viewStudent}
            parent={parent}
            setViewStudent={setViewStudent}
          />
        </Suspense>
      ) : (
        <>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Students
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={() => setOpenAddModal(true)}
                disabled={parent?.account_locked ? true : false}
              >
                Add Student
              </Button>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          <Paper sx={{ p: 2, mb: 2, position:'relative' }}>
            <Box sx={{ width: '100%' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <StudentList
                  studentList={students}
                  parent={parent}
                  setViewStudent={setViewStudent}
                />
              </Suspense>
            </Box>
          </Paper>
        </>
      )}
      <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
      >
        <StudentAdd
          educators={educators}
          handleCloseAddModal={handleCloseAddModal}
        />
      </Modal>
    </Container>
  );
};

export default Students;
