import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Container, Grid, Typography, Button, Divider, Paper, Box, Modal } from '@mui/material';
import { EducatorList } from './components/educatorList';
import { EducatorView } from './components/educatorView';
import { userAccount, checkType } from '../../../services/userService';
import { getEducators, inviteEducator, } from '../../../services/studentActionService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const StudentEducators = () => {
  const [user, setUser] = React.useState({});
  const [educators, setEducators] = React.useState({});
  const [viewEducator, setViewEducator] = React.useState(false);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleCloseAddModal = () => setOpenAddModal(false);
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [errorText, setErrorText] = React.useState('');

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleEmailChange = (event, values) => {
    if (event.target.value) {
      setEmail(event.target.value);
    }
  };

  const handleMessageChange = (event, values) => {
    if (event.target.value) {
      setMessage(event.target.value);
    }
  };

  const handleAddEducator = (event) => {
    let data = new URLSearchParams({
      'email':email,
      'message':message
    });

    inviteEducator(localStorage.getItem('token'), data).then(update => {
      if (update?.status == true) {
          setEmail('');
          setMessage('');
          handleCloseAddModal();
          window.location.reload();
      } else {
          setErrorText(update.message);
      }
    });
  };

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setUser(userData.data);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getEducators(localStorage.getItem('token')).then(educators => {
      if (educators.status == true) {
        setEducators(educators.data);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      {viewEducator ? (
        <EducatorView 
          user={user}
          educator={viewEducator}
          setViewEducator={setViewEducator}
        />
      ) : (
        <>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Educators
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={() => setOpenAddModal(true)}
                disabled={!user?.account_locked ? false : true}
              >
                Add Educator
              </Button>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          <Paper>
            <Box sx={{ width: '100%' }}>
              <EducatorList 
                educators={educators}
                user={user}
                setViewEducator={setViewEducator}
              />
            </Box>
          </Paper>
        </>
      )}
      <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
      >
        <Box sx={style}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                  <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                      Add Educator
                  </Typography>
                  <Typography textAlign={'center'} variant='h6' component='div'>
                      Note: This educator will be able to see your profile including scholarships and loans.
                  </Typography>
                  {errorText && (
                      <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                          {errorText}
                      </Typography>
                  )}
              </Grid>
              <Grid item xs={12}>
                  <TextField
                      required
                      fullWidth
                      name="email"
                      id="email"
                      label="Email"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                  />
              </Grid>
              <Grid item xs={12}>
                  <TextField
                      name="message"
                      fullWidth
                      id="message"
                      label="Add A Message (Optional)"
                      type="text"
                      multiline
                      rows={5}
                      value={message}
                      onChange={handleMessageChange}
                  />
              </Grid>
              <Grid item xs={12}>
                <Button
                    variant="contained"
                    type="submit"
                    disableElevation
                    size="large"
                    fullWidth
                    onClick={handleAddEducator}
                    disabled={email ? false : true}
                >
                    Add Educator
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default StudentEducators;
