import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadDocument, downloadEmployeeDocument } from '../../../services/documentsService';
import { deleteDocument } from '../../../services/documentsService';
import { updateDocument } from '../../../services/documentsService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function DocumentsList({type, documents, attachedUser, setUpdateList, setOpenAddDocumentModal, setError, setErrorText, disableGutters}) {
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [documentToEdit, setDocumentToEdit] = useState(null);
    const [documentTitle, setDocumentTitle] = useState(null);
    const [status, setStatus] = useState('Requires Review');
    const [formCompleted, setFormCompleted] = React.useState(false);
    const [openEditDocumentModal, setOpenEditDocumentModal] = React.useState(false);
    const handleCloseEditDocumentModal = () => {
        setDocumentToEdit(null);
        setDocumentTitle(null);
        setStatus(null);
        setOpenEditDocumentModal(false);
    };
    const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = React.useState(false);
    const handleCloseDeleteDocumentModal = () => {
        setDocumentToDelete(null);
        setOpenDeleteDocumentModal(false);
    };

    React.useEffect(() => {
        setFormCompleted(false);
        if (documentTitle && status) {
            setFormCompleted(true);
        }
    });

    const formatDate = (date) => {
        var options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit' };
        var formattedDate  = new Date(date);

        return formattedDate.toLocaleDateString('en-US', options);
    };

    const onDocumentTitleChange = (event) => {
        setDocumentTitle(event.target.value);
    };

    const onStatusChange = (event) => {
      setStatus(event.target.value);
    }

    const canDelete = (document) => {
        if (type === 'student') {
            if (document?.uploader_id === attachedUser?.id) {
                return true;
            }
        } else if (type === 'self_educator') {
            if (document?.uploader_id === attachedUser?.id) {
                return true;
            }
        }
        return false;
    };

    const canEditTitle = (document) => {
        if (type === 'student') {
            if (document?.educator_id === undefined || document?.educator_id === null || document?.educator_id === 'null') {
                return true;
            }

            if (document?.uploader_id === attachedUser?.id) {
                return true;
            }
        } else if (type === 'self_educator') {
            if (document?.uploader_id === attachedUser?.id) {
                return true;
            }
        }
        return false;
    };

    const downloadFile = (document) => {
        if (type === 'educator') {
            downloadEmployeeDocument(localStorage.getItem('token'), attachedUser?.id, document?.id, document?.document.replace('private/', '')).then(file => {
                if (file) {
                    return false;
                } else {
                    setError(true);
                    setErrorText('There was an error downloading the file.');
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error downloading the file.');
            });
        } else {
            downloadDocument(localStorage.getItem('token'), document?.id, document?.document.replace('private/', '')).then(file => {
                if (file) {
                    return false;
                } else {
                    setError(true);
                    setErrorText('There was an error downloading the file.');
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error downloading the file.');
            });
        }
    };

    const editFileModal = (document) => {
        setDocumentToEdit(document);
        setDocumentTitle(document?.document_title);
        setStatus(document?.status);
        setOpenEditDocumentModal(true);
    };

    const deleteFileModal = (document) => {
        setDocumentToDelete(document?.id);
        setOpenDeleteDocumentModal(true);
    };

    const deleteFile = () => {
        deleteDocument(localStorage.getItem('token'), documentToDelete).then(document => {
            if (document.status) {
                setUpdateList(document);
                handleCloseDeleteDocumentModal();
            } else {
                setError(true);
                setErrorText('There was an error deleting the file.');
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error deleting the file.');
        });
    };

    const editFile = () => {
        let data = new URLSearchParams({
          'document_title':documentTitle,
          'status':status
        });
        
        updateDocument(localStorage.getItem('token'), data, documentToEdit?.id).then(document => {
            if (document.status) {
                setUpdateList(document);
                handleCloseEditDocumentModal();
            } else {
                setError(true);
                setErrorText('There was an error updating the file.');
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error updating the file.');
        });
    };
    
    return (
        <>
            {documents?.length > 0 ? (
                <>
                    {documents?.map((document, index) => (
                        <Container key={index} maxWidth={false} disableGutters={(type === 'student' && !disableGutters) ? false : true}>
                            <Grid 
                                container 
                                alignItems="center" 
                                justifyContent="center" 
                                spacing={0}
                            >
                                <Grid item xs={9}>
                                    <Typography color='primary' variant='h6' component='div'>
                                        <Link href='#' onClick={() => downloadFile(document)} underline="hover">{document?.document_title}</Link>
                                        <Chip label={document?.status} size="small" sx={{ml: 1}} />
                                    </Typography>
                                    <Typography color='secondary' component='div' sx={{mb: 1, fontSize: '14px'}}>
                                        {formatDate(document?.created_at)}
                                    </Typography>
                                    {(type === 'student' || type === 'parent') && document?.educator !== null && (
                                        <Typography color='secondary' component='div' sx={{mb: 1, fontSize: '14px', fontWeight: 'bold'}}>
                                            {document?.educator?.id === document?.uploader_id ? 'Recieved By' : 'Sent to'} {document?.educator?.first_name} {document?.educator?.last_name} {document?.educator?.active === 0 && ('(Inactive)')}
                                        </Typography>
                                    )}
                                    {(type !== 'educator' && type !== 'parent') && attachedUser?.active !== 0 && document?.educator?.active !== 0 && (
                                        <>
                                            <Chip 
                                                label="Edit File" 
                                                clickable 
                                                color='primary' 
                                                size="small" 
                                                variant="outlined"
                                                sx={{mr: 1}}
                                                onClick={() => editFileModal(document)}
                                            />
                                            {canDelete(document) && (
                                                <Chip 
                                                    label="Delete File" 
                                                    clickable 
                                                    size="small" 
                                                    variant="outlined"
                                                    sx={{mr: 1}} 
                                                    onClick={() => deleteFileModal(document)}
                                                />
                                            )}
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={3} textAlign='right' sx={{pr:1}}>
                                    <Tooltip title="Download this file" arrow>
                                        <IconButton 
                                            color='primary' 
                                            onClick={() => downloadFile(document)}
                                        >
                                            <FileDownloadIcon
                                                sx={{
                                                    transform: 'scale(1.5)'
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        </Container>
                    ))}
                </>
            ) : (
                <>
                    <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10 }}>
                        {type === 'student' ? ('No documents were found.') : ('This student does not have any documents from this educator.')}<br />
                        {type === 'self_educator' && (
                            <Button
                                variant='contained'
                                disableElevation
                                size='large'
                                sx={{mt: 2}}
                                onClick={setOpenAddDocumentModal}
                                disabled={attachedUser?.active ? false : true}
                            >
                                Add Document
                            </Button>
                        )}
                    </Typography>
                </>
            )}
            <Modal
              open={openEditDocumentModal}
              onClose={handleCloseEditDocumentModal}
            >
                <Box sx={style}>
                    <Grid container alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item xs={12}>
                            <Typography textAlign={'center'} color='secondary' variant='h5' component='div'>
                                Edit This Document
                            </Typography>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        <Grid item xs={12}>
                            <Tooltip title={canEditTitle(documentToEdit) ? '' : 'You cannot edit this document title.'} arrow>
                                <TextField
                                    name="document_title"
                                    required
                                    fullWidth
                                    id="document_title"
                                    label="Document Title"
                                    type="text"
                                    value={documentTitle}
                                    onChange={onDocumentTitleChange}
                                    disabled={!canEditTitle(documentToEdit)}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="status">Document Status *</InputLabel>
                                <Select
                                    labelId="status"
                                    id="status"
                                    label="Document Status *"
                                    value={status}
                                    sx={{textAlign:'left'}}
                                    onChange={onStatusChange}
                                >
                                    <MenuItem key='1' value='Requires Review'>Requires Review</MenuItem>
                                    <MenuItem key='2' value='Submitted'>Submitted</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                disableElevation
                                size='large'
                                sx={{width:'100%'}}
                                onClick={editFile}
                                disabled={formCompleted ? false : true}
                            >
                                Edit File
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
              open={openDeleteDocumentModal}
              onClose={handleCloseDeleteDocumentModal}
            >
                <Box sx={style}>
                    <Grid container alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item xs={12}>
                            <Typography textAlign={'center'} color='secondary' variant='h5' component='div'>
                                Are you sure you want to delete this document?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                disableElevation
                                size='large'
                                sx={{width:'100%'}}
                                onClick={deleteFile}
                            >
                                Delete File
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}