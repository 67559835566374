import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ScienceIcon from '@mui/icons-material/Science';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaidIcon from '@mui/icons-material/Paid';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StarIcon from '@mui/icons-material/Star';
import GroupsIcon from '@mui/icons-material/Groups';
import { logOut } from '../../../services/userService';

const runLogOut = () => {
  logOut(localStorage.getItem('token')).then(logOut => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  });
}

export function MainListItems({accountLocked, accountSoftLocked}) {
  return (
    <React.Fragment>
      <ListItemButton 
        component="a" 
        href="/students/dashboard" 
        selected={(window.location.pathname.includes('dashboard')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/education" 
        selected={(window.location.pathname.includes('education')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="My School" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/occupation" 
        selected={(window.location.pathname.includes('occupation')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary="My Job" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/loans" 
        selected={(window.location.pathname.includes('loans')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <HandshakeIcon />
        </ListItemIcon>
        <ListItemText primary="My Loans" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/scholarships" 
        selected={(window.location.pathname.includes('scholarships')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="My Scholarships" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/favorites" 
        selected={(window.location.pathname.includes('favorites')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <StarIcon />
        </ListItemIcon>
        <ListItemText primary="My Favorites" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/suggestions" 
        selected={(window.location.pathname.includes('suggestions')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <ScienceIcon />
        </ListItemIcon>
        <ListItemText primary="My Suggestions" />
        <Chip 
          label="BETA"
          color='primary'
          size='small'
          sx={{
            color: 'white',
          }}
        />
      </ListItemButton>
    </React.Fragment>
  )
};

export function EducatorListItems({accountLocked, messagesCount, accountSoftLocked}) {
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Application Center
      </ListSubheader>
      <ListItemButton 
        component="a" 
        href="/students/educators" 
        selected={(window.location.pathname.includes('educators')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText primary="My Educators" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/parents" 
        selected={(window.location.pathname.includes('parents')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="My Parents" />
      </ListItemButton>
      <Tooltip arrow title={accountSoftLocked === 0 ? 'Please connect with an educator or subscribe to access this.' : ''}>
        <div>
          <ListItemButton 
            component="a" 
            href="/students/portfolio" 
            selected={(window.location.pathname.includes('portfolio')) ? true : false}
            disabled={(accountLocked || accountSoftLocked) ? true : false}
          >
            <ListItemIcon>
              <FolderCopyIcon />
            </ListItemIcon>
            <ListItemText primary=" My Portfolio" />
          </ListItemButton>
        </div>
      </Tooltip>
      <Tooltip arrow title={accountSoftLocked === 0 ? 'Please connect with an educator or subscribe to access this.' : ''}>
        <div>
          <ListItemButton 
            component="a" 
            href="/students/resume" 
            selected={(window.location.pathname.includes('resume')) ? true : false}
            disabled={(accountLocked || accountSoftLocked) ? true : false}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary=" My Resume" />
          </ListItemButton>
        </div>
      </Tooltip>
      <ListItemButton 
        component="a" 
        href="/students/messages" 
        selected={(window.location.pathname.includes('messages')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Messages" />
        {(messagesCount > 0) && (
          <Chip 
            label={messagesCount}
            color='primary'
            size='small'
            sx={{
              color: 'white',
            }}
          />
        )}
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/todo" 
        selected={(window.location.pathname.includes('todo')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <FactCheckIcon />
        </ListItemIcon>
        <ListItemText primary="To Do" />
      </ListItemButton>
      <ListItemButton 
        component="a" 
        href="/students/documents" 
        selected={(window.location.pathname.includes('documents')) ? true : false}
        disabled={(accountLocked || accountSoftLocked) ? true : false}
      >
        <ListItemIcon>
          <FilePresentIcon />
        </ListItemIcon>
        <ListItemText primary="Documents" />
      </ListItemButton>
    </React.Fragment>
  )
};

export function AccountListItems({userData, accountLocked, accountSoftLocked, openSidebar}) {
  const [open, setOpen] = React.useState(window.location.pathname.includes('billing') ? true : false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Account Details
      </ListSubheader>
      {userData?.account_soft_locked && userData?.stripe_subscription_id && (
        <ListItemButton 
          onClick={handleClick} 
          selected={(window.location.pathname.includes('billing')) ? true : false}
          disabled={(accountLocked) ? true : false}
        >
          <ListItemIcon>
            <PaymentsIcon />
          </ListItemIcon>
          <ListItemText primary="Billing" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton 
            sx={{ pl: openSidebar === true ? 4 : 2 }}
            component="a"
            href="/students/billing/subscription"
            selected={(window.location.pathname.includes('subscription')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <PaidIcon />
            </ListItemIcon>
            <ListItemText primary="Your Subscription" />
          </ListItemButton>
          <ListItemButton 
            sx={{ pl: openSidebar === true ? 4 : 2 }}
            component="a"
            href="/students/billing/invoices"
            selected={(window.location.pathname.includes('invoices')) ? true : false}
            disabled={(accountLocked) ? true : false}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Invoice History" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton 
        component="a" 
        href="/students/settings" 
        selected={(window.location.pathname.includes('settings')) ? true : false}
        disabled={(accountLocked) ? true : false}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
      <ListItemButton onClick={runLogOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Log Out" />
      </ListItemButton>
    </React.Fragment>
  )
};
