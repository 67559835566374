import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Popper from '@mui/material/Popper';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';
import { MainListItems, DiscoverListItems, AccountListItems } from './listItems';
import { Outlet } from "react-router-dom";
import Footer from "../default/footer/footer";
import './layout.scss';
import { parentAccount, resendActivationEmail, getUserNotifications, markNotificationsRead } from '../../../services/userService';
import { getSubscription, getProducts, updateSubscription } from '../../../services/parentBillingService';
import { Notifications } from '../../global/notifications';
import { getUnreadMessagesCount } from '../../../services/messagingService';
import { TermsAndConditions } from '../../../pages/parent/billing/subscription/components/overview/terms';

import logoDesktop from '../../../assets/images/logos/logo-wide.jpg';
import logoMobile from '../../../assets/images/logos/logo-icon.jpg';

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '800px',
  width: '90%',
  maxHeight: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  boxSizing: 'border-box'
};

const smallerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '90%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    boxSizing: 'border-box',
    textAlign: 'center'
};

const termsStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  maxHeight: 400,
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const formatPrice = (price) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price / 100);

const Layout = () => {
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = React.useState({});
  const [userSubscription, setUserSubscription] = React.useState({});
  const [products, setProducts] = React.useState({});
  const [userNotifications, setUserNotifications] = React.useState([]);
  const [unreadNotifications, setUnreadNotifications] = React.useState(null);
  const [resendButtonText, setResendButtonText] = React.useState('RESEND EMAIL');
  const [resendButtonDisabled, setResendButtonDisabled] = React.useState(false);
  const [accountLocked, setAccountLocked] = React.useState(false);
  const [accountSoftLocked, setAccountSoftLocked] = React.useState(false);
  const [messagesCount, setMessagesCount] = React.useState(0);
  const [openUpdateSubscriptionModal, setOpenUpdateSubscriptionModal] = React.useState(false);
  const handleCloseUpdateSubscriptionModal = () => setOpenUpdateSubscriptionModal(false);
  const [openTermsModal, setOpenTermsModal] = React.useState(false);
  const handleCloseTermsModal = () => setOpenTermsModal(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNotifications = Boolean(anchorEl);
  const notificationID = openNotifications ? 'notification-popover' : undefined;

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClickNotification = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    markNotificationsRead(localStorage.getItem('token')).then(notifications => {
      if (notifications.status === true) {
        setUnreadNotifications(null);
      }
    });
  };

  React.useEffect(() => {
      if (window.innerWidth <= 800 && document.readyState != 'complete') {
          setOpen(false);
      }

      parentAccount(localStorage.getItem('token')).then(userData => {
        if (userData.status == true) {
          if (
            (userData.data.investments[0].occupation === "0" || 
            userData.data.investments[0].institution === "0") &&
            !window.location.href.includes('students/dashboard')
          ) {
            window.location.href = '/students/dashboard';
          }
          setUserData(userData.data);
          setAccountLocked(userData.data.account_locked);
          setAccountSoftLocked(userData.data.account_soft_locked);
          getUserNotifications(localStorage.getItem('token')).then(notifications => {
            if (notifications.status === true) {
              setUserNotifications(notifications.data);
              let newCount = 0;
              notifications.data.forEach(notification => {
                if (notification.seen === 0) {
                  newCount++;
                }
              });
              if (newCount === 0) {
                setUnreadNotifications(null);
              } else {
                setUnreadNotifications(newCount);
              }
            }
          });
          getUnreadMessagesCount(localStorage.getItem('token')).then(messages => {
            if (messages.status === true) {
              setMessagesCount(messages.data);
            }
          });
          if (
            !window.location.href.includes('/parents/students')
            && userData?.data?.stripe_subscription_id
            && userData?.data?.account_soft_locked
          ) {
            getSubscription(localStorage.getItem('token')).then(subscription => {
              if (subscription.status === true) {
                setUserSubscription(subscription.data);
              }
            });
            getProducts(localStorage.getItem('token')).then(products => {
              if (products.status === true) {
                setProducts(products.data);
              }
            });
          }
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
  }, []);

  const removeStudents = () => {
    window.location.href = '/parents/students';
  }

  const updateSubscriptionButtonClick = () => {
    setOpenUpdateSubscriptionModal(true);
  }

  const finalizeUpdateSubscription = () => {
    let pricingData = new URLSearchParams({
        'price':userSubscription.plan.id
    });

    updateSubscription(localStorage.getItem('token'), pricingData).then(subscription => {
      if (subscription.status === true) {
        window.location.reload();
      }
    });
  }

  const resendConfirmationEmail = () => {
    resendActivationEmail(localStorage.getItem('token')).then(response => {
      if (response.status == true) {
        setResendButtonText('SUCCESS');
        setResendButtonDisabled(true);
      }
    })
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="absolute" open={open} color="inherit" className="AppBar">
          <Toolbar
            sx={{
              pr: '24px',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" className="logoDiv" sx={{ flexGrow: 1 }}>
              <Link to="/students/dashboard" className="logo" style={{backgroundImage: "url(" + logoMobile + ")"}}>
                <img src={logoDesktop} alt="Logo Desktop" />
              </Link>
            </Typography>
            <IconButton onClick={handleClickNotification}>
              <Badge badgeContent={unreadNotifications} color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Popper
              id={notificationID}
              open={openNotifications}
              anchorEl={anchorEl}
              placement={'bottom-end'}
              sx={{
                zIndex: '9999'
              }}
            >
              <Notifications notifications={userNotifications} />
            </Popper>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems
              accountLocked={accountLocked}
              messagesCount={messagesCount}
              accountSoftLocked={accountSoftLocked}
            />
            <Divider sx={{ my: 1 }} />
            <DiscoverListItems
              accountLocked={accountLocked}
              accountSoftLocked={accountSoftLocked}
            />
            <Divider sx={{ my: 1 }} />
            <AccountListItems
              accountLocked={accountLocked}
              accountSoftLocked={accountSoftLocked}
              openSidebar={open}
            />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {userData?.account_notifications?.map((notification, index) => (
            <Alert 
              severity={notification.type} 
              key={index} 
              sx={{borderRadius:0}}
              action={(notification.message === 'Please confirm your email.') &&
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={resendConfirmationEmail}
                  disabled={resendButtonDisabled}
                >
                  {resendButtonText}
                </Button>
              }
            >
              {notification.message}
            </Alert>
          ))}
          <Outlet />
        </Box>
      </Box>
      {(
        !window.location.href.includes('/parents/students')
        && userData?.stripe_subscription_id
        && userData?.account_soft_locked
      ) && (
        <Modal
          open={true}
        >
          <Box sx={style}>
              <Typography color='secondary' variant='h5' component='div' sx={{textAlign: 'center'}}>
                  Your Subscription Needs To Be Updated.
              </Typography>
              <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
              <Typography color='secondary' sx={{mb: 1}}>
                  You currently have more students than your subscription allows for. This happens for a few reasons:
              </Typography>
              <Typography color='secondary' sx={{mb: 1}}>
                  1. You added a new student and the student accepted your invitation.
              </Typography>
              <Typography color='secondary' sx={{mb: 1}}>
                  2. A student who had an educator was removed from that educators network.
              </Typography>
              <Typography color='secondary' sx={{mb: 1}}>
                  3. There was a problem with an educators payment method or their subscription was canceled.
              </Typography>
              <Typography color='secondary' sx={{mt:1}}>
                  You will either need to update your subscription or remove a student.
              </Typography>
              <Typography color='secondary' sx={{mt:1, fontWeight:'bold'}}>
                If you decide to update your subscription your monthly payment will be updated from&nbsp;
                {formatPrice(userSubscription?.plan?.amount * userSubscription?.quantity)} to&nbsp;
                {formatPrice(products?.[0]?.pricing?.[0]?.total_price)}
              </Typography>
              <Grid container spacing={2} sx={{mt:1}}>
                <Grid item xs={12} sm={6}>
                  <Button 
                      variant='contained'
                      color='primary'
                      disableElevation
                      sx={{width:'100%'}}
                      onClick={updateSubscriptionButtonClick}
                  >
                      Update Subscription
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                  <Button 
                      variant='outlined'
                      color='secondary'
                      sx={{width:'100%'}}
                      onClick={removeStudents}
                  >
                      Remove Students
                  </Button>
                </Grid>
              </Grid>
          </Box>
        </Modal>
      )}
      <Modal
        open={openUpdateSubscriptionModal}
        onClose={handleCloseUpdateSubscriptionModal}
      >
        <Box sx={smallerStyle}>
            <WarningIcon
                color="primary"
                fontSize="large"
            />
            <Typography variant="h6" component="h2">
              Are you sure you want to update your subscription? If you decide to update your subscription your monthly payment will be updated from&nbsp;
              <b>{formatPrice(userSubscription?.plan?.amount * userSubscription?.quantity)}</b> to&nbsp;
              <b>{formatPrice(products?.[0]?.pricing?.[0]?.total_price)}</b>.
            </Typography>
            <Grid container spacing={2} sx={{mt:1, mb: 2}}>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        sx={{width:'100%'}}
                        onClick={finalizeUpdateSubscription}
                    >
                        Update Subscription
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <Button 
                        variant='outlined'
                        color='secondary'
                        sx={{width:'100%'}}
                        onClick={handleCloseUpdateSubscriptionModal}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <Link href="#" onClick={() => setOpenTermsModal(true)}>Terms and Conditions</Link>
        </Box>
      </Modal>
      <Modal
          open={openTermsModal}
          onClose={handleCloseTermsModal}
      >
          <Box sx={termsStyle}>
              <TermsAndConditions />
          </Box>
      </Modal>
      <Footer />
    </>
  )
};

export default Layout;
