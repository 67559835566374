import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { parentAccount, checkType } from '../../../services/userService';
import { getSystemSuggestions } from '../../../services/suggestionsService';
import { getStudents, getEducators } from '../../../services/parentActionsService';
import { SuggestionItem } from '../../../components/global/suggestions/lists/suggestionItem';
import { SuggestionsView } from '../../../components/global/suggestions/suggestionsView';
import { StudentAdd } from '../students/components/studentAdd';

const ParentSuggestions = () => {
  const [user, setUser] = React.useState({});
  const [reloadList, setReloadList] = React.useState(false);
  const [type, setType] = React.useState('self_parent');
  const [students, setStudents] = React.useState([]);
  const [educators, setEducators] = React.useState({});
  const [systemSuggestions, setSystemSuggestions] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
  const [specificType, setSpecificType] = React.useState(false);
  const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);
  const [openSearchStudentModal, setOpenSearchStudentModal] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState({});
  const [openAddStudentModal, setOpenAddStudentModal] = React.useState(false);
  const handleCloseAddStudentModal = () => setOpenAddStudentModal(false);
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const handleCloseError = () => setError(false);
  
  const handleCloseSearchStudentModal = () => {
      setOpenSearchStudentModal(false);
  }
  
  const closeAndAddStudent = () => {
      handleCloseSearchStudentModal();
      setOpenAddStudentModal(true);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
  };

  const drawerStyle = {
      width: '80%',
      '@media (max-width: 800px)': {
          width: '100%'
      }
  }

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleCloseDrawer = () => {
      setOpenSuggestionsDrawer(false);
      setSelectedSuggestion({});
      setSpecificType(false);
      setSelectedStudent(user);
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
    parentAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/parents/dashboard';
        }
        setUser(userData.data);
        setSelectedStudent(userData.data);
        getStudents(localStorage.getItem('token')).then(studentsData => {
          if (studentsData.status == true) {
            setStudents(studentsData.data);
          }
        });
        getEducators(localStorage.getItem('token')).then(educatorsData => {
          if (educatorsData.status == true) {
            setEducators(educatorsData.data);
          }
        });
      } else {
        setError(true);
        setErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
    if (user?.id !== undefined) {
        getSystemSuggestions(localStorage.getItem('token'), user?.id).then(suggestions => {
            if (suggestions.status == true) {
                setSystemSuggestions(suggestions.data);
            }
        });
    }
    setReloadList(false);
  }, [user, setOpenSuggestionsDrawer, reloadList]);

  const handleOpenSearchStudentModal = (type) => {
      setOpenSearchStudentModal(true);
      setSelectedStudent(user);
  }

  const onStudentChange = (event) => {
    setSelectedStudent(event.target.value);
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography color='secondary' variant='h4' component='div'>
            Suggestions
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} align='right'>
          <Button
            variant='contained'
            disableElevation
            size='large'
            onClick={handleOpenSearchStudentModal}
            disabled={!user?.account_locked ? false : true}
          >
            Send A Suggestion
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
      <Paper>
          {systemSuggestions?.length > 0 ? (
              <>
                <Box sx={{ p:2 }}>
                  {systemSuggestions?.map((suggestion, index) => (
                      <SuggestionItem
                          key={index}
                          type={type}
                          attachedUser={user}
                          suggestion={suggestion}
                          setSelectedSuggestion={setSelectedSuggestion}
                          setReloadList={setReloadList}
                          setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                          setSpecificType={setSpecificType}
                          setError={setError}
                          setErrorText={setErrorText}
                      />
                  ))}
                </Box>
              </>
          ) : (
            <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                <Tooltip title="ROI is our artificial intelligence. Please be patient he is still learning!" placement="bottom" arrow>
                    You do not have any suggestions from ROI. <InfoIcon color="secondary" sx={{mb:'-2px', fontSize: 'medium'}} />
                </Tooltip>
                <br />
                <Button
                  variant='contained'
                  disableElevation
                  size='large'
                  onClick={handleOpenSearchStudentModal}
                  disabled={!user?.account_locked ? false : true}
                  sx={{mt:2}}
                >
                  Send Your Own Suggestion To A Student
                </Button>
            </Typography>
          )}
      </Paper>
      <Drawer
          open={openSuggestionsDrawer}
          onClose={handleCloseDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <SuggestionsView
              type={type}
              selectedSuggestion={selectedSuggestion}
              specificType={specificType}
              student={selectedStudent}
              setReloadList={setReloadList}
              handleCloseDrawer={handleCloseDrawer}
              setError={setError}
              setErrorText={setErrorText}
          />
      </Drawer>
      <Modal
          open={openSearchStudentModal}
          onClose={handleCloseSearchStudentModal}
      >
        <Box sx={style}>
            {students?.length > 0 ? (
              <>
                <Grid item xs={12}>
                  <Typography textAlign={'center'} color='secondary' variant='h5' component='div'>
                    Choose a student to send a suggestion to.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{mt:2}}>
                    <InputLabel id="students">Select A Student</InputLabel>
                    <Select
                      labelId="students"
                      id="students"
                      label="Select A Student"
                      sx={{textAlign:'left'}}
                      onChange={onStudentChange}
                    >
                      {students?.map((student, index) => (
                        <MenuItem key={index} value={student}>{student.first_name} {student.last_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    disableElevation
                    size='large'
                    sx={{width:'100%', mt:2}}
                    onClick={() => setOpenSuggestionsDrawer(true)}
                    disabled={Object.keys(selectedStudent).length === 0 ? true : false}
                  >
                    Send A Suggestion
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography textAlign={'center'} color='secondary' variant='h6' component='div'>
                    You have no active students assigned to you. Please add a student to your account and make sure they accept your invitation before  sending a suggestion.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    disableElevation
                    size='large'
                    sx={{width:'100%', mt:2}}
                    onClick={closeAndAddStudent}
                  >
                    Invite A Student
                  </Button>
                </Grid>
              </>
            )}
        </Box>
      </Modal>
      <Modal
          open={openAddStudentModal}
          onClose={handleCloseAddStudentModal}
      >
        <StudentAdd
          educators={educators}
          handleCloseAddModal={handleCloseAddStudentModal}
        />
      </Modal>
      <Snackbar
        open={error}
        onClose={handleCloseError}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {errorText}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ParentSuggestions;
