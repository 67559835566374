import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import './recover.scss';
import { requestRecover, checkType } from '../../../services/userService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const RequestRecover = () => {
  if (localStorage.getItem('token')) {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
  }

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalText, setOpenModalText] = React.useState('');
  const [requirementsCompleted, setRequirementsCompleted] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    requestRecover(data).then(requestData => {
      setOpenModal(true);
      setOpenModalText(requestData.message);
    });
  };

  const requirementsChange = (event, values) => {
    setRequirementsCompleted(false);
    if (
      validateEmail(event.target.value)
    ) {
      setRequirementsCompleted(true);
    }
  }

  const validateEmail = (email) => {
    return String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  return (
    <Container component="div" className="wrapper wrapper-small recover">
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Typography variant="h4" align="center">
          Recover Your Password
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={requirementsChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          sx={{ mt: 3, mb: 2 }}
          disabled={requirementsCompleted ? false : true}
        >
          Recover Password
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
}

export default RequestRecover;
