import React, { PureComponent, } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddTaskIcon from '@mui/icons-material/AddTask';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ChatIcon from '@mui/icons-material/Chat';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { checkType } from '../../../services/userService';
import { getDashboard } from '../../../services/educationActionsService';
import { educatorAccount } from '../../../services/userService';
import { getInvoices } from '../../../services/educatorBillingService';
import { getEmployees } from '../../../services/educationActionsService';
import { StudentAdd } from '../students/components/studentAdd';
import { addEmployee } from '../../../services/educationActionsService';
import { getStudentsByEmployee } from '../../../services/educationActionsService';
import { TasksView } from '../../../components/global/tasks/tasksView';
import { SuggestionsView } from '../../../components/global/suggestions/suggestionsView';
import { DocumentsAdd } from '../../../components/global/documents/documentsAdd';
import { HistoryList } from '../../../components/global/history/historyList';
import { appUrl } from '../../../constants/globals';
import './dashboard.scss';
import { 
  BarChart, 
  Bar,
  Tooltip,
  XAxis, 
  YAxis, 
  ResponsiveContainer, 
  CartesianGrid, 
  Legend 
} from 'recharts';

const CustomizedYLabel = ({ text }) => {
  return (
      <text
          x={0}
          y={0}
          dx={-180}
          dy={25}
          transform="rotate(-90)"
      >            
          {text}
      </text>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const drawerStyle = {
    width: '60%',
    '@media (max-width: 800px)': {
        width: '100%'
    }
};

const Dashboard = () => {
  const [user, setUser] = React.useState({});
  const [employees, setEmployees] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [selectedStudent, setSelectedStudent] = React.useState({});
  const [invoices, setInvoices] = React.useState([]);
  const [reportType, setReportType] = React.useState('week');
  const [reports, setReports] = React.useState({});
  const [reportsGraphData, setReportsGraphData] = React.useState({});
  const [formCompleted, setFormCompleted] = React.useState(false);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [userLevel, setUserLevel] = React.useState(null);
  const [openError, setOpenError] = React.useState(false);
  const [openErrorText, setOpenErrorText] = React.useState('');
  const [reloadList, setReloadList] = React.useState(false);
  const handleCloseError = () => setOpenError(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleCloseSuccess = () => setOpenSuccess(false);
  const [openAddStudentModal, setOpenAddStudentModal] = React.useState(false);
  const handleCloseAddStudentModal = () => setOpenAddStudentModal(false);
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = React.useState(false);
  const handleCloseAddEmployeeModal = () => setOpenAddEmployeeModal(false);
  const [openSearchStudentModal, setOpenSearchStudentModal] = React.useState(false);
  const [searchStudentType, setSearchStudentType] = React.useState(null);
  const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false);
  const handleCloseTaskDrawer = () => setOpenTaskDrawer(false);
  const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);
  const handleCloseSuggestionsDrawer = () => setOpenSuggestionsDrawer(false);
  const [openAddDocumentModal, setOpenAddDocumentModal] = React.useState(false);
  const handleCloseAddDocumentModal = () => setOpenAddDocumentModal(false);

  const handleOpenSearchStudentModal = (type) => {
      setSearchStudentType(type);
      setOpenSearchStudentModal(true);
      setSelectedStudent({});
  }
  
  const handleCloseSearchStudentModal = () => {
      setOpenSearchStudentModal(false);
  }
  
  const closeAndAddStudent = () => {
      handleCloseSearchStudentModal();
      setOpenAddStudentModal(true);
  }

  const handleOpenObjectToCreate = (type) => {
      handleCloseSearchStudentModal();
      if (type == 'task') {
          setOpenTaskDrawer(true);
      } else if (type == 'suggestion') {
          setOpenSuggestionsDrawer(true);
      } else if (type == 'document') {
          setOpenAddDocumentModal(true);
      }
  }

  React.useEffect(() => {
      if (reloadList !== false) {
          setOpenSuccess(true);
      }
  }, [reloadList]);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        } else if (requestData.data?.type === 3) {
          window.location.href = '/parents/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
          setUser(userData?.data);
          getStudentsByEmployee(localStorage.getItem('token'), userData?.data?.id).then(employeeStudents => {
              if (employeeStudents.status == true) {
                  setStudents(employeeStudents?.data);
              }
          });
          if (userData?.data?.user_level === 1 || userData?.data?.user_level === 2) {
            getEmployees(localStorage.getItem('token')).then(employees => {
              if (employees.status == true) {
                setEmployees(employees.data);
              }
            });
          }
          if (userData?.data?.user_level === 1) {
              getInvoices(localStorage.getItem('token')).then(invoices => {
                  if (invoices.status == true) {
                      setInvoices(invoices.data);
                  } else {
                    setOpenError(true);
                    setOpenErrorText(invoices.message);
                  }
              });
          }
      } else {
          setOpenError(true);
          setOpenErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getDashboard(localStorage.getItem('token'), reportType).then(report => {
        if (report?.status == true) {
            setReports(report?.data);
            setReportsGraphData(transformData(report?.data));
        }
    });
  }, []);

  React.useEffect(() => {
    if (firstName && lastName && email && userLevel) {
      setFormCompleted(true)
    }
  });

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  }

  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const onUserLevelChange = (event) => {
    setUserLevel(event.target.value);
  }

  const onStudentChange = (event) => {
    setSelectedStudent(event.target.value);
  }

  const handleSubmitEmployee = (event) => {
    let data = new URLSearchParams({
      'first_name':firstName,
      'last_name':lastName,
      'email':email,
      'user_level':userLevel
    });

    addEmployee(localStorage.getItem('token'), data).then(update => {
      setFirstName(null);
      setLastName(null);
      setEmail(null);
      setUserLevel(null);
      handleCloseAddEmployeeModal();

      if (update?.status == true) {
        setOpenSuccess(true);
      } else {
        setOpenError(true);
        setOpenErrorText(update.message);
        if (update.message === 'This email already exists.') {
          setOpenErrorText('WARNING: This email already exists on another account. We understand this may be frustrating and we are actively working on a feature that will remedy this. While not the best option the current workaround is to have the employee with this email log into their already existing account and change the email in that account themselves. That will free up the email and allow you to add them as an employee. Feel free to contact support if you have any trouble.');
        }
      }
    });
  };

  const convertDate = (invoiceDate) => {
      let date = new Date(invoiceDate * 1000);
      return date.toLocaleString("default", { month: "long" })
              + ' ' +
              date.toLocaleString("default", { day: "numeric" })
              + ', ' +
              date.toLocaleString("default", { year: "numeric" });
  }

  const handleDownloadInvoice = (e, file) => {
      window.location.href = file;
  }

  const transformData = (data) => {
      return {
          students: transformStudents(data?.students),
          documents: transformSimpleData(data?.documents),
          messages: transformSimpleData(data?.messages),
          suggestions: transformSimpleData(data?.suggestions),
          tasks: transformSimpleData(data?.tasks),
      }
  }

  const transformSimpleData = (data) => {
      let graphData = [];
      Object.keys(data?.graph_data).forEach(function(key){
          graphData.push({
              name: key,
              top: data?.graph_data[key],
          });
      });
      return graphData;
  }

  const transformStudents = (data) => {
      let graphData = [];
      Object.keys(data?.graph_data_total).forEach(function(key){
          graphData.push({
              name: key,
              active: data?.graph_data_active[key],
              inactive: data?.graph_data_inactive[key],
              pending: data?.graph_data_pending[key]
          });
      });
      return graphData;
  }

  const reportTimeText = () => {
      switch (reportType) {
          case 'week':
              return 'Past 7 Days';
          case 'month':
              return 'Past Month';
          case 'year':
              return 'Past Year';
          default:
              return 'Past Month';
      }
  }

  return (
    <>
      <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Dashboard
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={() => setOpenAddStudentModal(true)}
                disabled={(!user?.active || user?.account_locked || user?.account_soft_locked) ? true : false}
              >
                Add Student
              </Button>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                  <Paper sx={{ p: 2, height:{xs:'460px', sm:'300px',md:'300px'} }}>
                      <Typography 
                        color='secondary' 
                        variant='h6' 
                        component='div'
                      >
                        Create New
                      </Typography>
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Grid container alignItems="center" justifyContent="center" spacing={2}>
                          <Grid item xs={12} sm={6}>
                              <Button
                                fullWidth
                                variant='text'
                                color='secondary'
                                onClick={() => setOpenAddStudentModal(true)}
                                sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                                startIcon={<SchoolIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                                disabled={(!user?.active || user?.account_locked || user?.account_soft_locked) ? true : false}
                              >
                                  Add Student
                              </Button>
                          </Grid>
                          {(user?.user_level === 1 || user?.user_level === 2) && (
                            <Grid item xs={12} sm={6}>
                                <Button
                                  fullWidth
                                  variant='text'
                                  color='secondary'
                                  onClick={setOpenAddEmployeeModal}
                                  sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                                  startIcon={<PersonAddIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                                  disabled={(!user?.active || user?.account_locked || user?.account_soft_locked) ? true : false}
                                >
                                    Add An Employee
                                </Button>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                              <Button
                                fullWidth
                                variant='text'
                                color='secondary'
                                size='large'
                                onClick={() => handleOpenSearchStudentModal('task')}
                                sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                                startIcon={<AddTaskIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                                disabled={(!user?.active || user?.account_locked || user?.account_soft_locked) ? true : false}
                              >
                                  Assign A New Task
                              </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Button
                                fullWidth
                                variant='text'
                                color='secondary'
                                onClick={() => handleOpenSearchStudentModal('document')}
                                sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                                startIcon={<UploadFileIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                                disabled={(!user?.active || user?.account_locked || user?.account_soft_locked) ? true : false}
                              >
                                  Send A Document
                              </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Button
                                fullWidth
                                variant='text'
                                color='secondary'
                                href={appUrl + '/educator/messages'} 
                                sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                                startIcon={<ChatIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                                disabled={(!user?.active || user?.account_locked || user?.account_soft_locked) ? true : false}
                              >
                                  Send A Message
                              </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Button
                                fullWidth
                                variant='text'
                                color='secondary'
                                onClick={() => handleOpenSearchStudentModal('suggestion')}
                                sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, textAlign:'left', fontWeight:'bold', '&:hover': {color: '#fa7918'}}}
                                startIcon={<MapsUgcIcon sx={{transform: 'scale(1.5)', mr:'5px'}} />}
                                disabled={(!user?.active || user?.account_locked || user?.account_soft_locked) ? true : false}
                              >
                                  Send A Suggestion
                              </Button>
                          </Grid>
                      </Grid>
                  </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                  <Paper sx={{ p: 2, height:'300px', overflowY: reports?.notifications?.length > 0 ? ('scroll') : ('hidden')}}>
                      <Typography 
                        color='secondary' 
                        variant='h6' 
                        component='div'
                      >
                        New Notifications
                      </Typography>
                      <Divider variant="fullWidth" sx={{ mt: 2 }} />
                      {reports?.notifications?.length > 0 ? (
                          <>
                          {reports?.notifications?.map((notification, index) => (
                              <Box 
                                  sx={{ width: '100%'}} 
                                  key={index}
                              >
                                  <Button 
                                    fullWidth
                                    color="secondary"  
                                    underline="none"
                                    variant='text'
                                    href={appUrl + '/' + notification.deep_link} 
                                    disabled={user?.active || user?.account_locked || user?.account_soft_locked ? false : true}
                                    startIcon={<AccountCircleIcon sx={{transform: 'scale(1.5)',mr:1}} />}
                                    sx={{justifyContent:'flex-start', lineHeight:1, pt: 2, pb: 2, pl:2, fontWeight: 'bold'}}
                                  >
                                      {notification.notification}
                                  </Button>
                                  <Divider variant="fullWidth" />
                              </Box>
                          ))}
                          </>
                      ) : (
                          <Typography sx={{textAlign:'center', pt:'100px'}} color={'secondary'}>
                              No notifications found!
                          </Typography>
                      )}
                  </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                  <Paper sx={{ p: 2, height:'365px', overflowY: 'scroll'}}>
                      <Typography 
                        color='secondary' 
                        variant='h6' 
                        component='div'
                      >
                        Recent Student Activity
                      </Typography>
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      {user?.id && (
                        <HistoryList type={'educator'} userId={user?.id} studentId={'*'} />
                      )}
                  </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                  <Paper sx={{ p: 2 }}>
                      <Typography 
                        color='secondary' 
                        variant='h6' 
                        component='div'
                      >
                        Recently Assigned Students ({reportTimeText()})
                      </Typography>
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Box sx={{ width:'100%', height: '300px' }}>
                          <ResponsiveContainer width='100%' height='100%'>
                            <BarChart
                              data={reportsGraphData?.students}
                              height={300}
                              margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis label={<CustomizedYLabel text='Students' />} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="inactive" stackId="a" name='Inactive' fill='#FF3131' />
                              <Bar dataKey="pending" stackId="a" name='Pending' fill='#777777' />
                              <Bar dataKey="active" stackId="a" name='Active' fill='#fa7918' />
                            </BarChart>
                          </ResponsiveContainer>
                      </Box>
                  </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={user?.user_level === 1 ? 6 : 12}>
                  <Paper sx={{ p: 2, height: '300px', fontSize:'14px', overflowY:'scroll' }}>
                      <Typography 
                        color='secondary' 
                        variant='h6' 
                        component='div'
                      >
                        Get Help
                      </Typography>
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <b style={{color:'#555'}}>You can always chat with support by clicking the bottom right icon from 9am to 5pm for a quick way to get your issues resolved.</b>
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Link href="https://educationadvisor.org/how-it-works/" target="_blank">Video Demos</Link> - A great place to start. Get walk through tutorials on how to use Education Advisor.
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Link href="https://educationadvisor.org/frequently-asked-questions/" target="_blank">Frequently Asked Questions</Link> - Browse the more popular questions that we get at Education Advisor.
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />

                      <Link href="https://educationadvisor.org/contact-us/" target="_blank">Contact Us</Link> - If our chat support is offline use this form for general questions and we will get back to you asap.
                      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                      <Link href="https://educationadvisor.org/report-a-bug/" target="_blank">Report An Issue</Link> - If our chat support is offline and you found an issue use this form and we will get back to you asap.
                  </Paper>
              </Grid>
              {user?.user_level === 1 && (
                <Grid item xs={12} sm={12} md={6}>
                    <Paper sx={{ p: 2, height:'300px', overflowY: invoices?.length > 0 ? ('scroll') : ('hidden')}}>
                        <Typography 
                          color='secondary' 
                          variant='h6' 
                          component='div'
                        >
                          Recent Invoices
                        </Typography>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                        {invoices?.length > 0 ? (
                            <>
                              {invoices?.map((invoice, index) => (
                                <Container 
                                    key={index} 
                                    maxWidth={false} 
                                    disableGutters={true} 
                                    sx={[
                                        {'&:hover': {boxShadow:'1px 1px #E5E5E5'}},
                                        {p: 1, mt:1, mb:1, border:'1px solid #E5E5E5', borderRadius:1}
                                    ]}
                                >
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={10}>
                                            <Typography variant='h6' color="secondary" component='div' sx={{mb: 1}}>
                                            Invoice <b>#{invoice.number}</b>
                                            </Typography>
                                            <Typography color='secondary' component={'span'}>
                                              ${invoice?.total ? invoice?.total?.toString().substring(0, (invoice?.total?.toString().length) - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} - {convertDate(invoice.created)}
                                              &nbsp;<Chip 
                                                    label={invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                                                    color={invoice.status === 'paid' ? 'primary' : 'secondary'}
                                                    size="small" 
                                                    sx={{
                                                        color:'white',
                                                        fontSize: '1'
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} align='right'>
                                            <IconButton aria-label="edit" color="secondary" onClick={e => handleDownloadInvoice(e, invoice.invoice_pdf)}>
                                                <FileDownloadIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Container>
                            ))}
                        </>
                    ) : (
                        <Typography sx={{textAlign:'center', pt:'100px'}} color={'secondary'}>
                            No invoices found!
                        </Typography>
                    )}
                    </Paper>
                </Grid>
              )}
          </Grid>
      </Container>
      <Snackbar
        open={openError}
        onClose={handleCloseError}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {openErrorText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        onClose={handleCloseSuccess}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%', maxWidth: '500px' }}>
          Success
        </Alert>
      </Snackbar>
      <Modal
          open={openAddStudentModal}
          onClose={handleCloseAddStudentModal}
      >
        <>
          <StudentAdd
            educator={user}
            employees={employees}
            handleCloseAddModal={handleCloseAddStudentModal}
          />
        </>
      </Modal>
      <Modal
          open={openSearchStudentModal}
          onClose={handleCloseSearchStudentModal}
      >
        <Box sx={style}>
            {students?.length > 0 ? (
              <>
                <Grid item xs={12}>
                  <Typography textAlign={'center'} color='secondary' variant='h5' component='div'>
                    Choose a student
                    {searchStudentType == 'task' && (' to assign a task to.')}
                    {searchStudentType == 'suggestion' && (' to send a suggestion to.')}
                    {searchStudentType == 'document' && (' to send a document to.')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{mt:2}}>
                    <InputLabel id="students">Select A Student</InputLabel>
                    <Select
                      labelId="students"
                      id="students"
                      label="Select A Student"
                      sx={{textAlign:'left'}}
                      onChange={onStudentChange}
                    >
                      {students?.map((student, index) => (
                        <MenuItem key={index} value={student}>{student.first_name} {student.last_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    disableElevation
                    size='large'
                    sx={{width:'100%', mt:2}}
                    onClick={() => handleOpenObjectToCreate(searchStudentType)}
                    disabled={Object.keys(selectedStudent).length === 0 ? true : false}
                  >
                    {searchStudentType == 'task' && ('Assign A Task')}
                    {searchStudentType == 'suggestion' && ('Send A Suggestion')}
                    {searchStudentType == 'document' && ('Send A Document')}
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography textAlign={'center'} color='secondary' variant='h6' component='div'>
                    You have no active students assigned to you. Please add a student to your account and make sure they accept your invitation before 
                    {searchStudentType == 'task' && (' assigning a task.')}
                    {searchStudentType == 'suggestion' && (' sending a suggestion.')}
                    {searchStudentType == 'document' && (' sending a document.')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    disableElevation
                    size='large'
                    sx={{width:'100%', mt:2}}
                    onClick={closeAndAddStudent}
                  >
                    Invite A Student
                  </Button>
                </Grid>
              </>
            )}
        </Box>
      </Modal>
      <Modal
        open={openAddEmployeeModal}
        onClose={handleCloseAddEmployeeModal}
      >
        <Box sx={style}>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                Add New Employee
              </Typography>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid item xs={12}>
              <TextField
                name="first_name"
                required
                fullWidth
                id="first_name"
                label="First Name"
                type="text"
                onChange={onFirstNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="last_name"
                required
                fullWidth
                id="last_name"
                label="Last Name"
                type="text"
                onChange={onLastNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                required
                fullWidth
                id="email"
                label="Email"
                type="email"
                onChange={onEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="user_level">User Level *</InputLabel>
                <Select
                  labelId="user_level"
                  id="user_level"
                  label="User Level *"
                  sx={{textAlign:'left'}}
                  onChange={onUserLevelChange}
                >
                  <MenuItem key='3' value='3'>Employee</MenuItem>
                  <MenuItem key='2' value='2'>Manager</MenuItem>
                  <MenuItem key='1' value='1'>Owner</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                disableElevation
                size='large'
                disabled={formCompleted ? false : true}
                sx={{width:'100%'}}
                onClick={handleSubmitEmployee}
              >
                Add Employee
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openAddDocumentModal}
        onClose={handleCloseAddDocumentModal}
      >
        <DocumentsAdd
            type={'self_educator'}
            attachedUser={selectedStudent}
            educators={[]}
            setError={setOpenError}
            setErrorText={setOpenErrorText}
            handleCloseAddDocumentModal={handleCloseAddDocumentModal}
        />
      </Modal>
      <Drawer
          open={openTaskDrawer}
          onClose={handleCloseTaskDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseTaskDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <TasksView 
              type={'self_educator'}
              selectedTask={{}}
              attachedUser={user}
              attachedRecipient={selectedStudent}
              educators={[]}
              handleCloseDrawer={handleCloseTaskDrawer}
              setReloadList={setReloadList}
              setError={setOpenError}
              setErrorText={setOpenErrorText}
          />
      </Drawer>
      <Drawer
          open={openSuggestionsDrawer}
          onClose={handleCloseSuggestionsDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseSuggestionsDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <SuggestionsView
              type={'self_educator'}
              selectedSuggestion={{}}
              specificType={false}
              student={selectedStudent}
              setReloadList={setReloadList}
              handleCloseDrawer={handleCloseSuggestionsDrawer}
              setError={setOpenError}
              setErrorText={setOpenErrorText}
          />
      </Drawer>
    </>
  );
};

export default Dashboard;
