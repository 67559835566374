import { apiUrl } from '../constants/globals';

export async function getEducators(token) {
    try {
        const response = await fetch(apiUrl + '/parents/educators/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function inviteEducator(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/educators/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function approveEducators(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/approve/educator/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function disapproveEducators(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/disapprove/educator/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function revokeEducatorInvite(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/revoke/educator/invitation/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function activateEducators(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/activate/educator/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function deactivateEducators(token, data) {
  try {
      const response = await fetch(apiUrl + '/parents/deactivate/educator/', {
        method: 'POST',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function getStudents(token) {
    try {
        const response = await fetch(apiUrl + '/parents/students/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateStudent(token, studentId, data) {
    try {
        const response = await fetch(apiUrl + '/parents/students/' + studentId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function inviteStudent(token, data) {
    try {
        const response = await fetch(apiUrl + '/parents/students/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function revokeStudentInvitation(token, data) {
    try {
        const response = await fetch(apiUrl + '/parents/revoke/students/invitation/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function approveStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/parents/approve/students/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function disapproveStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/parents/disapprove/students/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function activateStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/parents/activate/student/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deactivateStudents(token, data) {
    try {
        const response = await fetch(apiUrl + '/parents/deactivate/student/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}