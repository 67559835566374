import React, { Suspense } from 'react';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { userAccount, checkType } from '../../../../services/userService';
import { getEducators } from '../../../../services/studentActionService';
import { getProducts } from '../../../../services/studentBillingService';
import { SubscriptionUnnecessary } from './components/overview/unnecessary';
import { TermsAndConditions } from './components/overview/terms';
import { SubscriptionAdd } from './components/overview/add';
import { SubscriptionOverview } from './components/overview/overview';
import { stripePublicKey } from '../../../../constants/globals';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    maxHeight: 400,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

const stripePromise = loadStripe(stripePublicKey);

const Subscription = () => {
    const [user, setUser] = React.useState(false);
    const [educators, setEducators] = React.useState([]);
    const [activeEducators, setActiveEducators] = React.useState([]);
    const [product, setProduct] = React.useState({});
    const [stripeOptions, setStripeOptions] = React.useState({});
    const [error, setError] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const handleCloseError = () => setError(false);
    const [openTermsModal, setOpenTermsModal] = React.useState(false);
    const handleCloseTermsModal = () => setOpenTermsModal(false);
  
    if (!localStorage.getItem('token')) {
        window.location.href = '/login';
    }
  
    React.useEffect(() => {
      checkType(localStorage.getItem('token')).then(requestData => {
        if (requestData.status == true) {
          if (requestData.data?.type === 2) {
            window.location.href = '/educator/dashboard';
          } else if (requestData.data?.type === 3) {
            window.location.href = '/parents/dashboard';
          }
        }
      });
      userAccount(localStorage.getItem('token')).then(userData => {
        if (userData.status == true) {
          setUser(userData.data);
          getEducators(localStorage.getItem('token')).then(educators => {
            if (educators.status == true) {
                let actEducators = [];
                for (let i = 0; i < educators.data.length; i++) {
                    if (educators.data[i].active === 1 && educators.data[i].pending !== 1) {
                        actEducators.push(educators.data[i]);
                    }
                }
                setActiveEducators(actEducators);
                setEducators(educators.data);
            }
          }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
          });
        } else {
          setError(true);
          setErrorText(userData.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
      getProducts(localStorage.getItem('token')).then(subscriptionData => {
          if (subscriptionData.status == true) {
            setProduct(subscriptionData.data[0]);
            setStripeOptions({
                mode: 'subscription',
                currency: 'usd',
                amount: subscriptionData.data[0]?.pricing[0]?.unit_amount,
                captureMethod: 'automatic',
                setupFutureUsage: 'off_session'
            });
          } else {
              setError(true);
              setErrorText(subscriptionData.message);
          }
      }).catch(err => {
          setError(true);
          setErrorText(err);
      });
    }, []);

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth='lg'>
            {(user?.account_locked || user?.account_soft_locked || activeEducators.length === 0) && (
                <>
                    {Object.keys(stripeOptions).length !== 0 && user?.stripe_subscription_id === null && (
                        <Elements stripe={stripePromise} options={stripeOptions}>
                            <SubscriptionAdd
                                product={product}
                                setOpenTermsModal={setOpenTermsModal}
                                setError={setError}
                                setErrorText={setErrorText}
                            />
                        </Elements>
                    )}
                </>
            )}
            {user?.stripe_subscription_id !== null && Object.keys(product).length !== 0 && (
                <SubscriptionOverview
                    product={product}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            )}
            {(!user?.account_locked && !user?.account_soft_locked && activeEducators.length !== 0) && (
                <SubscriptionUnnecessary
                    educators={educators}
                    setOpenTermsModal={setOpenTermsModal}
                />
            )}
            <Snackbar
                open={error}
                onClose={handleCloseError}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
                {errorText}
                </Alert>
            </Snackbar>
            <Modal
                open={openTermsModal}
                onClose={handleCloseTermsModal}
            >
                <Box sx={style}>
                    <TermsAndConditions />
                </Box>
            </Modal>
        </Container>
    );
};
  
  export default Subscription;