import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { HistoryList } from '../../../../../components/global/history/historyList';

export function HistoryComponent({educator, student}) {

    return (
        <>
            <Typography color='secondary' variant='h5' component='div' sx={{mb:2}}>
                Student Activity
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <HistoryList type={'educator'} userId={educator.id} studentId={student.id} />
        </>
    );
}